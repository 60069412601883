import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const PetsTags = (props) => {
  const [tags, setTags] = useState(["pets"])
  const newTagRef = useRef()

  const generalTags = [
    'pets',
    'birds 🐦',
    'cats 🐈',
    'dogs 🐕',
    'fish 🐟',
    'insect supplies 🐞',
    'small animals',
    'reptile supplies',
  ]
  let specificBirds = [
    'bird food',
    'bird seed',
    'cage',
    'feeder',
    'incubator',
    'toy',
  ]
  let specificCats = [
    'cat bed',
    'furniture',
    'cat food',
    'clothing',
    'collar',
    'harness',
    'leash',
    'dish',
    'feeder',
    'fountain',
    'doors & flaps',
    'tick & flea remedy',
    'grooming',
    'health care',
    'housebreaking & training aid',
    'litter',
    'litter box',
    'toy',
    'travel supplies',
    'treats',
  ]
  let specificDogs = [
    'dog bed',
    'dog food',
    'dog furniture',
    'chews',
    'treats',
    'clothing',
    'shoes',
    'collar',
    'harness',
    'leash',
    'lead',
    'dish',
    'feeder',
    'fountain',
    'doors & flaps',
    'grooming',
    'health care',
    'kennel',
    'doghouse',
    'toy',
    'training & obedience',
    'travel supplies',
  ]
  let specificFish = [
    'aquarium',
    'tank',
    'cleaning',
    'maintenance',
    'decoration',
    'feeder',
    'fish food',
    'gravel',
    'substrate',
    'heating',
    'chiller',
    'lighting',
    'hood',
    'live fish',
    'pump',
    'filter',
    'thermometer',
    'water test',
    'water treatment',
  ]
  let specificSmallAnimals = [
    'bed',
    'hammock',
    'nester',
    'cage',
    'enclosure',
    'carrier',
    'crate',
    'collar',
    'lead',
    'leash',
    'harness',
    'feeding & watering',
    'food',
    'treats',
    'healthcare',
    'grooming',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>
          
          {tags.includes('birds 🐦') ? (
            specificBirds.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('cats 🐈') ? (
            specificCats.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('dogs 🐕') ? (
            specificDogs.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('fish 🐟') ? (
            specificFish.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('small animals') ? (
            specificSmallAnimals.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default PetsTags