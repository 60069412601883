import { useState, useContext, useRef, useEffect, useMemo } from 'react';
import { Outlet, Link } from "react-router-dom";
import { ref, getDownloadURL, listAll } from 'firebase/storage'

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from '../firebase'
import './Home.css'

import logoIcon from '../icons/logoIcon.png'
import loadIcon from '../icons/scoutIcon.png'
import heartIcon from '../icons/heartIcon.png'
import accountIcon from '../icons/accountIcon.png'
import scoutIcon from '../icons/scoutIcon.png'
import vendorIcon from '../icons/vendorIcon.png'

import Login from '../login/Login'
import { ProblemStatement } from './popups/ProblemStatement';
import { NameMeaning } from './popups/NameMeaningPopup'
import { DefinitionsPopup } from './popups/DefinitionsPopup';

const Home = () => {
  const loginPopupRef = useRef()
  const [isLoading, setIsLoading] = useState(true)
  let [showInfo1, setShowInfo1] = useState(false)

  const [blockFavourites, setBlockFavourites] = useState()
 

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setBlockFavourites(false)
    } else {
      setBlockFavourites(true)
    }
  });

  useEffect(() => {
    document.title = 'Home | taizte™';
  }, []);

  const handleNavigation = () => {
    if (!blockFavourites) {
      window.location.href = '/favourites';
    } else {
      setShowInfo1(true) // shows the login popup
    }
  };
  
  return (
    <div className='homeBody' style={{ backgroundColor: '#EBB89C' }}>
      <div className='homeHeader'>
        <div className="iconContainer">
          <img src={logoIcon} alt="bootstrapt logo" id='logo'/>
          <div className="homeSpace"></div>
          <img src={heartIcon} onClick={handleNavigation}alt="favourites page" id='favourites'/>
          <img onClick={() => {setShowInfo1(true)}} src={accountIcon} alt="login" id='login'/>
        </div>
      </div>
      <div ref={loginPopupRef} className="loginPopup">
        <Login show={showInfo1} onClickOutside={() => {setShowInfo1(false)}}/>
      </div>
      <Outlet />
    </div>
  );

};

export default Home;