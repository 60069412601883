import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const ElectronicsTags = (props) => {
  const [tags, setTags] = useState(["electronics"])
  const newTagRef = useRef()

  const generalTags = [
    'electronics',
    'audio & media',
    'cellphones & wearables',
    'computer',
    'cables',
    'desktop',
    'electronic component',
    'electronic testing equipment',
    'gadgets',
    'gaming laptop',
    'gaming PC',
    'GPS navigation',
    'hard drive',
    'home automation',
    'home entertainment',
    'keyboard',
    'laptop',
    'monitor',
    'mouse',
    'security & surveillance',
  ]
  let specificAudioMedia = [
    'amplifier',
    'aux cable',
    'cassette player',
    'CDs',
    'DVD',
    'DVD reader',
    'earphones',
    'headphones',
    'headsets',
    'Hi-Fi system',
    'iPod',
    'karaoke machine',
    'mic',
    'movies',
    'MP3 player',
    'processor',
    'radio',
    'record player',
    'speaker',
    'subwoofer',
    'surround sound system',
    'turntable',
    'vinyl',
  ]
  let specificHardDrive = ['external', 'internal']
  let specificCellphonesWearables = [
    'Apple iPhone',
    'Google',
    'Hisense',
    'Huawei',
    'LG',
    'Nokia',
    'Oppo',
    'OnePlus',
    'RealMe',
    'Samsung',
    'SONY',
    'Vodafone',
    'VIVO',
    'Xiaomi',
    'AR glasses',
    'accessories',
    'charger',
    'fitness tracker',
    'smart watches',
    'VR headset',
  ]
  let specificElectronicComponent = [
    'board',
    'batteries',
    'breadboard',
    'capacitor',
    'diode',
    'header',
    'terminal',
    'heatsink',
    'cooling',
    'IC',
    'socket',
    'LED',
    'potentiometer',
    'relay',
    'resistor',
    'sensor',
    'switch',
    'transistor',
    'voltage regulator',
    'power transducer',
    'converter',
    'inverter',
    'transformer',
  ]
  let specificElectronicTestingEquipment = [
    'clamp meter',
    'continuity tester',
    'energy monitor',
    'multimeter',
    'oscilloscope',
    'socket tester',
  ]
  let specificGadgets = [
    'air purifier',
    'breathalyser',
    'calculator',
    'clock radio',
    'digital clock',
    'digital photo frame',
    'digital scale',
    'fax machine',
    'laser pointer',
    'metal detector',
    'powerbank',
    'smart pen',
    'stylus',
    'telephone',
    'timer',
    'weather device',
  ]
  let specificGPSNavigation = [
    'GPS device',
    'holder',
    'mount',
    'tracking device',
    'charger',
    'cable',
    'antenna',
    'accessory',
  ]
  let specificHomeEntertainment = [
    'TV Box',
    'satellite dish',
    'decoder',
    'television',
    'projector',
    'home theatre system',
    'TV accessory',
  ]
  let specificHomeAutomation = [
    'bundle',
    'hub',
    'bridge',
    'smart bulb',
    'smart light',
    'smart fingerprint',
    'smart LED strip',
    'smart controller',
    'smart lock',
    'smart plug',
    'smart remote',
    'smart sensor',
    'smart speaker',
    'smart switch',
  ]
  let specificMouse = ['wireless', 'bluetooth', 'wired']
  let specificSecuritySurveillance = [
    'alarm system',
    'beam',
    'biometric security',
    'bug detectors',
    'cable',
    'CCTV',
    'CCTV kit',
    'CCTV processor',
    'CCTV switch',
    'DVR',
    'NVR',
    'parts',
    'security monitor',
    'security display',
    'sensor',
    'surveillance camera',
    'smart lock',
    'smoke detector',
    'heat detector',
    'vehicle surveillance',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>

          {tags.includes('audio & media') ? (
            specificAudioMedia.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('hard drive') ? (
            specificHardDrive.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('cellphones & wearables') ? (
            specificCellphonesWearables.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('electronic component') ? (
            specificElectronicComponent.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('electronic testing equipment') ? (
            specificElectronicTestingEquipment.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('gadgets') ? (
            specificGadgets.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('GPS Navigation') ? (
            specificGPSNavigation.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('home entertainment') ? (
            specificHomeEntertainment.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('home automation') ? (
            specificHomeAutomation.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('mouse') ? (
            specificMouse.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('security & surveillance') ? (
            specificSecuritySurveillance.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

        </div>
      </div>
    </div>
  )
}

export default ElectronicsTags