import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const HealthFitnessPersonalCareTags = (props) => {
  const [tags, setTags] = useState(["health, fitness & personal care"])
  const newTagRef = useRef()

  const generalTags = [
    'health, fitness & personal care',
    'first aid kit',
    'bath & body',
    'beard care',
    'caregiver products',
    'eye care',
    'fitness',
    'hair',
    'homeopathic remedies',
    'medical furniture',
    'menstrual packages',
    'mobility equipments',
    'monitoring & testing',
    'nail care',
    'oral care',
    'protective gear',
    'respiratory aid',
    'skin care',
    'smart watch',
    'spa',
    'supplements',
    'surgical instruments',
    'toiletries',
  ]

  let specificFirstAidKit = [
    'cotton wool',
    'earbuds',
    'bandage',
    'plasters',
    'dressing',
    'safety pins',
    'tweezers',
    'gloves',
  ]
  let specificBathBody = [
    'bath oil',
    'bubble bath',
    'bath salt',
    'shower set',
    'body sponge',
    'body lotion',
    'body cream',
    'scrubber',
    'cloth',
    'deodorant',
    'antiperspirant',
    'soap',
    'shower gel',
    'wash lotion',
  ]
  let specificBeardCare = [
    'razor',
    'blade',
    'shaver',
    'electric shaver',
    'shaving cream',
    'shaving gel',
    'aftershave',
    'electric shaver head',
    'grooming kit',
    'beard oil',
    'clipper',
  ]
  let specificCaregiverProducts = [
    'finger cots',
    'hot & cold therapies',
    'medical needles',
    'syringes',
    'medical tape',
    'bandages',
    'ostomy supplies',
    'oximeter',
    'sanitiser',
    'disinfectants',
    'antiseptics',
    'scale',
    'stethoscope',
    'thermometer',
  ]
  let specificEyeCare = [
    'contact lenses',
    'fashion lenses',
    'eye care product',
    'eyewear', 
    'eye drops',
    'glasses',
  ]
  let specificHair = [
    'bonnet',
    'shampoo',
    'conditioner',
    'leave in conditioner',
    'foam conditioner',
    'dry shampoo',
    'hair scrub',
    'co-wash',
    'hairdye',
    'oil',
    'combing cream',
    'treatment',
    'comb',
    'brush',
    'detangling brush',
    'flex brush',
    'mask',
    'gel',
    'curl cream',
    'blow drye spray',
    'travel hair dryer',
    'blowdryer',
    'hair dryer',
    'hair diffuser',
    'curling iron',
    'curling tong',
    'traightening iron',
    'thickening spray',
    'anti frizz',
    'hairspray',
    'braid',
    'hairband',
    'hair tie',
    'ponytail',
    'scissors',
  ]
  let specificHomeopathicRemdies = [
    'acupuncture',
    'aromatherapy',
    'hebal remdy',
    'resin',
    'plant',
    'hypnosis',
    'light therapy',
    'neti pot',
    'cleanser',
  ]
  let specificMedicalFurniture = [
    'examination table',
    'examination chair',
    'homecare bed',
    'hospital bed',
    'medical cart',
  ]
  let specificMobilityEquipment = [
    'cane',
    'walking stick',
    'crutches',
    'mobility scooter',
    'transfer board',
    'transfer sheet',
    'walker',
    'wheelchair',
  ]
  let specificMonitoringTesting = [
    'blood pressure',
    'blood type',
    'cholesterol',
    'diabetes',
    'drug testing',
    'emergency alert system',
    'HIV',
    'ovulation',
    'pregnancy',
  ]
  let specificNailCare = [
    'nail clipper',
    'nails',
    'grooming kit',
    'hand scrub',
    'cuticle eraser',
    'nail file',
    'buffer block',
    'nail strengthener',
    'base coat',
    'top coat',
    'cuticle oil',
    'balm',
    'hand cream',
    'nail polish',
  ]
  let specificOralCare = [
    'toothbrush',
    'electric toothbrush',
    'toothpaste',
    'breathe mints',
    'whitening',
    'dental floss',
    'flosser',
    'mouth wash',
    'breath freshner',
    'orthodontic supplies',
  ]
  let specificProtectiveGear = [
    'face mask',
    'face shield',
    'disposable gloves',
    'scrubs',
    'uniform',
  ]
  let specificRespiratoryAid = [
    'CPAP machine',
    'CPAP masks',
    'nebulizer',
    'oxygen tank',
    'steam inhaler',
  ]
  let specificSkinCare = [
    'toner',
    'face wash',
    'cleanser',
    'exfoliator',
    'treatment',
    'charcoal mask',
    'mask',
    'serum',
    'scrub',
    'face oil',
    'sunscreen',
    'sun cream',
    'sunscream',
    'SPF 15',
    'SPF 20',
    'SPF 30',
    'SPF 40',
    'chemical peel',
    'moisturiser',
    'eye cream',
    'facewash',
    'dermaroller',
    'scrubber',
    'facial brush',
    'micro-sculpting cream',
    'anti-aging',
    'anti-wrinkle',
    'peel',
    'lotion',
    'ointment',
  ]
  let specificSpa = [
    'electric massage chair',
    'massage oil',
    'lotion',
    'massage pillow',
    'bolster',
    'massager',
    'massage stone',
    'massage rock',
    'massage table',
    'massage chair',
    'programme',
  ]
  let specificSurgicalInstruments = [
    'medical scalpel',
    'forceps',
    'blades',
    'scissors',
    'sugical needles',
    'sutures',
  ]
  let specificToiletries = [
    'roll on',
    'spray',
    'deodorant',
    'perfume',
    'toothpaste',
    'shampoo',
    'body wash',
    'cloth',
    'soap',
    'conditioner',
  ]
  let specificFitness = [
    'gym equipment',
    'sneakers',
    'smart watch',
    'weights',
    'dumbell',
    'yoga mat',
    'tights',
    'shorts',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>

          {tags.includes('first aid kit') ? (
            specificFirstAidKit.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('bath & body') ? (
            specificBathBody.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('beard care') ? (
            specificBeardCare.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('caregiver products') ? (
            specificCaregiverProducts.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('eye care') ? (
            specificEyeCare.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('hair') ? (
            specificHair.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('homeopathic remedies') ? (
            specificHomeopathicRemdies.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('medical furniture') ? (
            specificMedicalFurniture.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('mobility equipments') ? (
            specificMobilityEquipment.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('monitoring & testing') ? (
            specificMonitoringTesting.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('nail care') ? (
            specificNailCare.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('oral care') ? (
            specificOralCare.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('protective gear') ? (
            specificProtectiveGear.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('respiratory aid') ? (
            specificRespiratoryAid.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('skin care') ? (
            specificSkinCare.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('spa') ? (
            specificSpa.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('surgical instruments') ? (
            specificSurgicalInstruments.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('toiletries') ? (
            specificToiletries.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('fitness') ? (
            specificFitness.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

        </div>
      </div>
    </div>
  )
}

export default HealthFitnessPersonalCareTags