import React, { useRef, useState, useEffect } from 'react';
import { Link, Outlet } from "react-router-dom";
import './Wallet.css';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

import backArrow from '../../icons/backArrow.png';
import notificationIcon from '../../icons/notificationIcon.png';
import historyIcon from '../../icons/historyIcon.png';

const Wallet = (props) => {
  const walletFormRef = useRef();
  const [showForm, setShowForm] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [accountHolder, setAccountHolder] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [bankName, setBankName] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    document.title = 'Wallet | taizte™';
  }, []);

  // Enable or disable the submit button based on input values
  useEffect(() => {
    setIsSubmitDisabled(!accountHolder || !accountNumber || !branchCode || !bankName);
  }, [accountHolder, accountNumber, branchCode, bankName]);

  async function saveWithdrawlInformation(e) {
    e.preventDefault();

    // Ensure props.userID exists before proceeding
    if (!props.userID) return;

    try {
      const userDocRef = doc(db, 'users', props.userID);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();

        // Update the user's banking details
        await updateDoc(userDocRef, {
          bankingDetails: {
            accountHolder,
            accountNumber,
            branchCode,
            bankName
          }
        });

        // Check if store_id exists, and if so, fetch and update the corresponding store document
        if (userData.store_id) {
          const storeDocRef = doc(db, 'stores', userData.store_id);
          const storeDocSnap = await getDoc(storeDocRef);

          if (storeDocSnap.exists()) {
            const storeData = storeDocSnap.data();

            // Append banking details to the store's bankingDetails array
            const updatedBankingDetails = [
              ...(storeData.bankingDetails || []),
              { accountHolder, accountNumber, branchCode, bankName }
            ];

            await updateDoc(storeDocRef, {
              bankingDetails: updatedBankingDetails
            });

            console.log('Banking details updated in both user and store documents.');
          } else {
            console.error('No such store document!');
          }
        } else {
          console.log('store_id not found in user document.');
        }

        console.log('Banking details updated successfully.');
      } else {
        console.error('No such user document!');
      }
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  }

  return (
    <div className='walletBody'>
      <style>{'body { background-color: #ECD3A1; }'}</style>
      <div className="walletHeader">
        <div className="walletHeaderContainer1">
          <Link to="/vendor">
            <div className="walletBack">
              <img src={backArrow} className="walletBackIcon" />
            </div>
          </Link>
          <div className='walletHeadingDiv'>
            <h1 className='walletHeading'>wallet.</h1>
          </div>
        </div>
        <img src={historyIcon} className="walletHistoryIcon" />
        <img src={notificationIcon} className="walletNotificationIcon" onClick={() => setShowNotifications(!showNotifications)} />
      </div>
      {props.country && props.country !== 'South Africa' && (
        <div className="walletCountryUploadNote">
          Note that <strong>taizte is not in {props.country} yet, </strong> 
          hence we do not have your country's banks yet.
        </div>
      )}
      <form ref={walletFormRef} className="walletForm" onSubmit={saveWithdrawlInformation}>
        <h1 className='bankDetailsHeader'>Banking Details</h1>
        <input 
          type="text" 
          placeholder='Account Holder' 
          className='bankHolderNameInput'
          value={accountHolder}
          onChange={(e) => setAccountHolder(e.target.value)}
        />
        <div className="bankAccountNumberDiv">
          <input 
            placeholder='Account Number' 
            type="number" 
            className='accountNumberInput'
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
          <input 
            placeholder='Branch Code' 
            type="number" 
            className='branchCodeInput'
            value={branchCode}
            onChange={(e) => setBranchCode(e.target.value)}
          />
        </div>
        <select 
          name="Bank Name" 
          className='bankNameOptions'
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
        >
          <option value="">Bank Name</option>
          <option value="ABSA">ABSA</option>
          <option value="African Bank">African Bank</option>
          <option value="Albaraka Bank">Albaraka Bank</option>
          <option value="Bank Zero">Bank Zero</option>
          <option value="Bidvest Bank">Bidvest Bank</option>
          <option value="Capitec">Capitec</option>
          <option value="Discovery Bank">Discovery Bank</option>
          <option value="First National Bank (FNB)">First National Bank (FNB)</option>
          <option value="FirstRand Bank">FirstRand Bank</option>
          <option value="Grindrod Bank">Grindrod Bank</option>
          <option value="Investec Bank Ltd">Investec Bank Ltd</option>
          <option value="Mercantile Bank Limited">Mercantile Bank Limited</option>
          <option value="Nedbank Limited">Nedbank Limited</option>
          <option value="Sasfin Bank Limited">Sasfin Bank Limited</option>
          <option value="Standard Bank">Standard Bank</option>
          <option value="TymeBank">TymeBank</option>
          <option value="Ubank Limited">Ubank Limited</option>
        </select>
        <button 
          type='submit' 
          className='saveBankDetailsButton'
          disabled={isSubmitDisabled} // Disable the button if fields are not filled
        >
          save details
        </button>
      </form>
      <div className="withdrawalWarning">
        <h1 className='withdrawalWarningHeader'>*PLEASE NOTE</h1>
        <ol className="withdrawalWarningText">
          <li>After each sale, your money may take up to 3 business days to reflect in your account.</li>
          <li>Transactions cannot be reversed or cancelled.</li>
        </ol>
      </div>
      <Outlet />
    </div>
  );
}

export default Wallet;
