import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const AudioMediaTags = (props) => {
  const [tags, setTags] = useState(["audio & media"])
  const newTagRef = useRef()

  const generalTags = [
    'audio & media',
    'amplifier',
    'aux cable',
    'cassette player',
    'CDs',
    'DVD',
    'DVD reader',
    'earphones',
    'headphones',
    'headsets',
    'Hi-Fi system',
    'iPod',
    'karaoke machine',
    'mic',
    'movies',
    'MP3 player',
    'processor',
    'radio',
    'record player',
    'speaker',
    'subwoofer',
    'surround sound system',
    'turntable',
    'vinyl',
  ]
  let specificEarphones = ['wireless', 'bluetooth', 'wired']
  let specificHeadphones = ['wireless', 'bluetooth', 'wired']

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>

          {tags.includes('earphones') ? (
            specificEarphones.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('headphones') ? (
            specificHeadphones.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default AudioMediaTags