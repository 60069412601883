import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const PhysicalSportTrainingTags = (props) => {
  const [tags, setTags] = useState(["physical sport & training"])
  const newTagRef = useRef()

  const generalTags = [
    'physical sport & training',
    'parasport equipment',
    'sportswear',
    'sport nutrition',
    'watch',
    'gym accessory',
    'exercise machine',
    'gym equipment',
    'yoga & pilates',
    'archery',
    'badminton',
    'bowling',
    'ballet & dancing',
    'combat sport',
    'cycling',
    'golf',
    'running & athletics',
    'skateboarding & rollerblading',
    'squash',
    'skiing & snowboarding',
    'swimming',
    'tennis',
    'airsoft',
    'basketball',
    'cricket',
    'hockey',
    'netball',
    'paintball',
    'rugby',
    'soccer',
    'softball & baseball',
    'volleyball',
    'waterpolo',
    'camping & hiking',
    'fishing',
    'equestrian',
    'water activities',
    'darts',
    'pool & snooker',
    'table tennis',
  ]
  let specificSportswear = [
    'compression gear',
    'footwear',
    'crop top',
    'tank top',
    'jacket',
    'jersey',
    'hoodie',
    'pants',
    'shorts',
    'swimwear',
    'leggings',
    'tights',
    'T-shirt',
    'tracksuit',
    'skirt',
    'skort',
    'vest',
    'cap',
    'bag',
    'hat',
    'sungalsses',
  ]
  let specificSportNutrition = [
    'bottle',
    'shaker',
    'sports food',
    'supplements',
    'amino acid',
    'fat burner',
    'weight loss',
    'mass gainer',
    'performance enhancer',
    'pre workout',
    'protein supplements',
    'vitamins & minerals',
  ]
  let specificWatch = [
    'Apple',
    'Fitbit',
    'Garmin',
    'Huawei',
    'Polar',
    'Suunto',
    'Xiaomi',
    'band',
    'strap',
    'charger',
    'screen protector',
    'sensor',
    'stop watch',
  ]
  let specificGymAccessory = [
    'ab wheel',
    'ankle weight',
    'wrist weight',
    'electronic muscle stimulator',
    'exercise mat',
    'gloves',
    'grip',
    'strap',
    'hand strengthener',
    'parrallettes',
    'resistance trainer',
    'skipping rope',
    'support brace',
    'protector',
    'tape',
    'wrap',
    'towel',
    'weight belt',
  ]
  let specificExerciseMachine = [
    'arm machine',
    'elliptical trainer',
    'exercise bike',
    'leg machine',
    'rowing machine',
    'smith machine',
    'step machine',
    'treadmill',
    'vibration platform machine',
  ]
  let specificGymEquipment = [
    'accessory',
    'barbell',
    'bench',
    'dumbbell',
    'home gym',
    'inversion equipment',
    'kettlebell',
    'weight plate',
    'pull-up bar',
    'pushup stand',
    'weight rack',
  ]
  let specificYogaPilates = [
    'bag',
    'ball',
    'mat',
    'non-slip towel',
    'equipment',
    'prop',
    'accessory',
  ]
  let specificArchery = [
    'accessory',
    'arrows',
    'bolts',
    'bows',
  ]
  let specificBadminton = [
    'apparel',
    'equipment',
  ]
  let specificBowling = [
    'apparel',
    'balls',
    'bowling pins',
  ]
  let specificBalletDancing = [
    'ballet shoes',
    'clothing',
    'tutu',
    'accessory',
  ]
  let specificCombatSport = [
    'apparel',
    'stick',
    'sword',
    'bag',
    'gloves',
    'protective gear',
    'training equipment',
  ]
  let specificCycling = [
    'accessory',
    'apparel',
    'bike',
    'helmet',
    'protection',
    'parts',
    'components',
    'tools & repairs',
    'wheel',
    'tyre',
  ]
  let specificGolf = [
    'accessory',
    'apparel',
    'bag',
    'cart',
    'balls',
    'club',
  ]
  let specificRunningAthletics = [
    'footwear',
    'apparel',
    'accessory',
    'socks',
    'takkies',
  ]
  let specificSkateboardingRollerblading = [
    'roller skate',
    'roller blade',
    'skateboard',
    'skate',
  ]
  let specificSkiingSnowboarding = [
    'ski',
    'goggles',
    'apparel',
    'snowboard',
    'pole',
    'helmet',
    'boots',
  ]
  let specificSwimming = [
    'shorts',
    'goggles',
    'bikini',
    'cap',
    'floatation',
    'bather',
    'maillot',
    'tank suit',
    'swimsuit',
  ]
  let specificTennis = [
    'bag',
    'balls',
    'shoes',
    'racket',
  ]
  let specificAirsoft = [
    'airsoft gun',
    'BB gun',
    'pellet gun',
    'ammunition',
    'apparel',
    'bag',
    'case',
    'CO2 tank',
    'air tank',
    'parts',
    'accessory',
    'protective gear',
  ]
  let specificBasketball = [
    'apparel',
    'ball',
    'sneaker',
    'kicks',
    'hoop',
  ]
  let specificCricket = [
    'apparel',
    'bag',
    'ball',
    'bat',
    'equipment',
    'set',
    'gloves',
    'helmet',
    'pads',
    'protective gear',
    'shoes',
  ]
  let specificHockey = [
    'accessory',
    'apparel',
    'ball',
    'boots',
    'protective gear',
    'helmet',
    'hockey stick',
    'whistle',
  ]
  let specificNetball = [
    'apparel',
    'ball',
    'equipment',
    'hoop',
  ]
  let specificPaintball = [
    'apparel',
    'bag',
    'case',
    'paintball gun',
    'marker',
    'mask',
    'paintball kit',
    'paintball',
    'parts',
    'accessory',
  ]
  let specificRugby = [
    'apparel',
    'jersey',
    'togs',
    'cleats',
    'accessory',
    'book',
    'DVD',
    'ball',
    'boots',
    'protective gear',
    'gumguard',
    'supporters gear',
    'whistle',
    'collectable',
  ]
  let specificSoccer = [
    'team jersey',
    'ball',
    'boots',
    'togs',
    'bulk kit',
    'full kit',
    'clothing',
    'accessory',
    'supporters gear',
    'whistle',
    'training equipment',
  ]
  let specificVolleyball = [
    'equipment',
    'shorts',
    'ball',
  ]
  let specificWaterpolo = [
    'equipment',
    'goal',
    'mouthguard',
    'cap',
    'ball',
    'goggles',
    'shorts',
    'trunks',
  ]
  let specificSoftballBaseball = [
    'apparel',
    'hat',
    'ball',
    'bat',
    'gloves',
    'mitts',
  ]
  let specificCampingHiking = [
    'apparel',
    'footwear',
    'bag',
    'pack',
    'camping furniture',
    'camping toilet',
    'camping shower',
    'caravan gear',
    'climbing gear',
    'caving gear',
    'cooking',
    'tools',
    'cooler',
    'freezer',
    'insect net',
    'insect repellent',
    'light',
    'navigation & optics',
    'sleeping bag',
    'accessory',
    'tent',
    'shelter',
    'knife',
  ]
  let specificFishing = [
    'apparel',
    'bundle',
    'combo',
    'net',
    'tools',
    'storage',
    'hook',
    'line',
    'braid',
    'trace',
    'lure',
    'bait',
    'reel',
    'rod',
    'terminal tackle',
  ]
  let specificHuntingShooting = [
    'apparel',
    'accessory',
    'firearm part',
    'equipment',
    'hunting knife',
    'reloading equipment',
    'training equipment',
  ]
  let specificEquestrian = [
    'apparel',
    'boots',
    'helmet',
    'horse',
    'pony',
    'equipment',
    'headwear',
    'footwear',
    'saddle',
  ]
  let specificWaterActivities = [
    'kayak',
    'canoe',
    'raft',
    'kite',
    'kitesurfing',
    'scuba',
    'snorkelling',
    'surfing',
    'bodyboarding',
    'surf board',
    'body board',
    'waterskiing',
    'wakeboarding',
    'wetsuit',
    'lifejacket',
    'shorts',
    'swimsuit'
  ]
  let specificDarts = [
    'dart board',
    'darts',
    'component',
  ]
  let specificPoolSnooker = [
    'pool table',
    'snooker table',
    'accessory',
    'balls',
    'cues',
  ]
  let specificTableTennis = [
    'accessory',
    'bat',
    'set',
    'table',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }

  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>
          
          {tags.includes('sportswear') ? (
            specificSportswear.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('sport nutrition') ? (
            specificSportNutrition.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('watch') ? (
            specificWatch.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('gym accessory') ? (
            specificGymAccessory.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('exercise machine') ? (
            specificExerciseMachine.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('gym equipment') ? (
            specificGymEquipment.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('yoga & pilates') ? (
            specificYogaPilates.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('archery') ? (
            specificArchery.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('badminton') ? (
            specificBadminton.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('bowling') ? (
            specificBowling.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('ballet & dancing') ? (
            specificBalletDancing.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('combat sport') ? (
            specificCombatSport.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('cycling') ? (
            specificCycling.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('golf') ? (
            specificGolf.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('running & athletics') ? (
            specificRunningAthletics.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('skateboarding & rollerblading') ? (
            specificSkateboardingRollerblading.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('skiing & snowboarding') ? (
            specificSkiingSnowboarding.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('swimming') ? (
            specificSwimming.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('tennis') ? (
            specificTennis.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('airsoft') ? (
            specificAirsoft.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('softball & baseball') ? (
            specificSoftballBaseball.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('basketball') ? (
            specificBasketball.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('cricket') ? (
            specificCricket.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('hockey') ? (
            specificHockey.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('netball') ? (
            specificNetball.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('paintball') ? (
            specificPaintball.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('rugby') ? (
            specificRugby.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('soccer') ? (
            specificSoccer.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('volleyball') ? (
            specificVolleyball.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('waterpolo') ? (
            specificWaterpolo.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('camping & hiking') ? (
            specificCampingHiking.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('fishing') ? (
            specificFishing.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('equestrian') ? (
            specificEquestrian.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('water activities') ? (
            specificWaterActivities.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('darts') ? (
            specificDarts.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('pool & snooker') ? (
            specificPoolSnooker.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('table tennis') ? (
            specificTableTennis.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default PhysicalSportTrainingTags