import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  createUserWithEmailAndPassword, 
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";

import { auth, signInWithGoogle, db, storage } from "../firebase";
import { addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, query, where, onSnapshot, deleteDoc, 
  limit, orderBy } 
from '@firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4} from "uuid";
import { motion } from "framer-motion";

import './StorelessUser.css';
import vendorIcon from '../icons/vendorIcon.png';
import Login from '../login/Login';
import FilmGrain from '../scout/FilmGrain';
import MarketGradient from "../market/MarketGradient";

import loadIcon from '../icons/scoutIcon.png';
import backArrow from '../icons/backArrow.png';
import logoPattern from '../icons/logoPattern.png'

import VendorForm from './VendorForm.jsx'; // Import the multi-step form component
import { OmitProps } from "antd/es/transfer/ListBody";

function getStoreID() {
  let title = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 7; i++) {
    title += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  title += new Date().getTime();
  return title;
}

const StorelessUser = (props) => {
  // Existing state and refs
  // handles cases where there is a user, but they have not made a store
  const businessDescriptionRef2 = useRef();
  
  const [storeName2, setStoreName2] = useState("");
  const [selectedIndustry2, setSelectedIndustry2] = useState("");
  
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateStore2Form, setShowCreateStore2Form] = useState(true);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [showUserLoggedInCS, setShowUserLoggedInCS] = useState(false);

  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);
  const [savedDominantColors, setSavedDominantColors] = useState([
    'rgb(197, 201, 198)',
    'rgb(91, 106, 125)',
    'rgb(138, 60, 36)',
    'rgb(210, 136, 55)',
    'rgb(114, 126, 132)'
  ]);
    

  const cSAlreadyLoggedInRef2 = useRef();
  const navigate = useNavigate();

  const [storeID, setStoreID] = useState(getStoreID());
  const [userID, setUserID] = useState(getStoreID());
  
  useEffect(() => {
    document.title = 'Create Store | taizte™';
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid);
        setShowCreateAccount(false);
        setShowCreateStore2Form(true);
      } else {
        setShowCreateStore2Form(false);
        setShowCreateAccount(true);
        setIsLoading(false);
      }
    });
  
    return () => unsubscribe(); // Clean up the subscription on component unmount
  }, []);

  useEffect(() => {
    const fetchUserDoc = async () => {
      if (userID) {
        try {
          const userDocRef = doc(db, 'users', userID);
          const docSnap = await getDoc(userDocRef);
          
          if (docSnap.exists()) {
            if (docSnap.data().store_id == null) {
              // User is logged in but does not have a store
            } else {
              // User is logged in and has a store
              setShowUserLoggedInCS(true);
              setShowCreateStore2Form(false);
            }
          }
        } catch (error) {
          console.error("Error getting document:", error);
        } finally {
          setIsLoading(false); // Set isLoading to false after fetching user doc
        }
      }
    };
  
    if (userID) {
      fetchUserDoc();
    }
  }, [userID]);

  return (
    <div className="createStoreDiv2">
      <canvas className="storelessUserGradientCanvas">
        <MarketGradient 
          page={"market"} 
          dominantColors={savedDominantColors} 
          isWideScreen={isWideScreen}
        />
      </canvas>
      <motion.div
        key="filmGrain"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <FilmGrain />
      </motion.div>
      <div className="createStore2Header">
        <Link to="/vendor">
          <div className="createStoreBack2">
            <img src={backArrow} className="createStoreBackIcon2" alt="Back Arrow"/>
          </div>
        </Link>
      </div>
      {isLoading && ( 
        <div className="storelessUserLoadingDiv">
          <img src={loadIcon} className="storelessUserLoadingIcon" alt="Loading Icon" />
        </div>
      )}
      {!isLoading && (
        <>
        {showCreateStore2Form && 
          <VendorForm 
            storeID={storeID}
            setStoreID={setStoreID} 
            userID={userID}
            setUserID={setUserID}
            setAlertMessage={props.setAlertMessage}
            setAlertType={props.setAlertType}
            country={props.country}
          />
        } {/* Renders multi-step form */}
        {showUserLoggedInCS &&
        <div className="cSAlreadyLoggedIn2" ref={cSAlreadyLoggedInRef2}>
          <div>You already created a store</div>
          <Link to="/vendor">
            <button className="returnToVendor2">
              <img className="returnToVendorIcon" src={vendorIcon} alt="Vendor Icon"/>
              Return to Vendor Page
            </button>
          </Link>
        </div>
        }
        {showCreateAccount &&
        <div className="createStoreAccountDiv">
          <div className="createStoreAccountHeading">
            Login/Create an account before opening a store
          </div>
          <Login show={showCreateAccount} onClickOutside={() => {setShowCreateAccount(false)}}/> 
          {/* <div className="logoPatternDiv">
            <img className="logoPattern" src={logoPattern}/>
          </div> */}
        </div>
        }
        </>
      )}
    </div>
  );
};

export default StorelessUser;
