import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { Link, Outlet } from "react-router-dom";

import { onAuthStateChanged } from "firebase/auth";
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, arrayUnion, arrayRemove, query, 
  where, onSnapshot } 
from '@firebase/firestore'
import { auth, db, storage } from '../firebase'
import { motion, AnimatePresence, useAnimation, useMotionValue } from "framer-motion";

import './Cart.css'
import historyIcon from '../icons/historyIcon.png'
import CartContainer from './CartContainer';
import PaymentPopup from './PaymentPopup';
import deleteIcon from '../icons/deleteIcon.png'
import loadIcon from '../icons/scoutIcon.png'
import ScoutCard from '../scout/ScoutCard'
import EthicalChecksPopup from "../scout/EthicalChecksPopup"
import notificationIcon from "../icons/notificationIcon.png"
import NotificationPopup from "../home/NotificationPopup";
import ScoutGradient from '../scout/ScoutGradient'
import FilmGrain from '../scout/FilmGrain'
import DustEffect from '../scout/DustEffect'

const Cart = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userID, setUserID] = useState()
  const [cart, setCart] = useState([])
  const [isActive, setIsActive] = useState(false);
  const [expandedStores, setExpandedStores] = useState({})
  const [imageGrowthAnimation, setImageGrowthAnimation] = useState()
  const [expandProductCard, setExpandProductCard] = useState(false)
  const [currentProduct, setCurrentProduct] = useState([])
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const trackRef = useRef(null);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [totalShippingPrice, setTotalShippingPrice] = useState(0);
  const [isShippingPriceFetched, setIsShippingPriceFetched] = useState(false)
  const paymentSliderRef = useRef()
  const xVal = useMotionValue(0)
  const [colorArray, setColorArray] = useState([])
  const productInCartAnimation = useAnimation()
  const [showCartMessage, setShowCartMessage] = useState(false)
  let [animation, setAnimation] = useState(50)
  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);
  const [globalTotalShippingPrice, setGlobalTotalShippingPrice] = useState([])
  const [totalSum, setTotalSum] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [blockSlider, setBlockSlider] = useState(false);
  const [count, setCount] = useState(0);
  const [showShippingPopup, setShowShippingPopup] = useState(false);
  const shippingPopupAnimation = useAnimation()
  const [showTotalContainer, setShowTotalContainer] = useState(true)
  const [showNotifications, setShowNotifications] = useState(false)
  const [notifications, setNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [popupClosed, setPopupClosed] = useState(false)

  useEffect(() => {
    document.title = 'Cart | taizte™';
  }, []);

  useEffect(() => {
    if (showShippingPopup) {
      shippingPopupAnimation.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.5 }
      }).then(() => {
        setTimeout(() => {
          setShowShippingPopup(false);
        }, 3500); // Adjust time as needed
      });
    }
  }, [showShippingPopup]);

  const cartColorArray = [
    "rgb(30, 68, 30)",
    "rgb(125, 69, 27)",
    "rgb(198, 202, 83)",
    "rgb(118, 117, 4)",
    "rgb(219, 76, 64)"
  ]

  // In Cart component
  const handleBlockSlider = (shouldBlock, count) => {
    console.log(count)
    setBlockSlider(shouldBlock);
    if (shouldBlock) {
      setCount(count); // Assuming you have a state [count, setCount] for storing the count
    }
  };

  useEffect(() => {
    if (!showPaymentPopup) {
      xVal.set(0);
    }
  }, [showPaymentPopup, xVal])

  const updateGlobalTotalShippingPrice = (storeID, storeName, totalShipping) => {
    setGlobalTotalShippingPrice(prevShippingPrices => {
      // Find the index of the store by storeID
      console.log("Total shipping: ", totalShipping)
      const storeIndex = prevShippingPrices.findIndex(store => store.storeID === storeID);
      let updatedShippingPrices;
      if (storeIndex > -1) {
        // Store exists, replace its total_shipping
        updatedShippingPrices = [...prevShippingPrices];
        updatedShippingPrices[storeIndex] = {
          ...updatedShippingPrices[storeIndex],
          total_shipping: totalShipping // Replacing the value
        };
      } else {
        // Store does not exist, add a new entry with storeID
        updatedShippingPrices = [...prevShippingPrices, { storeID, store: storeName, total_shipping: totalShipping }];
      }

      // Calculate the new total shipping price
      const total = updatedShippingPrices.reduce((acc, store) => acc + store.total_shipping, 0);
      setTotalShippingPrice(total); // Update the total shipping price state
      const cartRef = doc(db, "cart", userID)

      console.log("Total sum: ", totalSum, " Total: ", total)
      updateDoc(cartRef, { cartShippingTotal: total, cart_total: (totalSum + total) })
      return updatedShippingPrices;
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= wideScreenBreakpoint);
    };

    window.addEventListener('resize', handleResize);
    handleResize()
    
    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => { 
    if (props.navBarState === true) {
      setAnimation(-20)
    } else {
      setAnimation(0)
    }
  }, [props.navBarState])

  useEffect(() => {
    if (paymentSliderRef.current) {
      setSliderWidth(paymentSliderRef.current.offsetWidth);
    }
  }, []);

  const dragTransition = (x, y) => {
    if (props.country !== 'South Africa') {
      props.setAlertMessage(`Purchasing is not allowed in ${props.country} yet.`);
      props.setAlertType('error')
      return
    }
    if (blockSlider) {
      setShowShippingPopup(true);
      // Reset the slider position to its original state
      xVal.set(0); 
      return;
    }

    if (x < sliderWidth * 0.7) {
      xVal.set(0);
      setShowPaymentPopup(false);
    } else {
      xVal.set(sliderWidth - 50); 
      setShowPaymentPopup(true);
    }
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserID(user.uid)
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  });

  var groups = {}

  // function below sets the cart's total shipping
  useEffect(() => {
    if (userID) {
      const checkCart = async() => {
        if (userID) {
          getDoc(doc(db, "cart", userID)).then(docSnap => {
            if (docSnap.exists()) {
              const cartData = docSnap.data().cart;
      
              // Transform cartData to a suitable structure for checkedOptions
              let processedBundles = new Set(); // To keep track of processed bundles
              let totalShipping = 0;
              let shippingCounts = new Map(); // To track the count of each shipping method

              cartData.forEach(product => {
                // Set the shipping option for each product
                const storeName = product.store
                const storeID = product.store_id

                const docRef = doc(db, "stores", storeID);

                getDoc(docRef).then(docSnap => {
                  if (docSnap.exists()) {
                    const bundlingRules = (docSnap.data().bundlingRules);
                    console.log("First bundling rules setup: ", bundlingRules)

                    let shippingMethod = product.selected_shippingName;
                    let currentCount = shippingCounts.get(shippingMethod) || 0;
                    shippingCounts.set(shippingMethod, currentCount + 1); // Increment count
        
                    if (
                      product.bundledWith !== "" && 
                      product.bundledWith !== undefined && 
                      (!Array.isArray(product.bundledWith) || (Array.isArray(product.bundledWith) && product.bundledWith.length > 0)) &&
                      !processedBundles.has(product.bundledWith)
                    ) {
                      // Check if the count is within the bundling limit
                      if (shippingCounts.get(shippingMethod) <= (bundlingRules[shippingMethod] || 0) && currentCount === 0) {
                        totalShipping += parseFloat(product.selected_shippingPrice || 0);
                        processedBundles.add(product.bundledWith);
                      }
                    } else {
                      // For non-bundled products, add individual shipping price
                      if (shippingMethod && bundlingRules.hasOwnProperty(shippingMethod)) {
                        if (shippingCounts.get(shippingMethod) <= (bundlingRules[shippingMethod] || 0) && currentCount === 0) {
                          totalShipping += parseFloat(product.selected_shippingPrice || 0);
                        }
                      } else {
                        console.log("Invalid shipping method or bundling rules not defined for:", shippingMethod);
                      }
                    }
                    updateGlobalTotalShippingPrice(storeID, storeName, totalShipping)
                  }
                })
              });
            }
          });
        } else {
          const cartRef = doc(db, "cart", userID)
          updateDoc(cartRef, { cartShippingTotal: 0, cart_total: 0 })
        }
      }
      checkCart()
    }
  }, [userID])

  const setShippingPrice = useCallback((value) => {
    setTotalShippingPrice(value)
  })

  useEffect(() => {
    if (userID) {
      const getProducts = async() => {
        getDoc(doc(db, "cart", userID)).then(docSnap => {
          const list = docSnap.data().cart

          const groups = list.reduce((groups, item) => {
            const store = (groups[item.store] || [])
            store.push(item)
            groups[item.store] = store
            return groups
          }, {})

          console.log("Groups: ", groups)
          for (const store in groups) {
            const total = groups[store].reduce((sum, item) => sum + item.listed_price, 0)
          }

          setCart(groups)
          setIsLoading(false)

          const newTotalSum = Object.keys(groups).reduce((total, storeName) => {
            return total + getTotalForGroup(groups, storeName);
          }, 0);
          setTotalSum(newTotalSum ?? 0)

          const cartRef = doc(db, "cart", userID)
          updateDoc(cartRef, { cart_total: (newTotalSum + totalShippingPrice) })
        })
      }
      getProducts()
    }
  }, [userID])

  const calculateTotalShippingPrice = (cartData) => {
    let totalShipping = 0;
    for (let item of cartData) {
      if (item.selected_shippingPrice) {
        totalShipping += parseFloat(item.selected_shippingPrice);
      }
    }
    return totalShipping;
  };  

  useEffect(() => {
    const fetchTotalShippingPrice = async () => {
      if (userID) { 
        const cartRef = doc(db, 'cart', userID);
        const cartSnap = await getDoc(cartRef);
        if (cartSnap.exists()) {  
          let cartShippingTotal = cartSnap.data().cartShippingTotal;
          if (cartShippingTotal === 0) {
            cartShippingTotal = calculateTotalShippingPrice(cartSnap.data().cart);
            await updateDoc(cartRef, { cartShippingTotal: cartShippingTotal });
          }
          setTotalShippingPrice(cartShippingTotal)
          setIsShippingPriceFetched(true);
        }
      }
    }
  
    fetchTotalShippingPrice();
  }, [userID]);
  
  const getTotalForGroup = (groups, storeName) => {
    const group = groups[storeName]
    return group.reduce((sum, item) => sum + item.listed_price, 0)
  }

  const closeExpandedProductCard = () => {
    setExpandProductCard(false)
  }

  const expandCardFunction = useCallback(async(
    productID, productImage, productPrice, productTitle, store, 
    productDescription, productCategory, data, bundling, cart
  ) => {
    console.log(cart)
    setIsLoading(false)
    setExpandProductCard(true)
    setImageGrowthAnimation(true)
    console.log(data)
    setCurrentProduct([{
      productID: productID,
      productImage: productImage,
      productPrice: productPrice,
      productTitle: productTitle,
      store: store,
      productDescription: productDescription,
      productCategory: productCategory,
      data: data,
      bundling: bundling,
    }])
    console.log([{
      productID: productID,
      productImage: productImage,
      productPrice: productPrice,
      productTitle: productTitle,
      store: store,
      productDescription: productDescription,
      productCategory: productCategory,
      data: data
    }])
    
  })

  const handleTotalShippingPrice = (priceFromChild) => {
    console.log("Total Shipping Price:", priceFromChild);
  
    const cartRef = doc(db, 'cart', userID)
    updateDoc(cartRef, { 
      cartShippingTotal: priceFromChild, 
      cart_total: (totalSum + priceFromChild) 
    })
      .then(() => {
        setTotalShippingPrice(priceFromChild)
        setIsShippingPriceFetched(true)
      });
  };

  const handleDominantColor = (dominantColor, index) => {
    setColorArray((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = dominantColor;
      return newColors;
    });
  };

  const alreadyInCart = useCallback((alreadyInCartMessage) => {
    setShowCartMessage(alreadyInCartMessage);
    console.log('alreadyInCart called with', alreadyInCartMessage)
    if (alreadyInCartMessage == true) {
      setShowCartMessage(true);
  
      // Start the animation
      productInCartAnimation.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.5 },
      });
  
      // Reset the animation after 4 seconds
      setTimeout(() => {
        productInCartAnimation.start({
          y: -30,
          opacity: 0,
          transition: { duration: 0.5 },
        });
      }, 4000);
    } else {
      setShowCartMessage(false);
    }
  }, [productInCartAnimation]);

  const [ethicalChecks, setEthicalChecks] = useState()
  const [ethicalChecksText, setEthicalChecksText] = useState('')
  const [showEthicalChecksPopup, setShowEthicalChecksPopup] = useState(false)

  const openEthicalChecksPopup = useCallback((eCheck, eCheckText) => {
    console.log(eCheck, eCheckText)
    setEthicalChecks(eCheck)
    setEthicalChecksText(eCheckText)
    setShowEthicalChecksPopup(true)
  })

  const togglePopup = () => {
    setShowNotifications(!showNotifications);
    setPopupClosed(!popupClosed); // Set this to true when the popup is closed
  }  

  useEffect(() => {
    if (!userID) return; // Make sure userID is not undefined
    getNotifications();
    console.log(notifications)
  }, [userID]); // Add userID as a dependency
  
  const getNotifications = async () => {
    try {
      const q = query(collection(db, "notifications"), where("userID", "==", userID), where("notificationType", "==", "cartNotification"));
      const querySnapshot = await getDocs(q);
      let fetchedNotifications = [];
  
      querySnapshot.forEach((doc) => {
        let notification = doc.data();
        notification.id = doc.id; // Add document ID to the notification object
        fetchedNotifications.push(notification);
      });
  
      // Sort notifications by time
      fetchedNotifications.sort((a, b) => b.messageDateNumeric - a.messageDateNumeric);
  
      // Update notifications state
      setNotifications(fetchedNotifications);
  
      // Filter and set read and unread notifications
      setReadNotifications(fetchedNotifications.filter(n => n.readStatus === 'read'));
      setUnreadNotifications(fetchedNotifications.filter(n => n.readStatus === 'unread'));
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };  

  const markNotificationAsRead = async (notificationId) => {
    try {
      // Update the readStatus in Firebase
      const notificationRef = doc(db, "notifications", notificationId);
      await updateDoc(notificationRef, {
        readStatus: 'read'
      });
  
      // Update local state
      // Create a new array with the updated readStatus
      const updatedNotifications = notifications.map(notification => {
        if (notification.id === notificationId) {
          return { ...notification, readStatus: 'read' };
        }
        return notification;
      });
  
      // Update the notifications state
      setNotifications(updatedNotifications);
  
      // Update read and unread notifications states
      setReadNotifications(updatedNotifications.filter(n => n.readStatus === 'read'));
      setUnreadNotifications(updatedNotifications.filter(n => n.readStatus === 'unread'));
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  return (
    <div className="cartBody">
      {showPaymentPopup && 
        <PaymentPopup 
          onClickOutside={() => setShowPaymentPopup(false)} 
          userID={userID}
          totalAmount={(totalSum ?? 0) + (totalShippingPrice ?? 0)}
          setAlertMessage={props.setAlertMessage}
          setAlertType={props.setAlertType}
        />
      }
      {!isLoading && expandProductCard &&
        <div className="cartCardPopupDiv">
          <div style={{ position: 'relative', width: 'fit-content', height: 'fit-content', margin: 'auto'}}>
            <div className="marketDelete" onClick={closeExpandedProductCard}>
              <img src={deleteIcon} alt="delete uploaded" id="marketDeleteIcon" />
            </div>
            {currentProduct.map((object, index) => (
            <ScoutCard
              showLogin={props.showLogin}
              imageGrowthAnimation={imageGrowthAnimation}
              isLoggedIn={isLoggedIn}
              productID={object.productID}
              data={object.data}
              image={object.productImage}
              bundling={object.bundling}
              onDominantColor={handleDominantColor}
              listed_price={object.productPrice}
              title={object.productTitle}
              store={object.store}
              storeID={object.storeID}
              description={object.productDescription}
              category={object.productCategory}
              index={index}
              navBarState={props.navBarState}
              userID={userID}
              alreadyInCart={alreadyInCart}
              openEthicalChecksPopup={openEthicalChecksPopup}
            />
            ))}
          </div>
        </div>
      }
      <style>{'body { background: linear-gradient(-160deg, #E94C49, #F56551 ); }' }</style>
      <div className="cartHeader">
        <div className="cartHeadingDiv">
          <h1 className='cartHeading'>cart.</h1>
        </div>
        <div className="cartBtnDiv">
          <div className="trackParcelBtn">track parcel</div>
          <img className="cartHistoryIcon" src={historyIcon} alt="history" />
          <div className="cartNotificationIconContainer" style={{ position: 'relative' }}>
            <img src={notificationIcon} className="cartNotificationIcon" onClick={togglePopup}/>
            {unreadNotifications.length > 0 && (
              <span className="cartNotificationBadge">
                {unreadNotifications.length}
              </span>
            )}
          </div>
        </div>
      </div>
      {showNotifications && 
        <NotificationPopup 
          userID={userID}
          markNotificationAsRead={markNotificationAsRead}
          notifications={notifications}
          unreadNotifications={unreadNotifications}
          readNotifications={readNotifications}
        />
      }
      
      {/* **Modified Section Starts Here** */}
      {/* Replace the loading icon with conditional messages */}
      {!isLoading && !isLoggedIn && (
        <div className="emptyCartDiv">Please log in to view your cart.</div>
      )}
      {!isLoading && isLoggedIn && Object.keys(cart).length === 0 && (
        <div className="emptyCartDiv">Your cart is empty.</div>
      )}
      {/* **Modified Section Ends Here** */}

      {/* Optionally, you can remove the original loading icon section */}
      {/* {isLoading ?
        <div className="cartLoadingDiv">
          <img src={loadIcon} className="cartLoadingIcon" alt="loading icon" />
        </div> : null
      } */}

      {/* Alternatively, if you still want to handle the loading state differently, you can adjust accordingly */}
      {/* For example, show nothing or a different message while loading */}
      
      <div className="cartProductSection">
        {!isLoading && isLoggedIn && Object.keys(cart).length > 0 && (
          Object.keys(cart).map(storeName => {
            if (cart[storeName] && cart[storeName].length > 0) {
              console.log(cart[storeName][0].address)
              return (
                <CartContainer
                  key={storeName}
                  setShippingPrice={setShippingPrice} 
                  updateGlobalTotalShippingPrice={updateGlobalTotalShippingPrice}
                  storeName={storeName}
                  storeID={cart[storeName][0].store_id} // We've already checked that this exists
                  cart={cart}
                  address={cart[storeName][0].address}
                  expandedStores={expandedStores}
                  setIsActive={setIsActive}
                  isActive={isActive}
                  expandCardFunction={expandCardFunction}
                  getTotalForGroup={getTotalForGroup}
                  setExpandedStores={setExpandedStores}
                  handleTotalShippingPrice={handleTotalShippingPrice}
                  setIsLoadingDeleted={setIsLoading}
                  setCart={setCart}
                  onBlockSlider={handleBlockSlider}
                  setShowTotalContainer={setShowTotalContainer}
                  setAlertMessage={props.setAlertMessage}
                  setAlertType={props.setAlertType}
                  country={props.country}
                />
              );
            } else {
              // If there are no items, don't render CartContainer and optionally handle this case
              return null; // Or some other placeholder/fallback
            }
          })
        )}
      </div>
      {showEthicalChecksPopup && 
        <EthicalChecksPopup 
          onClickOutside={() => {setShowEthicalChecksPopup(false)}}
          ethicalChecks={ethicalChecks}
          ethicalChecksText={ethicalChecksText}
        />
      }
      {showShippingPopup && (
        <motion.div 
          className="shippingPopupContainer"
          initial={{ y: -50, opacity: 0 }}
          animate={shippingPopupAnimation}
        >
          <div className="shippingPopup">
            {count} {count === 1 ? 'product' : 'products'} still need{count === 1 ? 's' : ''} shipping to be selected.
          </div>
        </motion.div>
      )}
      {showTotalContainer && isLoggedIn && Object.keys(cart).length !== 0 ? 
      <motion.div className="cartTotalContainer" animate={{ y: animation }}>
        <div className="cartTotalDiv">
          <div className="productsTotal">
            <div className="productsText">Products</div>
            <div className="productsAmount">R{(totalSum ?? 0).toFixed(2)}</div>
          </div>
          <div className="shippingHandlingFeesTotal">
            <div className="shippingHandlingFeesText">Shipping & Handling fees</div>
            <div className="shippingHandlingFeesAmount">R{(totalShippingPrice ?? 0).toFixed(2)}</div>
          </div>
          <hr border-radius="2" size="3" width="95%" color="#000000"/>
          <div className="cartTotal">
            <div className="cartTotalText">Total</div>
            <div className="cartTotalAmount">R{((totalSum ?? 0) + (totalShippingPrice ?? 0)).toFixed(2)}</div>
          </div>
          <div className="paymentSlider" ref={paymentSliderRef}>
            <div ref={trackRef} className="paymentSliderTrack">
              <motion.div className="paymentSliderButton"
                style={{ x: xVal }}
                key={props.index}
                drag="x"
                dragConstraints={trackRef}
                dragMomentum={!blockSlider}
                dragElastic={0}
                onDragEnd={dragTransition}
              />
            </div>
          </div>
        </div>
      </motion.div> : null
      }
      {showCartMessage && 
        <motion.div
          className="alreadyInCartDiv"
          initial={{ y: -30, opacity: 0 }}
          animate={productInCartAnimation}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="alreadyInCartDivText">
            The product is already in the cart, to review it go to the cart page
          </div>
        </motion.div>
      }
    </div>
  )
}
 
export default Cart

