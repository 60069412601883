// PaymentPopup.jsx

import { useState, useEffect, useRef } from "react";
import { getDoc, doc, setDoc } from "@firebase/firestore"; // Added setDoc to clear the cart
import { db } from "../firebase";
import { motion } from "framer-motion";

import './PaymentPopup.css';

const PaymentPopup = ({
  onClickOutside,
  userID,
  totalAmount,
  setAlertMessage,
  setAlertType,
}) => {
  const paymentPopupRef = useRef();

  const [cartItems, setCartItems] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [buyerAddress, setBuyerAddress] = useState("");
  const [yocoSDK, setYocoSDK] = useState(null);

  // Handle clicking outside the payment popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        paymentPopupRef.current &&
        !paymentPopupRef.current.contains(event.target)
      ) {
        onClickOutside?.();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside]);

  // Fetch cart items from Firestore
  useEffect(() => {
    const fetchCartItems = async () => {
      if (userID) {
        const cartRef = doc(db, "cart", userID);
        const cartSnap = await getDoc(cartRef);
        if (cartSnap.exists()) {
          const cartData = cartSnap.data().cart;
          setCartItems(cartData);
          const cartDeliveryFee = cartSnap.data().deliveryFee || 0;
          const cartBuyerAddress = cartSnap.data().buyerAddress || "";
          setDeliveryFee(cartDeliveryFee);
          setBuyerAddress(cartBuyerAddress);
        } else {
          setCartItems([]);
        }
      }
    };

    fetchCartItems();
  }, [userID]);

  // Load the YOCO SDK
  useEffect(() => {
    if (window.YocoSDK) {
      const yoco = new window.YocoSDK({
        publicKey: process.env.REACT_APP_YOCO_PUBLIC_KEY,
      });
      setYocoSDK(yoco);
    } else {
      const script = document.createElement("script");
      script.src = "https://js.yoco.com/sdk/v1/yoco-sdk-web.js";
      script.async = true;
      script.onload = () => {
        const yoco = new window.YocoSDK({
          publicKey: process.env.REACT_APP_YOCO_PUBLIC_KEY,
        });
        setYocoSDK(yoco);
      };
      script.onerror = () => {
        setAlertMessage("Failed to load Yoco SDK");
        setAlertType("error");
      };
      document.body.appendChild(script);
    }
  }, [setAlertMessage, setAlertType]);

  // Handle the payment process
  const handlePayment = (totalAmount) => {
    if (!yocoSDK) {
      setAlertMessage("Payment gateway is not ready. Please try again later.");
      setAlertType("error");
      return;
    }

    if (totalAmount === 0) {
      setAlertMessage("Total amount cannot be R0. Please check your cart.");
      setAlertType("error");
      return;
    }

    // Convert totalAmount from Rands to Cents
    const amountInCents = totalAmount * 100;

    yocoSDK.showPopup({
      amountInCents: amountInCents,
      currency: "ZAR",
      name: "Taizte",
      description: "Purchase Products",
      callback: function (result) {
        if (result.error) {
          setAlertMessage("Error: " + result.error.message);
          setAlertType("error");
        } else {
          // Send the token to your backend for processing
          processPayment(result.id, totalAmount, cartItems);
        }
      },
    });
  };

  // Process payment by sending token to the backend
  const processPayment = async (token, amount, items) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/process-payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
            amount: amount * 100, // Convert to cents
            currency: "ZAR",
            description: "Purchase Products",
            metadata: {
              userID,
              items,
              deliveryFee,
              buyerAddress,
            },
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        setAlertMessage("Payment successful!");
        setAlertType("success");
        // Clear the cart after successful payment
        await setDoc(doc(db, "cart", userID), {
          cart: [],
          deliveryFee: 0,
          buyerAddress: "",
        });
        // Optionally, redirect the user or update the UI
      } else {
        setAlertMessage("Payment failed: " + data.message);
        setAlertType("error");
      }
    } catch (error) {
      setAlertMessage("Payment processing error: " + error.message);
      setAlertType("error");
    }
  };

  return (
    <div className="paymentPopupContainer">
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{ stiffness: 100 }}
        ref={paymentPopupRef}
        className="paymentPopupDiv"
      >
        <div className="paymentPopupText">Payment</div>
        <div className="paymentDetails">
          <p>Total Amount: R{totalAmount.toFixed(2)}</p>
          <button
            className="payNowButton"
            onClick={() => handlePayment(totalAmount)}
          >
            Pay Now
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default PaymentPopup;
