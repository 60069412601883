import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, storage } from './firebase';
import { motion, AnimatePresence, useAnimation } from "framer-motion";

import PortraitLock from "./PortraitLock"; 
import Layout from './Layout';
import Home from "./home/Home";
import Favourites from "./home/Favourites";
import Scout from "./scout/Scout";
import Market from "./market/Market";
import Vendor from "./vendor/Vendor";
import Website from "./user website/Website";
import AddProduct from "./vendor/add product/AddProduct";
import Wallet from "./vendor/wallet/Wallet";
import Dashboard from "./vendor/dashboard/Dashboard";
import Cart from "./cart/Cart";
import CreateStore from "./create store/CreateStore";
import StorelessUser from "./create store/StorelessUser";
import useFavicon from "./user website/useFavicon";
import Alert from './login/Alert'
import FullScreenPopup from "./FullScreenPopup";
import './Layout.css'; 

function ResetProductSavedMessage({ setProductSavedMessage, productSavedMessageAnimation }) {
  const location = useLocation();
  const lastPath = useRef();

  useEffect(() => {
    if (lastPath.current !== '/addproduct') {
      productSavedMessageAnimation.start({
        y: 50,
        opacity: 0,
        transition: { duration: 0.5 },
      }).then(() => {
        setTimeout(() => {
          setProductSavedMessage(false);
        }, 500);  // Match the duration of your animation
      });
    }
    lastPath.current = location.pathname;
  }, [location, productSavedMessageAnimation, setProductSavedMessage]);

  return null;
}

function App() {
  const [isMobile, setIsMobile] = useState(null);
  const [userID, setUserID] = useState();
  const [websiteName, setWebsiteName] = useState();
  const [navBarState, setNavBarState] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const offlineMessageAnimation = useAnimation();
  const productSavedMessageAnimation = useAnimation();
  const [productSavedMessage, setProductSavedMessage] = useState(false);
  const [transitionStarted, setTransitionStarted] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success"); 

  // 1. Add state for country
  const [country, setCountry] = useState('');
  
  // 2. Fetch country data on mount
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCountry(data.country_name);
        console.log(`User country: ${data.country_name}`);
      } catch (error) {
        console.error('Error fetching country:', error);
      }
    };

    fetchCountry();
  }, []);

  const setFavicon = useFavicon('/favicon.ico');

  useEffect(() => {
    // Set default favicon when the app loads
    setFavicon('/favicon.ico');
    console.log('Default favicon set in App.jsx');
  }, [setFavicon])

  useEffect(() => {
    const handleResize = () => {
      // Set isMobile to true if the screen width is less than or equal to 768px
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Add event listener to update on window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function updateOnlineStatus() {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        offlineMessageAnimation.start({
          y: -30,
          opacity: 0,
          transition: { duration: 0.5 },
        });
      } else {
        offlineMessageAnimation.start({
          y: 0,
          opacity: 1,
          transition: { duration: 0.5 },
        });
      }
    }
  
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, [offlineMessageAnimation]);
  
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserID(user.uid);
    }
  });

  const navBarStateFunction = useCallback(async(state) => {
    setNavBarState(state);
  }, []);

  const setProductSavedMessageFunction = useCallback((state) => {
    if (state) {
      setProductSavedMessage(true);
      setTimeout(() => {
        setProductSavedMessage(false);
      }, 3500); // Show the message for 3.5 seconds then hide
    }
  }, []);

  // Updated renderProductSavedMessage function
  const renderProductSavedMessage = () => {
    return (
      <AnimatePresence>
        {productSavedMessage && (
          <motion.div className="productSavedMessagePopupContainer" 
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { duration: 0.5 } }}
            exit={{ y: 100, opacity: 0, transition: { duration: 0.5 } }}
          >
            <div className="productSavedMessageDiv">
              Your product draft has been saved to your dashboard for future editing.
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return (
    <BrowserRouter>
      <PortraitLock/>
      <ResetProductSavedMessage setProductSavedMessage={setProductSavedMessage} productSavedMessageAnimation={productSavedMessageAnimation} />
      <motion.div className="offlineMessagePopupContainer" 
        initial={{ y: -50, opacity: 0 }}
        animate={offlineMessageAnimation}
        exit={{ y: -50, opacity: 0 }}
      >
        <motion.div
          className="offlinePopupMessageDiv"
        >
          {!isOnline && 'You are currently offline. Please check your network connection.'}
        </motion.div>
      </motion.div>
      <Alert component={'app'} message={alertMessage} type={alertType} onClose={() => setAlertMessage("")} />
      {!isMobile &&
        <FullScreenPopup navBarOpen={navBarState}/>
      }
      <Routes>
        <Route path="/" element={<Layout userID={userID} navBarStateFunction={navBarStateFunction} />}>
          <Route index element={<Home userID={userID} setAlertMessage={setAlertMessage} setAlertType={setAlertType}/>}/>
          {/* 3. Pass the country as a prop to Scout and Vendor */}
          <Route path="scout" element={<Scout userID={userID} navBarState={navBarState} country={country} setTransitionStarted={setTransitionStarted} transitionStarted={transitionStarted} setAlertMessage={setAlertMessage} setAlertType={setAlertType}/>} />
          <Route path="market" element={<Market userID={userID} setTransitionStarted={setTransitionStarted} setAlertMessage={setAlertMessage} setAlertType={setAlertType}/>} />
          <Route path="vendor" element={<Vendor userID={userID} navBarState={navBarState} country={country} setAlertMessage={setAlertMessage} setAlertType={setAlertType}/>} />
          <Route path="cart" element={<Cart userID={userID} navBarState={navBarState} setAlertMessage={setAlertMessage} setAlertType={setAlertType} country={country}/>} />
          <Route path="favourites" element={<Favourites userID={userID} setAlertMessage={setAlertMessage} setAlertType={setAlertType}/>} />
          <Route path="/:websiteName" element={<Website userID={userID} navBarState={navBarState}  setTransitionStarted={setTransitionStarted} transitionStarted={transitionStarted} setAppFavicon={setFavicon} setAlertMessage={setAlertMessage} setAlertType={setAlertType}/>} />
          <Route path="create-store" element={<StorelessUser userID={userID} navBarState={navBarState} setAlertMessage={setAlertMessage} setAlertType={setAlertType} country={country}/>} />
          <Route path="addproduct" element={<AddProduct userID={userID} setProductSavedMessageFunction={setProductSavedMessageFunction} navBarState={navBarState} setAlertMessage={setAlertMessage} setAlertType={setAlertType} country={country}/>} />
          <Route path="wallet" element={<Wallet userID={userID} navBarState={navBarState} setAlertMessage={setAlertMessage} setAlertType={setAlertType} country={country}/>} />
          <Route path="dashboard" element={<Dashboard userID={userID} navBarState={navBarState} setAlertMessage={setAlertMessage} setAlertType={setAlertType} country={country}/>} />
        </Route> 
      </Routes>
      {renderProductSavedMessage()}
    </BrowserRouter>
  );
}

export default App;
