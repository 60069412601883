import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const BeautyTags = (props) => {
  const [tags, setTags] = useState(["beauty"])
  const newTagRef = useRef()

  const generalTags = [
    'beauty',
    'beard care',
    'body care',
    'epilator',
    'fragrances',
    'hair',
    'makeup',
    'skin care',
    'wigs',
    'nails',
  ]
  let specificNails = [
    'nail polish',
    'nail clipper',
    'nails',
    'grooming kit',
    'hand scrub',
    'cuticle eraser',
    'nail file',
    'buffer block',
    'nail strengthener',
    'base coat',
    'top coat',
    'cuticle oil',
    'balm',
    'hand cream',
    'nail polish',
  ]
  let specificBodyCare = [
    'lotion',
    'rollon',
    'nail clipper',
    'soap',
    'bubble bath',
    'moisturiser',
    'handlotion',
    'body wash',
    'antiseptic balm',
    'shea butter',
    'body oil',
    'sunscreen',
    'sun cream',
    'sunscream',
    'SPF 15',
    'SPF 20',
    'SPF 30',
    'SPF 40',
    'cream',
    'liquid soap',
    'shower oil',
    'body oil',
    'shaping cream',
  ]
  let specificFragrances = [
    'perfume',
    'spray',
    'everyday scents',
    'luxury scents',
    'deodorant',
    'cologne',
    'Eau de Toilette',
  ]
  let specificHair = [
    'bonnet',
    'razor',
    'blade',
    'shaver',
    'electric shaver',
    'shaving cream',
    'shaving gel',
    'aftershave',
    'electric shaver head',
    'grooming kit',
    'clipper',
    'shampoo',
    'conditioner',
    'leave in conditioner',
    'foam conditioner',
    'dry shampoo',
    'hair scrub',
    'co-wash',
    'hairdye',
    'oil',
    'combing cream',
    'treatment',
    'comb',
    'brush',
    'detangling brush',
    'flex brush',
    'mask',
    'gel',
    'curl cream',
    'blow drye spray',
    'travel hair dryer',
    'blowdryer',
    'hair dryer',
    'hair diffuser',
    'curling iron',
    'curling tong',
    'traightening iron',
    'thickening spray',
    'anti frizz',
    'hairspray',
    'braid',
    'hairband',
    'hair tie',
    'ponytail',
    'scissors',
  ]
  let specificMakeup = [
    'concealer',
    'foundation',
    'blush',
    'highlighter',
    'contour',
    'lipstick',
    'eye shadow',
    'setting spray',
    'palette',
    'eyebrow pencil',
    'eyeliner',
    'liquid eyeliner',
    'under-eye concealer',
    'mascara',
    'colour-correcting concealer',
    'primer',
    'lash primer',
    'eyebrow powder',
    'lip scrub',
    'lip balm',
    'lip liner',
    'lip gloss',
    'face jewels',
  ]
  let specificBeardCare = [
    'razor',
    'blade',
    'shaver',
    'electric shaver',
    'shaving cream',
    'shaving gel',
    'aftershave',
    'electric shaver head',
    'grooming kit',
    'beard oil',
    'clipper',
  ]
  let specificSkinCare = [
    'razor',
    'toner',
    'face wash',
    'cleanser',
    'exfoliator',
    'treatment',
    'charcoal mask',
    'mask',
    'serum',
    'scrub',
    'face oil',
    'sunscreen',
    'sun cream',
    'sunscream',
    'SPF 15',
    'SPF 20',
    'SPF 30',
    'SPF 40',
    'chemical peel',
    'moisturiser',
    'eye cream',
    'facewash',
    'dermaroller',
    'scrubber',
    'facial brush',
    'micro-sculpting cream',
    'anti-aging',
    'anti-wrinkle',
    'peel',
    'lotion',
    'ointment',
  ]
  let specificWigs = [
    'curly',
    'straight',
    'wavy',
    'brazilian',
    'bob',
    'fringe',
    'balayage',
    'afro',
    'pixie',
    'half pixie',
    'extensions',
    'ponytail',
    'hairband',
    'human hair',
    'glueless',
    'lace',
    'welded lace',
    'swiss lace',
    'single monofilament',
    'double monofilament',
    'glass silk',
    'french drawn',
    'polyurethane',
    'silicone',
    'anti-slip',
    'suction',
    'capless',
    'weave',
    'braids',
    'synthetic hair',
    'heat friendly synthetic hair',
    'virgin hair',
    'single knot',
    'single split knot',
    'double knot',
    'V-loop knot',
    'direct inject knot',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>

          {tags.includes('nails') ? (
            specificNails.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('body care') ? (
            specificBodyCare.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('fragrances') ? (
            specificFragrances.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('hair') ? (
            specificHair.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('makeup') ? (
            specificMakeup.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('beard care') ? (
            specificBeardCare.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('skin care') ? (
            specificSkinCare.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('wigs') ? (
            specificWigs.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default BeautyTags