import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const HobbiesToysTags = (props) => {
  const [tags, setTags] = useState(["hobbies & toys"])
  const newTagRef = useRef()

  const generalTags = [
    'hobbies & toys',
    'action figure',
    'arts & crafts',
    'bath toy',
    'battery & wind-up',
    'bear',
    'bicycle',
    'bike',
    'boardgame',
    'cards',
    'collectable toy',
    'desktop & novelty',
    'die cast',
    'doll',
    'educational',
    'electronic toy',
    'hoverboard',
    'instrument',
    'interactive toy',
    'kids furniture',
    'LEGO',
    'magic',
    'models & kits',
    'musical toy',
    'outdoor toy & structures',
    'paint',
    'pretend playing',
    'puzzle',
    'radio control',
    'remote control',
    'scooter',
    'soft toy',
    'stationary',
    'tricycle',
    'vintage toy',
    'wooden toy',
  ]
  let specificBicycle = [
    'helmet',
    'protective gear',
    'parts',
    'accessory',
  ]
  let specificDieCast = [
    'model',
    'accessory',
    'part',
    'display',
  ]
  let specificModelsKits = [
    'railway',
    'locomotive',
    'train set',
    'track',
    'scenery',
    'parts',
    'accessory',
    'catalogue',
    'magazine',
    'rolling stock',
    'aircraft',
    'spacecraft',
    'car',
    'truck',
    'figures',
    'animal',
    'military',
    'armour',
    'artillery',
    'infantry',
    'motorcycle',
    'ship',
    'boat',
    'architecture',
    'diorama',
    'tools',
    'supplies',
    'paint brush',
    'decal',
    'craft tool',
    'paint',
    'varnish',
  ]
  let specificRadioControl = [
    'drone',
    'aeroplace',
    'park flyer',
    'slower flyer',
    'sailplane',
    'glider',
    'trainer',
    'helicopter',
    'boat',
    'navy',
    'marine',
    'speed',
    'racing',
    'car',
    'buggy',
    'crawler',
    'drift car',
    'formula 1',
    'motorcycle',
    'robot',
    'truck',
    'tank',
    'parts',
    'accessory',
  ]
  let specificRemoteControl = [
    'drone',
    'aeroplace',
    'park flyer',
    'slower flyer',
    'sailplane',
    'glider',
    'trainer',
    'helicopter',
    'boat',
    'navy',
    'marine',
    'speed',
    'racing',
    'car',
    'buggy',
    'crawler',
    'drift car',
    'formula 1',
    'motorcycle',
    'robot',
    'truck',
    'tank',
    'parts',
    'accessory',
  ]
  let specificLEGO = [
    'LEGO set',
    'basic bricks',
    'minifigures',
    'magnetic set',
    'meccano',
    'wooden block',
    'fischertechnik',
  ]
  let specificCollectableToy = [
    'classic toy',
    'collectable car',
    'fast food toy',
    'cereal toy',
    'trading cards',
  ]
  let specificActionFigure = [
    'animal',
    'nature',
    'anime',
    'manga',
    'comic book',
    'designer',
    'fantasy',
    'historical',
    'military',
    'aviation',
    'music',
    'sports',
    'robots',
    'TV character',
    'movie character',
    'videogame character',
  ]
  let specificBoardgame = [
    'adult game',
    'chess',
    'draughts',
    'drinking game',
    'checkers',
    'dice game',
    'floor game',
    'general knowledge',
    'trivia',
    'party game',
    'strategy game',
    'war game',
    'Monopoly',
    'tile game',
    'TV game',
    'movie game',
    'video game',
    'word game',
  ]
  let specificCards = [
    'poker',
    'trading cards',
  ]
  let specificPretendPlaying = [
    'doctor',
    'nurse',
    'dress-up',
    'kitchen',
    'housework',
    'puppet',
    'tools',
    'building',
  ]
  let specificDoll = [
    'clothing',
    'shoes',
    'accessory',
    'doll house',
    'castle',
    'house',
    'modern',
  ]
  let specificBear = [
    'teddy bear',
    'clothing',
    'accessory',
  ]
  let specificEducational = [
    'alphabet',
    'animal',
    'geography',
    'history',
    'kids computer',
    'kids software',
    'language',
    'math & numbers',
    'music',
    'reading & writing',
    'science',
    'nature',
    'shapes',
    'colours',
    'autism',
    'special needs',
    'timer',
  ]
  let specificOutdoorToyStructures = [
    'ball',
    'frisbee',
    'boomerang',
    'climbing frame',
    'dart gun',
    'jumping castle',
    'inflatable',
    'kite',
    'padding pool',
    'blowup pool',
    'inflatable pool',
    'sand pit',
    'playhouse',
    'sand toy',
    'water toy',
    'swing',
    'slide',
    'tent',
    'tunnel',
    'trampoline',
    'water slide',
  ]
  let specificSoftToy = [
    'character toy',
    'stuffed animal',
  ]
  let specificBatteryWindUp = [
    'animal',
    'car',
    'motorcycle',
    'robot',
    'train',
    'boat',
    'bus',
    'truck',
    'digger',
    'excavator',
  ]
  let specificKidsFurniture = [
    'bean bag',
    'bed',
    'decor',
    'table',
    'chair',
    'toy box',
    'storage',
  ]
  let specificPuzzle = [
    '3D puzzle',
    'brain teaser',
    'floor puzzle',
    'jigsaw',
    'lenticular puzzle',
    'pegged puzzle',
    'puzzle play mat',
  ]
  let specificArtsCrafts = [
    'activity book',
    'colouring book',
    'apron',
    'smock',
    'art & craft kit',
    'easel',
    'blackboard',
    'jewellery',
    'beads',
    'paint',
    'paintbrush',
    'chalk',
    'colouring pencil',
    'easel',
    'face paint',
    'fibre tip pen',
    'pen',
    'marker',
    'oil pastel',
    'paint',
    'paint set',
    'paper',
    'canvas',
    'weaving',
    'looming',
    'wool',
    'thread',
  ]
  let specificStationary = [
    'blackboard',
    'chalk',
    'calculator',
    'dictionary',
    'thesaurus',
    'eraser',
    'correction fluid',
    'file',
    'binder',
    'folder',
    'sleeve',
    'glue',
    'adhesive',
    'tape',
    'lunchbox',
    'cooler',
    'marker',
    'highlighter',
    'math set',
    'paper',
    'notebook',
    'pencil case',
    'pencil tin',
    'sharpener',
    'pen',
    'pencil',
    'refill',
    'mechanical pen',
    'mechanical pencil',
    'sticky note',
    'post-it',
    'ruler',
    'school bag',
    'scissor',
    'stationary set',
    'whiteboard',
    'whiteboard marker',
  ]
  let specificInstrument = [
    'drum',
    'shaker',
    'flute',
    'guitar',
    'microphone',
    'music set',
    'piano',
    'keyboard',
    'trumpet',
    'xylophone',
    'violin',
  ]
  let specificPaint = [
    'oil',
    'paintbrush',
    'acrylic',
    'watercolour',
    'gouche',
    'flat',
    'matte',
    'eggshell',
    'gloss',
    'semi-gloss',
    'high-gloss',
    'satin',
    'canvas',
    'easel',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>
          
          {tags.includes('bicycle') ? (
            specificBicycle.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('die cast') ? (
            specificDieCast.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('models & kits') ? (
            specificModelsKits.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('radio control') ? (
            specificRadioControl.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('remote control') ? (
            specificRemoteControl.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('LEGO') ? (
            specificLEGO.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('collectable toy') ? (
            specificCollectableToy.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('action figure') ? (
            specificActionFigure.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('boardgame') ? (
            specificBoardgame.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('cards') ? (
            specificCards.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('pretend playing') ? (
            specificPretendPlaying.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('doll') ? (
            specificDoll.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('bear') ? (
            specificBear.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('educational') ? (
            specificEducational.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('outdoor toy & structures') ? (
            specificOutdoorToyStructures.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('soft toy') ? (
            specificSoftToy.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('battery & wind-up') ? (
            specificBatteryWindUp.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('kids furniture') ? (
            specificKidsFurniture.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('puzzle') ? (
            specificPuzzle.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('arts & crafts') ? (
            specificArtsCrafts.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('stationary') ? (
            specificStationary.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('instrument') ? (
            specificInstrument.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('paint') ? (
            specificPaint.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

        </div>
      </div>
    </div>
  )
}

export default HobbiesToysTags