// Login.js
import React, { useState, useContext, useRef, useEffect } from "react";
import { 
  signInWithEmailAndPassword, createUserWithEmailAndPassword, 
  onAuthStateChanged, GoogleAuthProvider, signInWithPopup,
  signOut, updateProfile, getRedirectResult, 
  sendPasswordResetEmail, AuthErrorCodes
} from "firebase/auth";
import { AuthContext } from "../context/AuthContext";
import { auth, db } from "../firebase";
import { doc, setDoc, getDoc } from '@firebase/firestore';
import Alert from "./Alert"; // Import the Alert component

import "./Login.css";
import "./Alert.css"; // Ensure Alert styles are included
import googleLogo from '../icons/googleLogo.png';
import deleteIcon from '../icons/deleteIcon.png';
import eyeIcon from '../icons/eyeIcon.png';

function Login (props) {
  const loginRef = useRef();
  const googleLoginRef = useRef();
  const alreadyLoggedInRef = useRef();

  // Updated state variables for first name and surname
  const [createFirstName, setCreateFirstName] = useState("");
  const [createSurname, setCreateSurname] = useState("");
  const [createEmail, setCreateEmail] = useState("");
  const [createPassword, setCreatePassword] = useState("");

  // Separate state variables for Login
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showAlreadyLoggedIn, setShowAlreadyLoggedIn] = useState(false);

  const { dispatch } = useContext(AuthContext);


  // Alert state
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success"); // "success" or "error"

  // Password Reset Popup state
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  // Utility function to validate first name and surname
  const isNameValid = (firstName, surname) => {
    return (
      firstName.trim().length > 0 &&
      surname.trim().length > 0 &&
      /^[A-Za-z]+$/.test(firstName) && // Optional: Ensure only letters
      /^[A-Za-z]+$/.test(surname)
    );
  };

  // Utility function to validate password strength with a broader set of special characters
  const isPasswordStrong = (pwd) => {
    // This regex allows letters, digits, and a variety of special characters
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return regex.test(pwd);
  };


  // Utility function to validate email format
  const isEmailValid = (em) => {
    // Simple email regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(em);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const togglePassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setShowLogin(false);
        setShowAlreadyLoggedIn(true);
  
        // Check and set user data in Firestore
        const dbRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(dbRef);
        if (!docSnap.exists()) {
          const userInfo = {
            firstName: createFirstName, // Updated
            surname: createSurname,     // Updated
            userID: user.uid,
            email: user.email,
            displayName: `${createFirstName} ${createSurname}`, // Ensure displayName is set
          };
          await setDoc(dbRef, userInfo);
          console.log("User document created in Firestore.");
        } else {
          console.log("User already exists in Firestore.");
        }
  
        // Dispatch the login action
        dispatch({ type: "LOGIN", payload: user });
  
        // Notify the user
        setAlertMessage("You have successfully logged in.");
        setAlertType("success");
  
        // Clear login inputs if any
        setLoginEmail("");
        setLoginPassword("");
      } else {
        setShowLogin(true);
        setShowAlreadyLoggedIn(false);
        dispatch({ type: "LOGOUT", payload: null });
      }
    });
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch, createFirstName, createSurname]); // Updated dependencies
  
  
  // Handle the result of signInWithRedirect
  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          // The signed-in user info.
          const user = result.user;
          // Optional: Google Access Token, if needed
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;

          // Check if user info needs to be added to Firestore
          const dbRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(dbRef);
          if (!docSnap.exists()) {
            const userInfo = {
              firstName: createFirstName, // Updated
              surname: createSurname,     // Updated
              userID: user.uid,
              email: user.email,
              displayName: `${createFirstName} ${createSurname}`, // Ensure displayName is set
            };
            await setDoc(dbRef, userInfo);
            console.log("User document created in Firestore.");
          } else {
            console.log("User already exists in Firestore.");
          }

          // Dispatch the login action
          dispatch({ type: "LOGIN", payload: user });

          // Notify the user
          setAlertMessage("You have successfully logged in with Google.");
          setAlertType("success");

          // Clear login inputs if any
          setLoginEmail("");
          setLoginPassword("");
        }
      } catch (error) {
        console.error("Error handling redirect result:", error);
        setAlertMessage("Error signing in with Google.");
        setAlertType("error");
      }
    };

    handleRedirectResult();
  }, [dispatch, createFirstName, createSurname]); // Updated dependencies

  const handleLogin = (e) => {
    e.preventDefault();

    // Validation Checks
    if (!loginEmail.trim() && !loginPassword.trim()) {
      setAlertMessage("Please enter your email and password.");
      setAlertType("error");
      return;
    }

    if (!loginEmail.trim()) {
      setAlertMessage("Please enter your email.");
      setAlertType("error");
      return;
    }

    if (!isEmailValid(loginEmail)) {
      setAlertMessage("Please enter a valid email address.");
      setAlertType("error");
      return;
    }

    if (!loginPassword.trim()) {
      setAlertMessage("Please enter your password.");
      setAlertType("error");
      return;
    }

    // Check if email ends with @gmail.com
    if (loginEmail.toLowerCase().endsWith('@gmail.com')) {
      setAlertMessage("Please use the quick login option for Gmail accounts.");
      setAlertType("error");
      return;
    }

    // Proceed with Firebase Authentication
    signInWithEmailAndPassword(auth, loginEmail, loginPassword)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        dispatch({type:"LOGIN", payload:user});
        console.log(user);
        setAlertMessage("You have successfully logged in.");
        setAlertType("success");

        // Clear login inputs
        setLoginEmail("");
        setLoginPassword("");
      })
      .catch((error) => {
        console.error("Error details:", error);
        // Customize error message based on error.code if needed
        setAlertMessage("Wrong email or password.");
        setAlertType("error");
      });
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
  
    // Validation Checks
    if (!createFirstName.trim() && !createSurname.trim() && !createEmail.trim() && !createPassword.trim()) {
      setAlertMessage("Please enter your first name, surname, email, and password.");
      setAlertType("error");
      return;
    }

    if (!createFirstName.trim()) {
      setAlertMessage("Please enter your first name.");
      setAlertType("error");
      return;
    }

    if (!createSurname.trim()) {
      setAlertMessage("Please enter your surname.");
      setAlertType("error");
      return;
    }

    if (!isNameValid(createFirstName, createSurname)) {
      setAlertMessage("Please enter a valid first name and surname (letters only).");
      setAlertType("error");
      return;
    }

    if (!createEmail.trim()) {
      setAlertMessage("Please enter your email.");
      setAlertType("error");
      return;
    }

    if (!isEmailValid(createEmail)) {
      setAlertMessage("Please enter a valid email address.");
      setAlertType("error");
      return;
    }

    if (!createPassword.trim()) {
      setAlertMessage("Please enter your password.");
      setAlertType("error");
      return;
    }

    if (!isPasswordStrong(createPassword)) {
      setAlertMessage("Password must be at least 8 characters long and include uppercase letters, lowercase letters, numbers, and special characters.");
      setAlertType("error");
      return;
    }

    // Prevent creating account with @gmail.com and instruct to use quick login
    if (createEmail.toLowerCase().endsWith('@gmail.com')) {
      setAlertMessage("Please use the quick login option for Gmail accounts.");
      setAlertType("error");
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, createEmail, createPassword);
      const user = userCredential.user;
      console.log("user credential: ", user, createFirstName, createSurname)
      const dbRef = doc(db, 'users', user.uid);
      const userInfo = {
        firstName: createFirstName,
        surname: createSurname,
        userID: user.uid,
        email: user.email,
        displayName: `${createFirstName} ${createSurname}`,
      };
  
      // Create the user document in Firestore
      await setDoc(dbRef, userInfo);
      console.log("User document created in Firestore.");
  
      // Update the displayName if it's not set
      if (!auth.currentUser.displayName) {
        await updateProfile(auth.currentUser, { displayName: `${createFirstName} ${createSurname}` });
        console.log("The displayName has been updated.");
      } else {
        console.log("The user already has a name.");
      }
  
      // Dispatch the login action
      dispatch({ type: "LOGIN", payload: user });
  
      // Notify the user
      setAlertMessage("You have created an account and are now logged in.");
      setAlertType("success");
      console.log(user);
      console.log("The user has been added to the database.");
  
      // Clear create account inputs
      setCreateFirstName("");
      setCreateSurname("");
      setCreateEmail("");
      setCreatePassword("");
  
    } catch (error) {
      console.error("Error creating account:", error);
      if (error.code === 'auth/email-already-in-use') {
        setAlertMessage("This email is already in use. Please use a different email or log in.");
      } else {
        setAlertMessage("Error creating account. Please try again.");
      }
      setAlertType("error");
    }
  };
   
  const provider = new GoogleAuthProvider();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleGoogleSignIn = () => {
    console.log(navigator.userAgent);
    signInWithPopup(auth, provider)
      .catch((error) => {
        console.error("Error during signInWithRedirect:", error);
        setAlertMessage("Error signing in with Google.");
        setAlertType("error");
      })
  };
  

  const authenticationRef = useRef(null);
  const frameInfoRef = useRef();
  const { onClickOutside } = props;

  // Code below closes the framePopup when a user clicks outside the framePopup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (authenticationRef.current && !authenticationRef.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ onClickOutside ]);

 
  const handleLogout = (e) => {
    e.preventDefault(); // Prevent default button behavior
    signOut(auth).then(() => {
      setAlertMessage("You are now logged out.");
      setAlertType("success");
      setShowLogin(true);
      setShowAlreadyLoggedIn(false);
      dispatch({ type: "LOGOUT", payload: null }); // Update AuthContext

      // Clear all input fields
      setCreateFirstName("");
      setCreateSurname("");
      setCreateEmail("");
      setCreatePassword("");
      setLoginEmail("");
      setLoginPassword("");
      
      console.log("User has been logged out and AuthContext updated.");
    }).catch((error) => {
      console.error("Error details:", error);
      setAlertMessage("Error logging out.");
      setAlertType("error");
    });
  }

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!resetEmail.trim()) {
      setAlertMessage("Please enter your email address.");
      setAlertType("error");
      return;
    }

    if (!isEmailValid(resetEmail)) {
      setAlertMessage("Please enter a valid email address.");
      setAlertType("error");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setAlertMessage("Password reset email sent. Please check your inbox.");
      setAlertType("success");
      setShowPasswordReset(false);
      setResetEmail("");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      if (error.code === AuthErrorCodes.USER_DELETED) {
        setAlertMessage("No user found with this email.");
      } else {
        setAlertMessage("Error sending password reset email. Please try again.");
      }
      setAlertType("error");
    }
  };

  // Close the Password Reset Popup when clicking outside
  const passwordResetRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (passwordResetRef.current && !passwordResetRef.current.contains(event.target)) {
        setShowPasswordReset(false);
      }
    };
    if (showPasswordReset) {
      document.addEventListener('click', handleClickOutside, true);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showPasswordReset]);

  if(!props.show)
    return null;

  return (
    <div className="authentication" ref={authenticationRef}>
      {/* Alert Component */}
      <Alert component={'login'} message={alertMessage} type={alertType} onClose={() => setAlertMessage("")} />

      {showLogin &&
        <div className="bootstraptAuth" ref={loginRef}>
          {/* Create Account Form */}
          <form id="createAccountForm" onSubmit={handleCreateAccount} noValidate>
            <h1 className="createAccountHeader">create</h1>

            {/* First Name Input */}
            <input 
              id="firstNameInput" 
              type="text" 
              placeholder=" First Name" 
              value={createFirstName}
              onChange={e => setCreateFirstName(e.target.value)} 
              required 
            />

            {/* Surname Input */}
            <input 
              id="surnameInput" 
              type="text" 
              placeholder=" Surname" 
              value={createSurname}
              onChange={e => setCreateSurname(e.target.value)} 
              required 
            />

            <input 
              id="emailInput" 
              type="email" 
              placeholder=" Email" 
              value={createEmail}
              onChange={e => setCreateEmail(e.target.value)} 
              required 
            />
            <div className="bootstraptPasswordDiv">
              <input 
                id="passwordInput" 
                type={showPassword ? "text" : "password"} 
                placeholder=" Password" 
                value={createPassword}
                onChange={e => setCreatePassword(e.target.value)} 
                required 
              />
              <img 
                className="bootstraptAuthShowPasswordIcon" 
                src={eyeIcon} 
                alt="show password" 
                onClick={togglePassword} 
                aria-label="Toggle password visibility"
              />
            </div>
            <button id="createAccountSubmitBtn" type="submit">create account</button>
          </form>

          {/* Login Form */}
          <form id="loginForm" onSubmit={handleLogin} ref={loginRef} noValidate>
            <h1 className="loginHeader">login</h1>
            <input 
              id="emailInput2" 
              type="email" 
              placeholder=" Email" 
              value={loginEmail}
              onChange={e => setLoginEmail(e.target.value)} 
              required 
            />
            <div className="loginSectionPasswordDiv">
              <input 
                id="passwordInput2" 
                type={showPassword2 ? "text" : "password"} 
                placeholder=" Password" 
                value={loginPassword}
                onChange={e => setLoginPassword(e.target.value)} 
                required 
              />
              <img 
                className="loginSectionShowPasswordIcon" 
                src={eyeIcon} 
                alt="show password" 
                onClick={togglePassword2} 
                aria-label="Toggle password visibility"
              />
            </div>
            {/* Forgot Password Link */}
            <div className="forgotPasswordContainer">
              <button 
                type="button" 
                className="forgotPasswordBtn" 
                onClick={() => setShowPasswordReset(true)}
              >
                Forgot Password?
              </button>
            </div>
            <button id="loginSubmitBtn" type="submit">login</button>
          </form>
        </div>
      }

      {showLogin &&
        <div className="GoogleAuth" ref={googleLoginRef}>
          {/* <h1 className="googleAuthHeader">quick login</h1> */}
          {/* <p className="GoogleAuthDescription">
            Reduce the struggle and <strong>login</strong> with your Google account.
          </p> */}
          <button onClick={handleGoogleSignIn} className="GoogleLoginBtn">
            <img className="googleLogo" src={googleLogo} alt="Google Logo" />
            <div className="GoogleAuthLoginText">Login with Google</div>
          </button>
        </div>
      }

      {showAlreadyLoggedIn &&
      <div className="userLoggedIn" ref={alreadyLoggedInRef}>
        <p className="warningLoggedInText">You are already logged in.</p>
        <button onClick={handleLogout} className="logoutBtn">logout?</button>
      </div>
      }

      {/* Password Reset Popup */}
      {showPasswordReset && (
        <div className="passwordResetOverlay">
          <div className="passwordResetPopup" ref={passwordResetRef}>
            <h2 className="passwordResetHeader">reset password</h2>
            <form  className="passwordResetContent" onSubmit={handlePasswordReset} noValidate>
              <input 
                type="email" 
                id="forgottenPasswordEmailInput"
                placeholder="Enter your email" 
                value={resetEmail}
                onChange={e => setResetEmail(e.target.value)} 
                required 
              />
              <div className="passwordResetButtons">
                <button type="submit" className="passwordResetBtn" >Send Reset Link</button>
                <img type="button" className="passwordResetCancelBtn" src={deleteIcon} onClick={() => setShowPasswordReset(false)} />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default Login;
