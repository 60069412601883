import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { onAuthStateChanged } from "firebase/auth";
import {
    addDoc, collection, doc, updateDoc, setDoc,
    getDoc, getDocs, arrayUnion, arrayRemove, query,
    where, onSnapshot
}
    from '@firebase/firestore'
import { auth, db, storage } from '../firebase'
import CartProduct from "./CartProduct";
import dropdownIcon from '../icons/popupIcon.png'
import './Cart.css'
import './CartStoreContainer.css'
import DeliveryOptionPopup from "./DeliveryOptionPopup";

const CartContainer = ({ 
  setShippingPrice, updateGlobalTotalShippingPrice, storeName, storeID, 
  cart, address, expandedStores, setIsActive, isActive, expandCardFunction, 
  getTotalForGroup, setExpandedStores, handleTotalShippingPrice,
  setIsLoadingDeleted, setCart, onBlockSlider, setShowTotalContainer, 
  setAlertMessage, setAlertType, country
}) => {

  const [showShipping, setShowShipping] = useState(false)
  const [selectedShippingOptions, setSelectedShippingOptions] = useState({});
  const [totalShippingPrice, setTotalShippingPrice] = useState(0);
  const [deletedProductID, setDeletedProductID] = useState()
  const [userID, setUserID] = useState()
  const [bundlingRules, setBundlingRules] = useState({});
  const [bundlingOptions, setBundlingOptions] = useState([]);
  const [totalShippingPriceByMethod, setTotalShippingPriceByMethod] = useState({});
  const [originalShippingPrices, setOriginalShippingPrices] = useState({});
  const [groupedOptions, setGroupedOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true)
  const [updatedCart, setUpdatedCart] = useState()
  const [bundlingRulesLoading, setBundlingRulesLoading] = useState(true);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserID(user.uid)
    }
  })

  const closePopup = () => {
    setShowShipping(false)
  }

  useEffect(() => {
    const fetchStoreData = async () => {
      if (storeID) {
        setBundlingRulesLoading(true);
        const docRef = doc(db, "stores", storeID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBundlingRules(docSnap.data().bundlingRules || {});
        } else {
          console.log("No such document!");
          setBundlingRules({});
        }
        setBundlingRulesLoading(false);
      }
    };
    fetchStoreData();
  }, [storeID]);

  const [productsWithoutShipping, setProductsWithoutShipping] = useState([]);

  useEffect(() => {
    const productsWithoutShipping = cart[storeName].filter(item => !item.selected_shippingName).map(item => item.productID);
    setProductsWithoutShipping(productsWithoutShipping);
  }, [cart, storeName])

  const calculateTotalCount = () => {
    let totalCount = 0;
    Object.keys(cart).forEach(storeName => {
      totalCount += cart[storeName].filter(item => !item.selected_shippingName).length;
    });
    console.log(totalCount)
    return totalCount;
  };

  // useEffect(() => {
  //   const totalCount = calculateTotalCount();
  //   onBlockSlider(totalCount > 0, totalCount);
  // }, [cart]);
  

  useEffect(() => {
    if (productsWithoutShipping.length > 0) {
      console.log("Products need shipping:", productsWithoutShipping.join(', '));
      // Code to block the slider
    }
  }, [productsWithoutShipping]);

  useEffect(() => {
    let totalByMethod = {};

    for (let productId in selectedShippingOptions) {
      let shippingMethod = selectedShippingOptions[productId].option.split('--')[0];
      let price = parseFloat(selectedShippingOptions[productId].price);

      if (totalByMethod[shippingMethod]) {
        totalByMethod[shippingMethod] += price;
      } else {
        totalByMethod[shippingMethod] = price;
      }
    }

    setTotalShippingPriceByMethod(totalByMethod);
  }, [selectedShippingOptions]);

  useEffect(() => {
    let initialShippingOptions = {};
    let initialShippingPrices = {};  // Store the original prices here
    Object.values(cart).forEach(storeItems => {
      storeItems.forEach(item => {
        if (item.selected_shippingName && typeof item.selected_shippingName === 'string') {
          const parts = item.selected_shippingName.split('--');
          const method = parts[0];
          const text = parts.slice(1).join('--'); // This will handle the remaining parts as the text
          initialShippingOptions[item.productID] = {
            option: `${method}--${text}`,
            price: item.selected_shippingPrice
          };
          initialShippingPrices[item.productID] = item.selected_shippingPrice;  // Add the original price
        }
      });
    });

    console.log("Initial Shipping Options: ", initialShippingOptions)
    setSelectedShippingOptions(initialShippingOptions);
    setOriginalShippingPrices(initialShippingPrices);  // Set the state variable with the original prices
    setIsLoading(false)
  }, []);


  const deletedProduct = useCallback(async (productID, shippingPrice, newCart) => {
    console.log("Trying to delete")
    setIsLoadingDeleted(true)
    console.log(newCart)
    updateShippingCalculation(newCart)
    setDeletedProductID(productID)
    let totalShippingCost = totalShippingPrice; // fetch totalShippingPrice from state
    const priceNumber = parseFloat(shippingPrice)
    if (priceNumber ==! 0 || undefined || "") {
      console.log(priceNumber)
      console.log(`Current total: ${totalShippingCost}, subtracting price: ${priceNumber}`)
      totalShippingCost -= priceNumber
      totalShippingCost = parseFloat(totalShippingCost.toFixed(2)); // limiting to 2 decimal places
      console.log(`Final total shipping cost: ${totalShippingCost}`);
      handleTotalShippingPrice(totalShippingCost)
    } else {console.log("There is no shipping associated")}
    setCart(newCart)
    setIsLoadingDeleted(false)
  }, [setIsLoadingDeleted, setCart])

  const updateShippingCalculation = useCallback((newCart) => {
    if (!bundlingRulesLoading) {
    console.log("New cart: ", newCart)
    let processedBundles = new Set();
    let totalShipping = 0;
    let shippingCounts = new Map();

    Object.keys(newCart).forEach(storeName => {
      newCart[storeName].forEach(product => {
        let shippingMethod = product.selected_shippingName;
        let currentCount = shippingCounts.get(shippingMethod) || 0;
        shippingCounts.set(shippingMethod, currentCount + 1);
    
        // Check if 'bundledWith' is an array and has elements
        let isBundledWithDefined = Array.isArray(product.bundledWith) && product.bundledWith.length > 0;
    
        // Determine whether to process bundling
        let shouldProcessBundling = product.bundledWith !== "" && isBundledWithDefined && !processedBundles.has(product.bundledWith);
    
        if (shouldProcessBundling) {
          // For bundled products
          if (shippingCounts.get(shippingMethod) <= (bundlingRules[shippingMethod] || 0) && currentCount === 0) {
            totalShipping += parseFloat(product.selected_shippingPrice || 0);
            processedBundles.add(product.bundledWith);
          }
        } else {
          // For non-bundled products
          if (shippingMethod && bundlingRules.hasOwnProperty(shippingMethod)) {
            if (shippingCounts.get(shippingMethod) <= (bundlingRules[shippingMethod] || 0) && currentCount === 0) {
              totalShipping += parseFloat(product.selected_shippingPrice || 0);
            }
          }
        }
      });
    });
    

    console.log("Total shipping: ", totalShipping);
    handleTotalShippingPrice(totalShipping);
  } else {
    console.log("Waiting for bundling rules to load")
  }
  }, [handleTotalShippingPrice, bundlingRules, bundlingRulesLoading]);


  // Pass the new Cart and use it to calculate the new cart_total. 
  // Use inspiration from the calculateShipping function from DeliveryOptionPopup

  useEffect(() => {
    // Check if deletedProductID is set and there was a shipping option selected for this product
    if (updatedCart && deletedProductID && selectedShippingOptions[deletedProductID]) {
      console.log("Updated cart: ", updatedCart)
      // Get the shipping price for the deleted product
      const deletedProductShippingPrice = selectedShippingOptions[deletedProductID].price;   
      // account for bundling   
      setTotalShippingPrice(prevTotal => prevTotal - deletedProductShippingPrice);
 
      // Remove the deleted product from the selected shipping options
      setSelectedShippingOptions(prev => {
        const newOptions = { ...prev };
        delete newOptions[deletedProductID];
        return newOptions;
      });
    }
  }, [updatedCart, deletedProductID, selectedShippingOptions]);

  useEffect(() => {
    console.log('New Total Shipping Price:', totalShippingPrice);
  }, [totalShippingPrice]);
  

  useEffect(() => {
    const groupedByShippingMethod = Object.entries(selectedShippingOptions).reduce((groups, [productId, { option }]) => {
      let shippingMethod = option.split('--')[0];
      if (!groups[shippingMethod]) {
        groups[shippingMethod] = [];
      }
      groups[shippingMethod].push(productId);
      return groups;
    }, {});

    setGroupedOptions(groupedByShippingMethod);
  }, [selectedShippingOptions]);

  const updateCartData = useCallback((newCartData) => {
    // ensure that it is grouped
    console.log("Updated cart data: ", newCartData)
    setIsLoading(true)
    const list = newCartData

          const groups = list.reduce((groups, item) => {
            const store = (groups[item.store] || [])
            store.push(item)
            groups[item.store] = store
            return groups
          }, {})

          console.log("Groups: ", groups)
          for (const store in groups) {
            const total = groups[store].reduce((sum, item) => sum + item.listed_price, 0)
          }

          setCart(groups)     
    
    setIsLoading(false)
  })

  return (
    <div className="cartStoreContainer">
      <div className="cartStoreDiv">
        <div className="cartStoreDivHeader">
          <div className="cartStoreName">{storeName}</div>
          <div className="cartStoreTotal">R{getTotalForGroup(cart, storeName).toFixed(2)}</div>
        </div>
        <div className="cartStoreProducts">
        {!isLoading && cart[storeName].slice(0, expandedStores[storeName] ? cart[storeName].length : 2).map((item, index) => (
          <CartProduct
            key={index}
            deletedProduct={deletedProduct}
            productID={item.productID}
            productImage={item.images[0]}
            productPrice={item.listed_price}
            productTitle={item.title}
            store={item.store}
            productDescription={item.description}
            productCategory={item.category}
            expandCardFunction={expandCardFunction}
            selectedShippingName={item.selected_shippingName}
            selectedShippingPrice={item.selected_shippingPrice}
            data={item}
            bundling={item.bundling}
            cart={cart}
            setShowShipping={setShowShipping}
          />
        ))}
        </div>
      </div>
      <div className="cartStoreOptionDiv">
        <div className="cartStoreShippingOptions" 
        onClick={() => { 
          if (country !== 'South Africa') {
            setAlertMessage(`Purchasing is not allowed in ${country} yet.`);
            setAlertType('error')
            return
          }
          setShowTotalContainer(false)
          setShowShipping(true) 
        }}>choose shipping</div>
        {cart[storeName].length > 2 &&
          <div className="cartExpandFiltersBtn" onClick={() => {
            setExpandedStores({
              ...expandedStores,
              [storeName]: !expandedStores[storeName]
            })
          }}>
            <div>
            {expandedStores[storeName] ?
              <img className="cartDropdownIcon2" src={dropdownIcon} alt="dropdown icon" /> :
              <img className="cartDropdownIcon" src={dropdownIcon} alt="dropdown icon" />
            }</div>
          </div>
        }
        {showShipping &&
          <DeliveryOptionPopup
          cart={cart}
          userID={userID}
          storeName={storeName}
          storeID={storeID}
          updateGlobalTotalShippingPrice={updateGlobalTotalShippingPrice}
          updateCartData={updateCartData}
          closePopup={closePopup}
          setShowTotalContainer={setShowTotalContainer}
          address={address}
          setAlertMessage={setAlertMessage}
          setAlertType={setAlertType}
        />
        
        }
      </div>
    </div>
  )
}

export default CartContainer;
