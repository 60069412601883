import React, { useEffect, useRef, useState } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'
import dropdownIcon from '../../../icons/popupIcon.png' 

const ArtTags = (props) => {
  const [tags, setTags] = useState(["art"])
  const newTagRef = useRef()
  const styleTagsRef = useRef()
  const styleTagsTextRef = useRef()
  const [show, setShow] = useState(false);

  const generalTags = [
    'art',
    'canvas',
    'carving',
    'ceramic',
    'collage',
    'digital',
    'drawing',
    'easel',
    'lino cut',
    'mixed media',
    'paint',
    'paintbrushes',
    'painting',
    'palette knife',
    'paper',
    'photography',
    'print',
    'sculpture',
    'sketch',
    'tapestry',
  ]
  const styleTags = [
    'south african',
    'international',
    'abstract',
    'abstract expressionism',
    'absurdism',
    'art nouveau',
    'art deco',
    'avant-garde',
    'baroque',
    'bauhaus',
    'classicism',
    'cubism',
    'conceptualism',
    'constructivism',
    'figurative',
    'futurism',
    'installation',
    'geometric',
    'landscape',
    'line art',
    'minimalism',
    'maximalism',
    'nature',
    'nude',
    'optical art',
    'pop art',
    'portrait',
    'realism',
    'replica',
    'reproduction',
    'statue',
    'still life',
    'surrealism',
    'symbolism',
    'typography',
    'urban',
  ]
  let specificPainting = ['oil', 'acrylic', 'watercolour', 'gouche', 'glass']
  let specificPaint = [
    'oil',
    'acrylic',
    'watercolour',
    'gouche',
    'flat',
    'matte',
    'eggshell',
    'gloss',
    'semi-gloss',
    'high-gloss',
    'satin',
  ]
  let specificSketch = ['pencil', 'pen', 'charcoal', 'graphite', 'chalk', 'ink']
  let specificDrawing = [
    'pencil',
    'pen',
    'charcoal',
    'graphite',
    'chalk',
    'ink',
    'crayon',
    'marker',
    'oil pastel',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);
  
  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="styleTags">
          <h1 className="styleTagsHeader" >
            <div className="styleTagsHeaderText">style</div> 
            <div onClick={()=>setShow(!show)}>
              {show ? <img className="dropdownIcon2" src={dropdownIcon}  alt="dropdown icon" /> 
              : <img className="dropdownIcon" src={dropdownIcon}  alt="dropdown icon" />}
            </div>
          </h1>
          {
          show?<div className="styleTagsDiv">
            {styleTags.map((styleTag, index) => {
              if (!tags.includes(styleTag))
                return (
                  <div className="styleTagItem" ref={styleTagsRef} key={index} 
                  onClick={() => {
                    setTags([...tags, styleTag])
                    props.addTags([...tags, styleTag])
                  }}>
                  <span ref={styleTagsTextRef} className="styleTagText">{styleTag}</span>
                  </div>
                )
            })}
          </div>:null
          }
        </div>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>

          {tags.includes('paint') ? (
            specificPaint.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('painting') ? (
            specificPainting.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('sketch') ? (
            specificSketch.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('drawing') ? (
            specificDrawing.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default ArtTags