import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import plusIcon from '../../icons/plusIcon.png';
import deleteIcon from '../../icons/deleteIcon.png';
import loadIcon from '../../icons/scoutIcon.png'
import ImageTags from './ImageTags';
import { doc, updateDoc, arrayUnion, arrayRemove } from '@firebase/firestore';
import { storage, db } from '../../firebase';
import './AddProduct.css'

// Import Framer Motion components
import { motion, AnimatePresence } from 'framer-motion';

const UploadComponent = ({
  productID,
  setImageUrl,
  imageUrl,
  setThumbnailUrl,
  setImagesChanged,
  setUploadImages,
  uploadImages,
  setPercentage,
  percentage,
  setShowProgressBar,
  completedUploads,
  setCompletedUploads,
  setProgressCompleted,
  setFetchedImageUrl,
  setFetchedImageThumbnail,
  handleFieldChange,
  required,
  initialImages = [],
  isEditing = false,
  setTags
}) => {
  const [tags, setTagsState] = useState([]);
  const [error, setError] = useState(''); // State to handle error messages
  // New state to track tag generation
  const [isTagGenerating, setIsTagGenerating] = useState(false);

  useEffect(() => {
    if (percentage === 100) {
      setPercentage(null);
      setShowProgressBar(false);
      setProgressCompleted(true);
    } else if (percentage !== null) {
      setShowProgressBar(true);
    }
  }, [percentage, setPercentage, setShowProgressBar, setProgressCompleted]);

  useEffect(() => {
    if (isEditing && initialImages.length > 0 && uploadImages.length === 0) {
      setUploadImages(initialImages.map(img => ({
        ...img,
        completed: img.completed !== undefined ? img.completed : true, // Ensure completed flag
        fromFirebase: img.fromFirebase || false, // Ensure fromFirebase flag
      })));
    }
  }, [isEditing, initialImages, uploadImages.length, setUploadImages]);

  useEffect(() => {
    // Cleanup function to revoke object URLs when the component unmounts
    return () => {
      uploadImages.forEach((img) => {
        if (img.preview && !img.fromFirebase) {
          URL.revokeObjectURL(img.preview);
        }
      });
    };
  }, []);

  const handleImageUpload = async (e) => {
    setImagesChanged(true);
    const file = e.target.files[0];
    if (!file) return;

    // File size validation: 5 MB limit
    if (file.size > 5 * 1024 * 1024) { // 5 MB in bytes
      setError('File size exceeds 5 MB. Please select a smaller file.');
      e.target.value = ''; // Reset the input
      return;
    }

    const fileName = file.name;

    // Check if this file name already exists in uploadImages
    const isAlreadyUploaded = uploadImages.some(
      (upload) => upload.file && upload.file.name === fileName
    );

    if (isAlreadyUploaded) {
      setError('This image has already been uploaded.');
      return;
    }

    const name = file.name + uuidv4();
    const storageRef = ref(storage, `product images/${name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Add a new upload image object to the state with initial completed: false
    setUploadImages((prev) => [
      ...prev,
      { file, progress: 0, preview: URL.createObjectURL(file), completed: false, fromFirebase: false },
    ]);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadImages((prev) =>
          prev.map((img) =>
            img.file === file
              ? { ...img, progress, completed: progress === 100 }
              : img
          )
        );
        setPercentage(progress);
      },
      (error) => {
        console.error('Error uploading file: ', error);
        setError('Error uploading file. Please try again.');
      },
      async () => { // Make this callback async to use await
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const resizedThumbnail = resizeThumbnailImageUrl(downloadURL);

          // Add the download URL to the Firestore document
          const dbRef = doc(db, 'products', productID);
          await updateDoc(dbRef, {
            images: arrayUnion(downloadURL),
            imageThumbnails: arrayUnion(resizedThumbnail),
          });
          console.log('Image URL added to Firestore');

          // Update the local state
          if (!imageUrl) {
            setImageUrl(downloadURL);
            setThumbnailUrl(resizedThumbnail);
          }

          setUploadImages((prev) =>
            prev.map((img) =>
              img.file === file
                ? { ...img, preview: downloadURL, completed: true }
                : img
            )
          );

          setCompletedUploads((prev) => prev + 1);
          handleFieldChange();
          setError(''); // Clear any previous errors

          // Determine if this is the first image being uploaded
          const isFirstImage = uploadImages.length === 0;

          // **Call the Cloud Function to Generate Tags**
          if (isFirstImage) {
            try {
              setIsTagGenerating(true); // Start tag generation
              const response = await fetch('https://generateimagetags-a6vcfarowq-uc.a.run.app', { // Replace with your actual Cloud Function URL
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ imageUrl: downloadURL }),
              });

              if (!response.ok) {
                throw new Error(`Server responded with status ${response.status}`);
              }

              const data = await response.json();
              if (data.tags && Array.isArray(data.tags)) {
                console.log('Generated Tags:', data.tags);
                const tagStrings = data.tags.map(tagObj => tagObj.tag)
                setTagsState(tagStrings); // Update local state
                setTags(tagStrings); // Inform parent component if needed
              } else {
                console.error('Invalid response format:', data);
              }
            } catch (error) {
              console.error('Error generating tags:', error.message);
            } finally {
              setIsTagGenerating(false); // End tag generation
            }
          }

        } catch (error) {
          console.error('Error during post-upload processing:', error);
          setError('Error processing uploaded image. Please try again.');
        }
      }
    );
  };

  const removeExistingResolution = (url) => {
    return url.replace(/(_1000x1000|_150x150)/g, '');
  };

  const resizeThumbnailImageUrl = (url) => {
    const cleanedUrl = removeExistingResolution(url);
    return resizeImageUrl(cleanedUrl, '_150x150');
  };

  const resizeImageUrl = (url, resolution) => {
    const queryParamIndex = url.lastIndexOf('?');
    return queryParamIndex === -1
      ? url + resolution
      : url.substring(0, queryParamIndex) + resolution + url.substring(queryParamIndex);
  };

  const handleDelete = async (index = null) => {
    setImagesChanged(true);
    const imageToDelete = uploadImages[index];
    if (!imageToDelete || !imageToDelete.preview) return;

    // Remove the image from the local state
    const newUploadImages = uploadImages.filter((_, i) => i !== index);
    setUploadImages(newUploadImages);

    if (imageToDelete.completed) {
      setCompletedUploads((prev) => prev - 1);
    }

    // Remove the image from Firestore arrays
    const dbRef = doc(db, 'products', productID);
    const updatedAttributes = {
      images: arrayRemove(imageToDelete.preview),
      imageThumbnails: arrayRemove(resizeThumbnailImageUrl(imageToDelete.preview)),
    };

    try {
      await updateDoc(dbRef, updatedAttributes);
      console.log(`${imageToDelete.preview} removed from Firestore document ${productID}`);
    } catch (error) {
      console.error('Error updating Firestore document:', error);
      setError('Error removing image from database. Please try again.');
    }

    if (imageToDelete.fromFirebase) {
      try {
        let fileName = imageToDelete.preview.slice(89, imageToDelete.preview.lastIndexOf('?')).replace(/ /g, '%20');
        fileName = decodeURIComponent(fileName);

        const baseFileName = fileName.replace('_1000x1000', '');

        const deleteRefOriginal = ref(storage, `product images/${baseFileName}`);
        const deleteRefLarge = ref(storage, `product images/${baseFileName}_1000x1000`);
        const deleteRefThumbnail = ref(storage, `product images/${baseFileName}_150x150`);

        await Promise.all([
          deleteObject(deleteRefOriginal),
          deleteObject(deleteRefLarge),
          deleteObject(deleteRefThumbnail)
        ]);
        
        console.log('Images successfully deleted from Firebase');
        handleFieldChange();
      } catch (error) {
        console.error('Error deleting images from Firebase:', error);
        setError('Error deleting image from storage. Please try again.');
      }
    }

    // Update the main image if the first image is deleted
    if (index === 0) {
      setImageUrl(null);
      setThumbnailUrl(null);
      setFetchedImageUrl('');
      setFetchedImageThumbnail('');
      setTags([])
      setTagsState([])
    } else if (newUploadImages.length > 0) {
      setImageUrl(newUploadImages[0].preview);
      setThumbnailUrl(resizeThumbnailImageUrl(newUploadImages[0].preview));
      setFetchedImageUrl(newUploadImages[0].preview);
      setFetchedImageThumbnail(resizeThumbnailImageUrl(newUploadImages[0].preview));
    } else {
      setImageUrl(null);
      setThumbnailUrl(null);
      setFetchedImageUrl('');
      setFetchedImageThumbnail('');
    }


    setError(''); // Clear any errors after successful deletion
  };

  const maxImagesReached = uploadImages.length >= 3;

  return (
    // Convert uploadProductMediaDiv to motion.div with layout animation
    <motion.div
      className="uploadProductMediaDiv"
      layout
      initial={{ height: 'auto' }}
      animate={{ height: 'auto' }}
      transition={{ duration: 0.5 }}
    >
      {(!maxImagesReached && uploadImages.length < 3) && (
        <label htmlFor="inputField" className="addProductChooseImageBtn">
          Upload Product Images
          <img src={plusIcon} alt="Upload Image Icon" className="addProductPlusIcon" />
        </label>
      )}
      <div className="uploadProductMediaContainer">
        {uploadImages &&
          uploadImages.map((upload, index) => (
            <div key={index} className="imageUploadPreviewDiv">
              {/* Use motion.img to animate opacity when tags are being generated */}
              <motion.img
                src={upload.preview}
                className="imageUploadPreview"
                alt={`Uploading ${index + 1}`}
              />
              {index === 0 && isTagGenerating && (
                <motion.div
                  className="scanningOverlay"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [0.4, 0.7, 0.4] }}
                  transition={{ duration: 1, repeat: Infinity, repeatType: 'loop' }}
                />
              )}
              {upload.completed ? (
                <div id="imageUploadDeleteBtn" onClick={() => handleDelete(index)}>
                  <img src={deleteIcon} alt="Delete Uploaded" className="deleteIcon" id="deleteIcon" />
                </div>
              ) : (
                <progress className="progressBar" value={upload.progress} max="100"></progress>
              )}
            </div>
          ))}

        {(!maxImagesReached && uploadImages.length < 3) && (
          <label
            htmlFor="inputField"
            className="addProductImagePlusBtn"
            style={{ margin: uploadImages.length === 0 ? '0px' : '10px 0 0 15px' }}
          >
            <img className="addProductImagePlusBtnIcon" src={plusIcon} alt="Upload More" />
          </label>
        )}
      </div>

      <input
        style={{ display: 'none' }}
        type="file"
        id="inputField"
        onChange={handleImageUpload}
        required={required}
        accept="image/*"
      />

      {/* Display Error Message */}
      {error && <div className="addProductErrorMessage">{error}</div>}

      {/* Conditionally render ImageTags with AnimatePresence */}
      <AnimatePresence>
        {tags.length > 0 && (
          <motion.div
            key="imageTags"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ImageTags
              tags={tags}
              setTags={(newTags) => {
                setTagsState(newTags);
                setTags(newTags); // Inform parent component if needed
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {isTagGenerating && (
        <div className="imageUploadLoadingDiv">
          <img src={loadIcon} className="marketLoadingIcon" alt="loading icon" />
        </div>
      )}
    </motion.div>
  );
};

export default UploadComponent;
