import React, {useEffect, useRef, useState } from 'react'
import { 
  addDoc, collection, doc, updateDoc, setDoc, getDoc, 
  getDocs, query, where, onSnapshot, deleteDoc, limit, 
  orderBy }  
from '@firebase/firestore'
import { db } from '../firebase'
import { useNavigate, useLocation } from 'react-router-dom';

import deleteIcon from '../icons/deleteIcon.png'
import './MarketStorePopup.css'

const MarketStorePopup = (props) => {
  const [storeProducts, setStoreProducts] = useState([])
  const navigate = useNavigate()
  const storeID = props.storeID
  const location = useLocation();

  // detect the page - if favourites the top is decreased, if market, it is 325px

  useEffect(() => {
    const productDoc = query(collection(db, "products"), where("store_id", "==", storeID), orderBy("date_added", "desc"), limit(2));

    onSnapshot(productDoc, (querySnapshot) => {
      let productArr = []
      querySnapshot.forEach((doc) => {
        productArr.push(doc.data())
      });
      
      setStoreProducts(productArr)
    })
  }, [storeID])
  

  const storePopupInfoDivRef = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (storePopupInfoDivRef.current && !storePopupInfoDivRef.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ onClickOutside ]);

  if(!props.show)
    return null;

  return (
    <div className="storePopupInfoDiv" ref={storePopupInfoDivRef} >
      <div className="marketPopupStoreName">{props.store}</div>
      <img className="closeStorePopup" src={deleteIcon} alt="" onClick={props.closePopup}/>
      <div className="marketPopupStoreProductDiv">
      {storeProducts.length === 0 ? (
        <div className="emptyStoreProductsDiv">This store has no products yet.</div>
      ) : (
        storeProducts.map((object, index) => (
          <img className="marketPopupStoreProduct" src={object.images} alt="" />
        )))
      }
      </div>
      <div className="visitWebsiteBtn"
      onClick={() => {
        if (props.userLoggedOut) {
          props.showLoginPopup()
        } else {
        setTimeout(() => {
          navigate(`/${props.store}`, { state: { store: props.store, storeID: props.storeID } })
        }, 500)
        }
      }}
      >
        visit website
      </div>
    </div>
  )
}

export default MarketStorePopup
