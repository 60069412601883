import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const BooksTags = (props) => {
  const [tags, setTags] = useState(["books"])
  const newTagRef = useRef()

  const generalTags = [
    'books',
    'fiction',
    'non-fiction',
    'children',
    'colouring book',
    'comic book',
    'daily planner',
    'diary',
    'e-book',
    'e-reader',
    'graphic novel',
    'journal',
    'magazine',
    'manga',
    'notebook',
    'sport',
    'textbook',
    'vintage',
  ]
  let specificFiction = [
    'adventure',
    'africana',
    'afrikaans',
    'classic fiction',
    'crime',
    'drama',
    'fantasy',
    'historical fiction',
    'horror fiction',
    'mythology',
    'poetry',
    'psychological',
    'romance',
    'science fiction',
    'south african',
    'teen',
    'thriller',
    'Xhosa',
    'young adult',
    'Zulu',
    'general fiction',
  ]
  let specificNonFiction = [
    'africana',
    'afrikaans',
    'animals',
    'architecture & design',
    'art & photography',
    'autobiography',
    'biography',
    'business',
    'computers & internet',
    'cooking, food & wine',
    'crafts & hobbies',
    'encyclopaedia',
    'entertainment',
    'finance',
    'health, mind & body',
    'history',
    'politics',
    'home & garden',
    'law',
    'memoir',
    'music',
    'natural science',
    'pets & animal care',
    'philosophy',
    'psychology',
    'spirituality',
    'religion',
    'reference',
    'science & technology',
    'self improvement',
    'true crime',
  ]
  let specificSport = [
    'leisure',
    'cricket',
    'soccer',
    'football',
    'golf',
    'rugby',
    'tennis',
    'swimming',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>

          {tags.includes('fiction') ? (
            specificFiction.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('non-fiction') ? (
            specificNonFiction.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('sport') ? (
            specificSport.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default BooksTags