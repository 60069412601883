import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'
import dropdownIcon from '../../../icons/popupIcon.png' 

const FashionTags = (props) => {
  const [tags, setTags] = useState(["fashion"])
  const newTagRef = useRef()
  const styleTagsRef = useRef()
  const styleTagsTextRef = useRef()

  const[show, setShow]=useState(false);
  const[show2, setShow2]=useState(false);

  const materialTags = [
    'cotton',
    'linen',
    'silk',
    'woven',
    'satin',
    'polyester',
    'chiffon',
    'lace',
    'leather',
    'faux leather',
    'patent leather',
    'vegan leather',
    'wool',
    'velvet',
    'nylon',
    'spandex',
    'latex',
    'faux fur',
    'fur',
    'cashmere',
    'fleece',
    'denim',
  ]
  const styleTags = [
    'winter',
    'summer',
    'spring',
    'autumn',
    'preppy',
    'sporty',
    'athleisure',
    'bohemian',
    'grunge',
    'punk',
    'streetwear',
    'classic',
    'casual',
    'Y2K',
    'vintage',
    'techwear',
    'artsy',
    'gothic',
    'formal',
    'maternal',
    'kawaii',
    'hypebeast',
    'luxury',
    'high fashion',
    'designer',
    'geeky',
    'dark academia',
    'androgynous',
    'loungewear',
    'cosplay',
    'cultural',
    'beachwear',
    'crochet',
    'outerwear',
    'e-girl',
    'maximalism',
    'baddie',
    'cottagecore',
    'normcore',
    'angelcore',
    'monochromatic',
    'babycore',
    'baggy',
    'loose',
    'tight',
  ]

  const generalTags = [
    'fashion',
    'thrift',
    'pants',
    'top',
    'shoes',
    'dress',
    'jacket',
    'coat',
    'glasses',
    'jumpsuit',
    'dungarees',
    'overall',
    'jersey',
    'hoodie',
    'blazer',
    'tuxedo',
    'suit',
    'scarf',
    'gloves',
    'kimono',
    'hat',
    'swimsuit',
    'hijab',
    'socks',
    'underwear',
    'sleepwear',
    'lingerie',
  ]
  let specificPants = [
    'shorts',
    'slacks',
    'jeans',
    'baggy',
    'chinos',
    'trousers',
    'boot-cut',
    'skinny',
    'straignt',
    'flare',
    'wide leg',
    'pegged',
    'cargo',
    'harem',
    'hammer pants',
    'sweatpants',
    'hiking pants',
    'hot pants',
    'skort',
    'sailor pants',
    'culottes',
    'jeggings',
    'leggings',
    'oxfords',
  ]
  let specificGlasses = [
    'sunglasses',
    'aviator',
    'goggles',
    'cat eye',
    'butterfly',
    'pilot',
    'shield',
    'wrap',
    'square',
    'round',
    'wayfarer',
    'pantos',
    'mirrored',
    'polarized',
    'biker',
    'sport',
    'rimless',
    'semi-rimless',
    'oversized',
    'rectangle',
  ]
  let specificTop = [
    'T-shirt',
    'shirt',
    'off shoulder top',
    'assymetrical',
    'blouse',
    'camisole',
    'bustier',
    'crop top',
    'corset',
    'collared',
    'kaftan',
    'lace up',
    'maxi top',
    'peasant top',
    'poncho',
    'sweatshirt',
    'spaghetti strap',
    'smock',
    'tank top',
    'tube top',
    'tunic',
  ]
  let specificShoes = [
    'formal',
    'high heel',
    'sandal',
    'flip flops',
    'slip-ons',
    'takkies',
    'business shoes',
    'sneakers',
    'running shoes',
    'work boots',
    'boots',
    'derby shoes',
    'chelsea boots',
    'uggs',
    'gumboots',
    'boat shoes',
    'espadrilles',
    'monk',
    'brogue',
    'knee high boots',
    'wedges',
    'combat boots',
    'gladiator sandals',
    'platfrom heels',
    'cone heels',
    'stilettos',
    'cowboy boots',
    'lita',
    'laced boots',
    'kitten heels',
    'scarpin heels',
    'ballet flats',
    'pumps',
    'vellies',
    'hiking boots',
    'togs',
    'cleats',
    'basketball shoes',
    'ice skates',
    'roller blades',
    'high tops',
    'knee high boots',
    'army boots',
    'chukka',
  ]
  let specificDress = [
    'skirt',
    'assymetrical',
    'shift',
    'sheath',
    'bodycon',
    'tent',
    'trapless',
    'halter dress',
    'apron',
    'sun dress',
    'jump dress',
    'wrap dress',
    'slip dress',
    'Qi Pao',
    'shirt dress',
    'maxi',
    'ball gown',
    'little black dress',
    'peplum',
    'party',
    'summer dress',
    'skater dress',
    'wedding gown',
    'floral',
    'halter',
    'A-line',
    'column',
    'trumpet',
    'tea dress',
    'flapper',
  ]
  let specificJacket = [
    'parka',
    'puffer',
    'leather jacket',
    'denim jacket',
    'windbreaker',
    'bomber',
    'cardigan',
    'peacoat',
    'anorak',
    'pullover',
    'shearling',
    'bolero',
    'gilet',
    'spencer jacket',
  ]
  let specificUnderwear = [
    'bra',
    'leggings',
    'tights',
    'panties',
    'garter',
    'boy short panties',
    'thong',
    'G-string',
    'C-string',
    'leggings',
    'trunks',
    'briefs',
    'boxers',
    'thermal pants',
  ]
  let specificCoat = [
    'trench coat',
    'raincoat',
    'fur coat',
    'maxi coat',
    'wrap coat',
    'swing coat',
    'tail coat',
    'cloak',
    'barbour',
    'duffel coat',
    'waist coat',
    'lab coat',
    'trucker',
  ]
  let specificHat = [
    'cap',
    'baseball cap',
    'cowboy hat',
    'top hat',
    'bucket hat',
    'fedora',
    'beret',
    'panama',
    'brim',
    'beanie',
    'bowler',
    'floppy',
    'cloche',
    'strawhat',
    'fez',
    'trapper',
    'deerstalker',
    'sun hat',
    'flat cap',
    'boater',
    'sailor',
    'halo',
    'breton',
    'mushroom',
    'turban',
    'sombrero',
    'rice hat',
  ]
  let specificSwimsuit = [
    'bikini',
    'retro bikini',
    'microkini',
    'bandeau',
    'one piece',
    'monokini',
    'tankini',
    'skirted bottom',
    'long sleeved',
    'burkini',
    'halter',
    'flounce',
  ]
  let specificSocks = [
    'plain',
    'funky',
    'yoga socks',
    'checkered',
    'thermal socks',
    'over the knee',
    'knee',
    'sover the calf',
    'mid calf',
    'ankle socks',
    'secret socks',
  ]
  let specificSleepwear = [
    'pyjamas',
    'pyjama top',
    'pyjama bottom',
    'sleep shirt',
    'robe',
    'onesie',
    'nightie',
    'sweats',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }

  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="styleTags">
          <h1 className="styleTagsHeader" >
            <div className="styleTagsHeaderText">style</div> 
            <div onClick={()=>setShow(!show)}>{show ? <img className="dropdownIcon2" src={dropdownIcon}  alt="dropdown icon" /> : <img className="dropdownIcon" src={dropdownIcon}  alt="dropdown icon" />}</div>
          </h1>
          {
          show?<div className="styleTagsDiv">
            {styleTags.map((styleTag, index) => {
              if (!tags.includes(styleTag))
                return (
                  <div className="styleTagItem" ref={styleTagsRef} key={index} 
                  onClick={() => {
                    setTags([...tags, styleTag])
                    props.addTags([...tags, styleTag])
                  }}>
                  <span ref={styleTagsTextRef} className="styleTagText">{styleTag}</span>
                  </div>
                )
            })}
          </div>:null
          }
        </div>
        <div className="materialTags">
          <h1 className="materialTagsHeader">
            <div className="materialTagsHeaderText">material</div>
            <div onClick={()=>setShow2(!show2)}>{show2 ? <img className="dropdownIcon2" src={dropdownIcon}  alt="dropdown icon" /> : <img className="dropdownIcon" src={dropdownIcon}  alt="dropdown icon" />}</div>
          </h1>
          {
          show2?<div className="materialTagsDiv">
            {materialTags.map((materialTag, index) => {
              if (!tags.includes(materialTag))
                return (
                  <div className="materialTagItem" key={index} 
                  onClick={() => {
                    setTags([...tags, materialTag])
                    props.addTags([...tags, materialTag])
                  }}>
                  <span className="suggestedTagText">{materialTag}</span>
                  </div>
                )
            })}
          </div>:null
          }
        </div>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>
          {tags.includes('pants') ? (
            specificPants.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('top') ? (
            specificTop.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('shoes') ? (
            specificShoes.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('dress') ? (
            specificDress.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('jacket') ? (
            specificJacket.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('underwear') ? (
            specificUnderwear.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('coat') ? (
            specificCoat.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('hat') ? (
            specificHat.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('swimsuit') ? (
            specificSwimsuit.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('socks') ? (
            specificSocks.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('sleepwear') ? (
            specificSleepwear.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default FashionTags