import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { 
  addDoc, collection, doc, updateDoc, setDoc, getDoc, getDocs, 
  query, where, onSnapshot, deleteDoc, arrayContains, serverTimestamp
} from '@firebase/firestore';
import { 
  ref, getDownloadURL, getStorage, listAll, 
  uploadBytesResumable, deleteObject 
} from 'firebase/storage';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db, storage } from '../../firebase';
import Select from "react-select";
import { motion, AnimatePresence, LayoutGroup, useAnimation } from "framer-motion";

import './Dashboard.css';

import backArrow from '../../icons/backArrow.png';
import backArrow2 from '../../icons/returnArrowIcon.png';
import eyeIcon from '../../icons/eyeIcon.png';
import plusIcon from '../../icons/plusIcon.png';
import deleteIcon2 from '../../icons/deleteIcon2.png';
import deleteIcon from '../../icons/deleteIcon.png';
import editIcon from '../../icons/editIcon.png';
import LongPress from '../../components/LongPress';
import BundlingDiv from './BundlingDiv';
import blankImage from '../../images/blankImage.png';

import LogoUploadPopup from './LogoUploadPopup';
import Login from '../../login/Login';
import '../../market/MarketProductCard.css'
import ScoutCard from '../../scout/ScoutCard';
import ImageGallery from '../../scout/ImageGallery'

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userID, setUserID] = useState(null);
  const [storeID, setStoreID] = useState(null);
  const [publishedProducts, setPublishedProducts] = useState([]);
  const [editingProducts, setEditingProducts] = useState([]);

  const [originalStoreName, setOriginalStoreName] = useState('');
  const [originalStoreDescription, setOriginalStoreDescription] = useState('');
  const [storeName, setStoreName] = useState('');
  const [storeDescription, setStoreDescription] = useState('');

  let [showInfo1, setShowInfo1] = useState(false);
  const [noProducts, setNoProducts] = useState(false);
  const [editingText, setEditingText] = useState(false);
  const [imageGrowthAnimation, setImageGrowthAnimation] = useState()
  const [colorArray, setColorArray] = useState([])

  const [showPublishedProducts, setShowPublishedProducts] = useState(true);
  const [showEditingProducts, setShowEditingProducts] = useState(false);
  const [showLoginOverlay, setShowLoginOverlay] = useState(false);
  const [showTextDivMessage, setShowTextDivMessage] = useState(false);
  const [infoDivExpanded, setInfoDivExpanded] = useState(false)
  const [isLogoUploadPopupOpen, setIsLogoUploadPopupOpen] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null); // State for selected product
  const [isScoutCardVisible, setIsScoutCardVisible] = useState(false); // State for ScoutCard visibility
  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);

  const [showEthicalChecksPopup, setShowEthicalChecksPopup] = useState(false);
  const [ethicalChecks, setEthicalChecks] = useState();
  const [ethicalChecksText, setEthicalChecksText] = useState('');

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryThumbnails, setGalleryThumbnails] = useState([]);
  // Remove these handlers
  const openGallery = (images, thumbnails) => {
    setGalleryImages(images);
    setGalleryThumbnails(thumbnails);
    setIsGalleryOpen(true);
  };
  
  const closeGallery = () => setIsGalleryOpen(false);
  const [storeNameExists, setStoreNameExists] = useState(false);
  const [showStoreNameExistsPopup, setShowStoreNameExistsPopup] = useState(false)
  const [sellerDeliveryPreference, setSellerDeliveryPreference] = useState('');

  useEffect(() => {
    document.title = 'Dashboard | taizte™';
  }, []);
  
  // Fetch existing delivery preferences on component mount
  useEffect(() => {
    if (storeID) {
      getDoc(doc(db, "stores", storeID)).then(docSnap => {
        if (docSnap.exists()) {
          setSellerDeliveryPreference(docSnap.data().sellerDeliveryPreference || 'both'); // default to 'both' if not set
        }
      });
    }
  }, [storeID]);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= wideScreenBreakpoint);
    };

    window.addEventListener('resize', handleResize);
    handleResize()
    
    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (userID && storeID) {
      unsubscribe = retrieveUserProducts();
    }
  
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userID, storeID]);
  
  

  const openEthicalChecksPopup = useCallback((eCheck, eCheckText) => {
    console.log(eCheck, eCheckText);
    setEthicalChecks(eCheck);
    setEthicalChecksText(eCheckText);
    setShowEthicalChecksPopup(true);
  });

  // Modify retrieveUserProducts to use userID from state
  const retrieveUserProducts = () => {
    if (!storeID) {
      console.warn("storeID is not set. Cannot retrieve products.");
      return;
    }
  
    const productQuery = query(collection(db, "products"), where("store_id", "==", storeID));
  
    const unsubscribe = onSnapshot(productQuery, (querySnapshot) => {
      let publishedArr = [];
      let editingArr = [];
  
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.status === 'published') {
          console.log("Published Product:", data);
          publishedArr.push(data);
        } else if (data.status === 'editing') {
          editingArr.push(data);
        }
      });
  
      setPublishedProducts(publishedArr);
      setEditingProducts(editingArr);
      setNoProducts(publishedArr.length === 0 && editingArr.length === 0);
    }, (error) => {
      console.error("Error fetching products:", error);
    });
  
    return unsubscribe;
  };
  


  useEffect(() => {
    if (showTextDivMessage) {
      const timer = setTimeout(() => {
        setShowTextDivMessage(false);
      }, 3000); // 500ms for fade-in + 1000ms delay before fade-out

      return () => clearTimeout(timer);
    }
  }, [showTextDivMessage, setShowTextDivMessage])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log(user);
        setUserID(user.uid);
        setShowLoginOverlay(false);
  
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const storeDoc = await getDoc(doc(db, "stores", userData.store_id))
            if (storeDoc.exists()) {
              const storeData = storeDoc.data();
              console.log("Store data:", storeData);
              console.log("User data:", userData);
              setData(storeData);
              setStoreName(storeData.store);
              setOriginalStoreName(storeData.store);
              setStoreDescription(storeData.store_description);
              setOriginalStoreDescription(storeData.store_description);
              setStoreID(storeData.store_id);
            }
          } else {
            console.log("No such user document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setShowLoginOverlay(true);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);
  
  
  const onLongPress = () => {
    console.log('longpress is triggered');
    setShowInfo1(true);
  };

  const onClick = () => {
    console.log('click is triggered');
  };

  const editWebsiteText = () => {
    setEditingText(true);
  };

  useEffect(() => {
    const checkIfStoreNameExists = async () => {
      if (!storeName) {
        console.log("Store name is empty.");
        setStoreNameExists(false);
        setShowStoreNameExistsPopup(false);
        return;
      }
  
      // Normalize both store names
      const normalizedStoreName = storeName.trim().toLowerCase();
      const normalizedOriginalStoreName = originalStoreName.trim().toLowerCase();
  
      console.log(`Normalized Store Name: "${normalizedStoreName}"`);
      console.log(`Normalized Original Store Name: "${normalizedOriginalStoreName}"`);
  
      if (normalizedStoreName === normalizedOriginalStoreName) {
        console.log("Store names are identical. No popup will be shown.");
        setStoreNameExists(false);
        setShowStoreNameExistsPopup(false);
        return;
      }
  
      const storeQuery = query(
        collection(db, "stores"),
        where("store", "==", storeName.trim()) // Trim storeName before querying
      );
  
      try {
        const snapshot = await getDocs(storeQuery);
        console.log(`Number of stores found with name "${storeName.trim()}": ${snapshot.size}`);
  
        if (snapshot.size > 0) {
          // Check if any of the stores with the same name have a different store_id
          const exists = snapshot.docs.some(doc => {
            const docStoreID = doc.data().store_id;
            console.log(`Comparing docStoreID: ${docStoreID} with storeID: ${storeID}`);
            return docStoreID !== storeID;
          });
          console.log(`Store name exists (excluding current store): ${exists}`);
          setStoreNameExists(exists);
          setShowStoreNameExistsPopup(exists);
        } else {
          console.log("Store name does not exist.");
          setStoreNameExists(false);
          setShowStoreNameExistsPopup(false);
        }
      } catch (error) {
        console.error("Error checking store name existence:", error);
        setStoreNameExists(false);
        setShowStoreNameExistsPopup(false);
      }
    };
  
    checkIfStoreNameExists();
  }, [storeName, originalStoreName, storeID]);
  
  
  const saveTextEdits = async () => {
    // Trim the storeName to remove leading/trailing whitespace
    const trimmedStoreName = storeName.trim();
  
    // Check if the store name is blank
    if (!trimmedStoreName) {
      // Display an error alert
      props.setAlertMessage("Store name cannot be blank.");
      props.setAlertType("error");
      return;
    }
  
    // Check if the store name already exists
    if (storeNameExists) {
      // Display an error alert
      props.setAlertMessage("Store name already exists. Please choose another name.");
      props.setAlertType("error");
      return;
    }
  
    // Proceed with saving the changes
    setEditingText(false);
  
    const dbRef = doc(db, "users", userID);
    const storeRef = doc(db, "stores", storeID);
    
    setOriginalStoreName(trimmedStoreName);
    setOriginalStoreDescription(storeDescription.trim());
  
    try {
      await updateDoc(dbRef, {
        store: trimmedStoreName,
        store_description: storeDescription.trim(),
      });
  
      await updateDoc(storeRef, {
        store: trimmedStoreName,
        store_description: storeDescription.trim(),
      });
  
      // Update products
      const productDoc = query(collection(db, "products"), where("userID", "==", userID));
      onSnapshot(productDoc, (querySnapshot) => {
        querySnapshot.forEach((object) => {
          const productID = object.data().productID;
          updateDoc(doc(db, "products", productID), {
            store: trimmedStoreName,
            store_description: storeDescription.trim(),
          });
        });    
      });
  
      // Update favourites
      const favQuerySnapshot = await getDocs(query(collection(db, "favourites")));
      favQuerySnapshot.forEach(async (object) => {
        const likedStores = object.data().liked_stores;
        const likedProducts = object.data().liked_products;
  
        if (!likedStores || !likedProducts) return;
  
        const updatedLikedStores = likedStores.map((likedStore) => {
          if (likedStore.store_id === storeID) {
            return {
              ...likedStore,
              store: trimmedStoreName,
              store_description: storeDescription.trim(),
            };
          } else {
            return likedStore;
          }
        });
  
        const updatedLikedProducts = likedProducts.map((likedProduct) => {
          if (likedProduct.store_id === storeID) {
            return {
              ...likedProduct,
              store: trimmedStoreName,
              store_description: storeDescription.trim(),
            };
          } else {
            return likedProduct;
          }
        });
    
        await updateDoc(doc(db, "favourites", object.id), {
          liked_products: updatedLikedProducts,
          liked_stores: updatedLikedStores,
        });
  
        console.log("All favourites documents edited");
      });
  
      // Display a success alert
      props.setAlertMessage("Store Information has been changed.");
      props.setAlertType("success");
    } catch (error) {
      console.error("Error saving text edits:", error);
      // Display an error alert
      props.setAlertMessage("An error occurred while saving changes.");
      props.setAlertType("error");
    }
  };

  const cancelTextEdit = async () => {
    setEditingText(false);
    setStoreName(originalStoreName);
    setStoreDescription(originalStoreDescription);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = LongPress(onLongPress, onClick, defaultOptions);

  const deleteProduct = async (userProduct, productName, storeName) => {
    const productID = userProduct.productID;
    console.log("product ID " + productID);
  
    const storeRef = doc(db, "stores", storeID);
    
    try {
      const storeSnap = await getDoc(storeRef);
      if (storeSnap.exists()) {
        let product_prices = storeSnap.data().product_prices || [];
  
        // Filter out the price of the product being deleted
        product_prices = product_prices.filter(price => price !== userProduct.seller_price);
  
        // Recalculate average, highest, and lowest prices
        const total = product_prices.reduce((acc, price) => acc + Number(price), 0);
        const average_price = product_prices.length > 0 ? total / product_prices.length : 0;
        const lowest_price = product_prices.length > 0 ? Math.min(...product_prices.map(Number)) : 0;
        const highest_price = product_prices.length > 0 ? Math.max(...product_prices.map(Number)) : 0;
  
        // Update the store document with the new prices
        await updateDoc(storeRef, {
          product_prices: product_prices,
          average_price: average_price,
          lowest_price: lowest_price,
          highest_price: highest_price,
        });
  
        console.log("Store prices updated!");
      }
  
      // Handling Favourites
      const favouritesSnapshot = await getDocs(collection(db, "favourites"));
      favouritesSnapshot.forEach(async (favDoc) => {
        const favData = favDoc.data();
        const likedProducts = Array.isArray(favData.liked_products) ? favData.liked_products : [];
        const userID = favData.user;
  
        if (likedProducts.some(product => product.productID === productID)) {
          const updatedLikedProducts = likedProducts.filter(product => product.productID !== productID);
          await updateDoc(favDoc.ref, { liked_products: updatedLikedProducts });
  
          // Create a notification
          const notificationRef = doc(collection(db, 'notifications'));
          await setDoc(notificationRef, {
            notificationType: 'productNotification',
            message: `The product titled '${productName}' has been deleted by the store ${storeName}`,
            messageDate: new Date().toLocaleString(),
            messageDateNumeric: serverTimestamp(),
            messageID: notificationRef.id,
            readStatus: 'unread',
            storeName: storeName,
            productName: productName,
            store_id: storeID,
            userID: userID
          });
  
          console.log("Notification sent for user that has the product liked:", userID);
        }
      });
  
      // Delete the product document
      await deleteDoc(doc(db, "products", userProduct.productID));
      console.log("Product deleted!");
  
      // Delete the product image if it exists
      if (userProduct.image) {
        const imageURL = userProduct.image;
        let fileName = imageURL.slice(89, imageURL.lastIndexOf('?')).replace(/ /g, "%20");
        fileName = decodeURIComponent(fileName);
  
        const deleteRef = ref(storage, `product images/${fileName}`);
        await deleteObject(deleteRef);
        console.log("Image successfully deleted!");
      } else {
        console.log("There is no image to delete / No image has been deleted");
      }
  
      // Handling Cart
      const cartSnapshot = await getDocs(collection(db, "cart"));
      cartSnapshot.forEach(async (cartDoc) => {
        const cartData = cartDoc.data();
        const cartItems = Array.isArray(cartData.cart) ? cartData.cart : [];
        const userID = cartData.user;
  
        if (cartItems.some(item => item.productID === productID)) {
          const updatedCartItems = cartItems.filter(item => item.productID !== productID);
          await updateDoc(cartDoc.ref, { cart: updatedCartItems });
  
          // Create a notification
          const notificationRef = doc(collection(db, 'notifications'));
          await setDoc(notificationRef, {
            notificationType: 'cartNotification',
            message: `The product titled '${productName}' has been deleted by the store ${storeName} and has been removed from your cart`,
            messageDate: new Date().toLocaleString(),
            messageDateNumeric: serverTimestamp(),
            messageID: notificationRef.id,
            readStatus: 'unread',
            storeName: storeName,
            productName: productName,
            store_id: storeID,
            userID: userID
          });
  
          console.log("Notification sent for user that has the product in cart:", userID);
        }
      });
  
    } catch (error) {
      console.error("Error deleting product:", error);
      props.setAlertMessage("An error occurred while deleting the product.");
      props.setAlertType("error");
    }
  };
  

  const adjustTextAreaHeight = (event) => {
    event.target.style.height = 'auto';
    event.target.style.height = `${event.target.scrollHeight}px`;
  };
  
  useEffect(() => {
    let timer;

    const resetTimer = () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        handleLogout();
      }, 600000); // 10 minutes in milliseconds
    };

    const handleActivity = () => {
      resetTimer();
    };

    // List of events to track
    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    // Attach event listeners
    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    // Initialize the timer
    resetTimer();

    // Cleanup function to remove event listeners and clear timer
    return () => {
      if (timer) clearTimeout(timer);
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User has been logged out due to inactivity.");
        setShowLoginOverlay(true);
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  // Handler to open ScoutCard
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsScoutCardVisible(true);
    setImageGrowthAnimation(true);
  };

  // Handler to close ScoutCard
  const closeScoutCard = () => {
    setSelectedProduct(null);
    setIsScoutCardVisible(false);
  };

  const handleDominantColor = (dominantColor, index) => {
    setColorArray((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = dominantColor;
      return newColors;
    });
  };

  const handleEditProduct = (product) => {
    navigate('../addproduct', { 
      state: { 
        isEditing: true, 
        productID: product.productID, 
        storeID: product.store_id 
      } 
    });
  };

  // Handler for updating delivery preference
  const updateDeliveryPreference = async (preference) => {
    if (!storeID) return;

    try {
      await updateDoc(doc(db, "stores", storeID), {
        sellerDeliveryPreference: preference,
      });
      setSellerDeliveryPreference(preference);
      props.setAlertMessage("Delivery preferences updated successfully!");
      props.setAlertType("success");
    } catch (error) {
      console.error("Error updating delivery preferences:", error);
      props.setAlertMessage("Failed to update delivery preferences.");
      props.setAlertType("error");
    }
  };

  // Render Delivery Preference Buttons
  const renderDeliveryPreference = () => {
    return (
      <div className="deliveryPreferenceContainer">
        <p className="deliveryPreferenceTitle">delivery preference</p>
        <div className="deliveryButtons">
          <div
            type="button"
            className={`deliveryButton ${sellerDeliveryPreference === 'driverPickup' ? 'selectedPreference' : ''}`}
            onClick={() => updateDeliveryPreference('driverPickup')}
          >
            driver pickup
          </div>
          <div
            type="button"
            className={`deliveryButton ${sellerDeliveryPreference === 'localCollect' ? 'selectedPreference' : ''}`}
            onClick={() => updateDeliveryPreference('localCollect')}
          >
            local collection
          </div>
          <div
            type="button"
            className={`deliveryButton ${sellerDeliveryPreference === 'both' ? 'selectedPreference' : ''}`}
            onClick={() => updateDeliveryPreference('both')}
          >
            both
          </div>
        </div>
      </div>
    );
  };

  const openLogoUploadPopup = () => {
    setIsLogoUploadPopupOpen(true);
  };
  
  const closeLogoUploadPopup = () => {
    setIsLogoUploadPopupOpen(false);
  };
  
  const handleLogoUpdate = (newLogoURL) => {
    setData((prevData) => ({
      ...prevData,
      store_logo: newLogoURL,
    }));
  };  
  

  return (
    <div className="dashboardBody">
      <style>{'body { background-color: #C8B7D2; }'}</style>
      <div className="dashboardHeader">
        <div className="dashboardHeaderContainer1">
          <Link to="/vendor">
            <div className="dashboardBack">
              <img src={backArrow} className="dashboardBackIcon" alt="Back" />
            </div>
          </Link>
          <div className='dashboardHeadingDiv'>
            <h1 className='dashboardHeadingText'>dashboard</h1>
          </div>
        </div>
        {data.store_logo ? (
          <img
            src={data.store_logo}
            alt="Store Logo"
            className='dashboardLogo'
            onClick={openLogoUploadPopup} // Make it clickable
            style={{ cursor: 'pointer' }} // Indicate it's clickable
            title="Click to change logo"
          />
        ) : (
          <div
            className='dashboardLogoPlaceholder'
            onClick={openLogoUploadPopup}
            style={{ cursor: 'pointer', /* Add more styling as needed */ }}
            title="Click to upload logo"
          >
            <img src={plusIcon} alt="Upload Logo" />
            <span>Upload Logo</span>
          </div>
        )}
        </div>

        <AnimatePresence>
          {isLogoUploadPopupOpen && (
            <LogoUploadPopup
              userID={userID}
              storeID={storeID}
              currentLogo={data.store_logo}
              onClose={closeLogoUploadPopup}
              onLogoUpdate={handleLogoUpdate}
              setAlertMessage={props.setAlertMessage}
              setAlertType={props.setAlertType}
            />
          )}
        </AnimatePresence>

      <AnimatePresence>
        {showTextDivMessage &&
          <motion.div
            className="marketTextDivMessage"
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -30, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {infoDivExpanded && 'Longpress the description box to collapse it.'}
            {!infoDivExpanded && 'Longpress the description box to expand it.'}

          </motion.div>
        }
      </AnimatePresence>
      <div className="dashboardContent">
        <div className="storeInfo">
          <div className="storeInfoTextSection">
            {editingText ?
              <textarea 
                className="dashboardEditStoreName" 
                value={storeName} 
                onChange={(e) => setStoreName(e.target.value)}
              /> :
              <p className="dashboardStoreName">{storeName}</p>
            }
            {editingText ?
              <textarea
                rows="3"
                className="dashboardEditStoreDescription"
                value={storeDescription}
                onChange={(e) => setStoreDescription(e.target.value)}
                onInput={adjustTextAreaHeight}
              /> :
              <p className="dashboardStoreDescription">{storeDescription}</p>
            }
          </div>
          <AnimatePresence>
            {showStoreNameExistsPopup && editingText && (
              <motion.div
                className="dashboardStoreNameExistsDiv"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
              >
                Business name already exists, please pick another name.
              </motion.div>
            )}
          </AnimatePresence>
          {!editingText ?
            <img 
              className="storeEditInfo" 
              src={editIcon} 
              onClick={editWebsiteText} 
              alt="Edit Info"
            /> :
            <div className="editTextBtnDiv">
              <div 
                className="submitTextBtn" 
                onClick={saveTextEdits}
                disabled={storeNameExists}
              >
                submit
              </div>
              <div className="cancelTextBtn" onClick={cancelTextEdit}>cancel</div>
            </div>
          }
        </div>
        {props.country && props.country !== 'South Africa' && (
          <div className="countryUploadNote">
            Note that product <strong>purchasing is not available in {props.country} yet, </strong> 
            but feel free to test it.
          </div>
        )}

        {data.offeringType !== 'services' && (
          <>
            <div className="storeUploadedProducts">
              <Link to="/addproduct">
                <img className="uploadNewProduct" src={plusIcon} alt="Upload New Product" />
              </Link>
              <div className="storeUploadedProductsHeader">products</div>
              <div className="productOptionsButtonsDiv">
                <div
                  className={`productsPublishedBtn${showPublishedProducts ? 'Active' : ''}`}
                  onClick={() => {
                    setShowPublishedProducts(true);
                    setShowEditingProducts(false);
                  }}
                >
                  published
                </div>
                <div
                  className={`productsEditingBtn${showEditingProducts ? 'Active' : ''}`}
                  onClick={() => {
                    setShowEditingProducts(true);
                    setShowPublishedProducts(false);
                  }}
                >
                  editing
                </div>
              </div>

              {/* Conditional Rendering for Published Products */}
              {showPublishedProducts && publishedProducts.length === 0 && (
                <div className="dashboardNoProducts">No products uploaded yet</div>
              )}
              {showPublishedProducts && publishedProducts.length > 0 && (
                publishedProducts.map((publishedProduct) => (
                  <div className="storesProducts" {...longPressEvent} key={publishedProduct.productID}>
                    <div className="storesProductsSection1">
                      <img
                        src={publishedProduct.imageThumbnails?.[0] || blankImage}
                        className="storesProductImg"
                        onClick={() => handleProductClick(publishedProduct)}
                        alt={publishedProduct.title}
                      />
                      <div className="storesProductInfo">
                        <div className="storesProductName">{publishedProduct.title}</div>
                        <div className="storesProductPrice">R{publishedProduct.listed_price}</div>
                      </div>
                    </div>
                    <div className="storesProductsSection2">
                      <div className="storesProductQuantity">{publishedProduct.quantity}</div>
                      <img
                        className="editProductIcon"
                        src={editIcon}
                        onClick={() => handleEditProduct(publishedProduct)}
                        alt="edit product"
                      />
                      <img
                        src={deleteIcon2}
                        className="deleteProductIcon"
                        onClick={() => deleteProduct(publishedProduct, publishedProduct.title, storeName)}
                        alt="delete product"
                      />
                    </div>
                  </div>
                ))
              )}

              {/* Conditional Rendering for Editing Products */}
              {showEditingProducts && editingProducts.length === 0 && (
                <div className="dashboardNoProducts">No products are currently being edited</div>
              )}
              {showEditingProducts && editingProducts.length > 0 && (
                editingProducts.map((product) => (
                  <div className="storesProducts" {...longPressEvent} key={product.productID}>
                    <div className="editStoresProductsSection1">
                      <img
                        src={product.imageThumbnails?.[0] || blankImage}
                        className="storesProductImg"
                        onClick={() => handleProductClick(product)}
                        alt={product.title || 'Untitled'}
                      />
                      <div className="storesProductInfo">
                        <div className="storesProductName">{product.title || 'No name set'}</div>
                        <div className="storesProductPrice">R{product.listed_price || 0}</div>
                      </div>
                    </div>
                    <div className="storesProductsSection2">
                      <div className="storesProductQuantity">{product.quantity || 0}</div>
                      <img
                        className="editProductIcon"
                        src={editIcon}
                        onClick={() => handleEditProduct(product)}
                        alt="edit product"
                      />
                      <img
                        src={deleteIcon2}
                        className="deleteProductIcon"
                        onClick={() => deleteProduct(product, product.title || 'Untitled', storeName)}
                        alt="delete product"
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="deliveryPreferencesSection">
              {renderDeliveryPreference()}
            </div>
            {/* <BundlingDiv 
              storeID={storeID}
              userProducts={publishedProducts}
            /> */}
            <Outlet />
          </>
        )}
      </div>

      {/* ScoutCard Popup */}
      <AnimatePresence>
        {isGalleryOpen && (
          <ImageGallery 
            image={selectedProduct.images} 
            imageThumbnail={selectedProduct.imageThumbnails}
            closeGallery={closeGallery}
          />
        )}
      </AnimatePresence>
      {isScoutCardVisible && selectedProduct && (
        <div className="marketCardPopupDiv">
          <div className="marketCardPopupContent">
            <div className="marketBackArrow" onClick={closeScoutCard}>
              <div className="marketCardBlurredBackground">
                <img src={backArrow2} alt="Close" id="marketBackArrowIcon" />
              </div>
            </div>
            <ScoutCard 
              page={'dashboard'}
              showLogin={null}
              imageGrowthAnimation={imageGrowthAnimation}
              isLoggedIn={null}
              productID={selectedProduct.productID}
              data={selectedProduct}
              image={selectedProduct.images} 
              imageThumbnail={selectedProduct.imageThumbnails}
              onDominantColor={handleDominantColor}
              listed_price={selectedProduct.listed_price}
              title={selectedProduct.title}
              store={storeName}
              storeID={storeID}
              description={selectedProduct.description}
              category={selectedProduct.category}
              navBarState={null}
              userID={userID}
              bundling={selectedProduct.bundling}
              alreadyInCart={null}
              isWideScreen={isWideScreen}
              openEthicalChecksPopup={openEthicalChecksPopup}
              showTextDivMessage={showTextDivMessage}
              setInfoDivExpanded={setInfoDivExpanded}
              setShowTextDivMessage={setShowTextDivMessage}
              setTransitionStarted={null}
              openGallery={openGallery}
            />
          </div>
        </div>
      )}

      {/* Login Overlay */}
      {showLoginOverlay &&
        <motion.div
          className="dashboardLogin"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0, duration: 0.5 }}
        >
          <div className="dashboardLoginDiv">
            <p className="dashboardLoginTextDiv">
              Session expired due to inactivity. Please <strong>login</strong> again to continue.
            </p>
            <Login show={showLoginOverlay} />
          </div>
        </motion.div>
      }
    </div>
  );
}

export default Dashboard;
