import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const MusicInstrumentsTags = (props) => {
  const [tags, setTags] = useState(["music & instruments"])
  const newTagRef = useRef()

  const generalTags = [
    'music & instruments',
    'instrument',
    'music CD',
    'music DVD',
    'memorabilia',
    'sound',
    'light',
    'DJ equipment',
    'tape',
    'smoke machine',
    'effects machine',
  ]
  let specificInstrument = [
    'accordian',
    'concertina',
    'brass',
    'drum',
    'percussion',
    'electronic',
    'guitar',
    'guitar accessory',
    'amp',
    'bass guitar',
    'guitar effect',
    'piano',
    'organ',
    'string',
    'violin',
    'cello',
    'flute',
    'woodwind',
    'harmonica',
    'sheet music',
    'song book',
  ]
  let specificMusicCD = [
    'blues',
    'childrens music',
    'classical',
    'country',
    'dance',
    'electronic',
    'easy listening',
    'folk',
    'gospel',
    'heavy metal',
    'holiday',
    'jazz',
    'karaoke',
    'local south african',
    'new age',
    'pop',
    'rap',
    'R&B',
    'reggae',
    'religious',
    'rock',
    'soundtrack',
    'theatre',
    'world music',
  ]
  let specificMusicDVD = [
    'blues',
    'childrens music',
    'classical',
    'country',
    'dance',
    'electronic',
    'easy listening',
    'folk',
    'gospel',
    'heavy metal',
    'holiday',
    'jazz',
    'karaoke',
    'local south african',
    'new age',
    'pop',
    'rap',
    'R&B',
    'reggae',
    'religious',
    'rock',
    'soundtrack',
    'theatre',
    'world music',
  ]
  let specificDJEquipment = [
    'amplifier',
    'CDJ player',
    'controller',
    'DJ mixer',
    'headphones',
    'turntable',
  ]
  let specificLight = [
    'accessory',
    'DJ lighting',
    'fixed lighting',
    'intelligent lighting',
    'light controller',
  ]
  let specificSound = [
    'cabling',
    'headphone amplifier',
    'headphones',
    'in ear monitor',
    'instrumet amp',
    'loudspeaker amp',
    'speaker',
    'microphone',
    'mixing console',
    'PA speaker',
    'portable PA system',
    'power amplifier',
    'recording device',
    'signal processor',
    'stand',
    'wireless microphone',
  ]
  let specificTape = [
    'blues',
    'childrens music',
    'classical',
    'country',
    'dance',
    'electronic',
    'easy listening',
    'folk',
    'gospel',
    'heavy metal',
    'holiday',
    'jazz',
    'karaoke',
    'local south african',
    'new age',
    'pop',
    'rap',
    'R&B',
    'reggae',
    'religious',
    'rock',
    'soundtrack',
    'theatre',
    'world music',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>
          
          {tags.includes('instrument') ? (
            specificInstrument.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('music CD') ? (
            specificMusicCD.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('music DVD') ? (
            specificMusicDVD.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('DJ equipment') ? (
            specificDJEquipment.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('light') ? (
            specificLight.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('sound') ? (
            specificSound.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('tape') ? (
            specificTape.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default MusicInstrumentsTags