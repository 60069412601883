import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const GardenPoolPatioTags = (props) => {
  const [tags, setTags] = useState(["garden, pool & patio"])
  const newTagRef = useRef()

  const generalTags = [
    'garden, pool & patio',
    'braai',
    'garden decor',
    'gardening supplies',
    'garden structure',
    'garden tools',
    'hose pipe',
    'hot tub',
    'lawnmower',
    'outdoor fountain',
    'outdoor furniture',
    'outdoor heating',
    'outdoor lighting',
    'plant',
    'plant care',
    'power tools',
    'sauna',
    'sauna parts',
    'swimming pool',
    'water feature',
    'watering equipment',
  ]
  let specificOutdoorLighting = [
    'solar powered',
    'security',
    'floodlights',
    'walkway lights',
    'landscape light',
    'candle lantern',
    'wall light',
    'patio light',
    'garden torch',
    'string light',
    'grow lights',
    'weed & pest control',
  ]
  let specificOutdoorFurniture = [
    'furniture set',
    'table',
    'bench',
    'chair',
    'hammock',
    'kidney chair',
    'furniture cover',
    'storage deck box',
  ]
  let specificPlant = [
    'aquatic plant',
    'bonsai',
    'cacti',
    'succulent',
    'carnivorous plant',
    'cycad',
    'flower',
    'fruit',
    'grass',
    'herbs',
    'shrubs',
    'tree',
    'vegetable',
    'vine',
  ]
  let specificBraai = [
    'braai',
    'charcoal',
    'wood',
    'braai cover',
    'picnic basket',
    'backpack',
    'potjie',
    'tools',
    'braai grid',
    'braai tong',
    'accessory',
    'spices',
  ]
  let specificGardeningSupplies = [
    'compost worms',
    'clothing',
    'protective gear',
    'garden kneelers',
    'pads',
    'seats',
    'gardening gloves',
    'grow lights',
    'growing media',
    'hydroponic propagator',
    'hydroponic system',
    'nutrients',
    'pH',
    'supplements',
    'tent',
    'tarp',
    'shelf',
    'seed starting',
    'parts',
    'accessory',
    'artificial grass',
    'turf',
    'landscape fabric',
    'paving',
    'decking',
  ]
  let specificOutdoorHeating = [
    'patio heater',
    'misting system',
    'firepit',
    'chimineas',
  ]
  let specificGardenDecor = [
    'bird bath',
    'stepping stone',
    'outdoor thermometer',
    'plant stand',
    'plaque',
    'sign',
    'statue',
    'lawn ornament',
    'sundial',
    'weathervane',
    'wind chime',
  ]
  let specificGardenTools = [
    'hand tool set',
    'axe',
    'hatchet',
    'bonsai tools',
    'rake',
    'ladder',
    'post hole digger',
    'auger',
    'chainsaw',
    'hedge trimmer',
    'leaf blower',
    'pressure hose',
    'string trimmer',
    'hoe',
    'trowel',
    'lawnmower',
    'pruning shears',
    'saw',
    'scythe',
    'sickle',
    'pruner',
    'spade',
    'shovel',
    'wheelbarrow',
    'cart',
    'tool rack',
    'organiser',
    'parts',
    'accessory',
  ]
  let specificPowerTools = [
    'chainsaw',
    'hedge trimmer',
    'leaf blower',
    'pressure hose',
    'string trimmer',
  ]
  let specificPlantCare = [
    'fertiliser',
    'moisture',
    'pH meter',
    'plant hook',
    'hanger',
    'plant label',
    'plant tie',
    'support',
    'liner',
    'pot',
    'planter',
    'seeder',
    'spreader',
    'soil',
  ]
  let specificWateringEquipment = [
    'hose pipe',
    'connector',
    'clamp',
    'fitting',
    'drip irrigation',
    'hose reel',
    'hose storage',
    'hose nozzle',
    'hose wand',
    'sprinkler',
    'watering can',
    'water pump',
    'pressure tank',
    'water storage',
    'rain barrel',
    'watering timer',
    'watering controller',
    'water treatment',
    'filtration',
    'water pump',
  ]
  let specificGardenStructure = [
    'arbour',
    'arch',
    'canopy',
    'gate',
    'fence',
    'gazeebo',
    'marqee',
    'tent',
    'shade sail',
    'umbrella',
    'umbrella stand',
  ]
  let specificSwimmingPool = [
    'pool equipment',
    'pool parts',
    'creepy crawly',
    'pool cleaner',
    'chemicals',
    'testing products',
    'cleaning tools',
    'pool inflatable',
    'toys',
    'water treatment',
    'filtration',
    'water pump',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>

          {tags.includes('outdoor lighting') ? (
            specificOutdoorLighting.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('outdoor furniture') ? (
            specificOutdoorFurniture.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('outdoor heating') ? (
            specificOutdoorHeating.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('plant') ? (
            specificPlant.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('braai') ? (
            specificBraai.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('gardening supplies') ? (
            specificGardeningSupplies.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('garden decor') ? (
            specificGardenDecor.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('garden tools') ? (
            specificGardenTools.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('power tools') ? (
            specificPowerTools.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('plant care') ? (
            specificPlantCare.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('watering equipment') ? (
            specificWateringEquipment.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('garden structure') ? (
            specificGardenStructure.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('swimming pool') ? (
            specificSwimmingPool.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

        </div>
      </div>
    </div>
  )
}

export default GardenPoolPatioTags