import React, {useEffect, useRef, useState, useCallback, useMemo} from 'react'
import { addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, query, where, onSnapshot, deleteDoc, 
  limit, orderBy, startAfter, startAt } 
from '@firebase/firestore'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from '../firebase'

import './Market.css'
import MarketStoreLogo from './MarketStoreLogo';
import dottedTexture from '../images/dottedTexture.png'
import MarketStorePopup from './MarketStorePopup'
import MarketProductCard from './MarketProductCard'
import SearchComponent from './SearchComponent'
import MarketGradient from './MarketGradient'
import EthicalChecksPopup from "../scout/EthicalChecksPopup"
import dropdownIcon from '../icons/popupIcon.png'
import loadIcon from '../icons/scoutIcon.png'
import backArrow from '../icons/backArrow.png'
import Login from '../login/Login'
import Select from 'react-select';

const Market = (props) => {
  const [productData, setProductData] = useState([])
  const [filteredCategory, setFilteredCategory] = useState("")
  const [storeData, setStoreData] = useState([])
  const [initialData, setInitialData] = useState([])
  const [noProducts, setNoProducts] = useState(false)
  const [noStores, setNoStores] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [currentTags, setTags] = useState([])
  const marketFilterDivRef = useRef()
  const marketFilterCategoryDivRef = useRef()
  const marketProductsSectionRef = useRef()
  const marketStoresSectionRef = useRef(null)
  const svgRef = useRef(null)

  const [isActive, setIsActive] = useState(false);
  const [activeCategory, setActiveCategory] = useState("All")
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [initialLoad, setInitialLoad] = useState(true)
  const [userLoggedOut, setUserLoggedOut] = useState()
  const [sortedUniqueTags, setSortedUniqueTags] = useState([]);
  const [only1Product, set1Product] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const auth = getAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [productNotFound, setProductNotFound] = useState(false)
  const [inputTerm, setInputTerm] = useState('');
  const [activeTag, setActiveTag] = useState(null);
  const [hasSearchText, setHasSearchText] = useState(false);
  const [userID, setUserID] = useState()

  const [currentGroupTags, setCurrentGroupTags] = useState([]);
  const [show, setShow] = useState(false);
  const [dependencies, setDependencies] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sortedOption, setSortedOption] = useState("newest")
  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);

  // const [previousSearches, setPreviousSearches] = useState([]);

  // const getPreviousSearches = () => {
  //   const userId = firebase.auth().currentUser.uid;
  //   firebase.database().ref('searches/' + userId).on('value', (snapshot) => {
  //     const searches = snapshot.val();
  //     if (searches) {
  //       const searchArray = Object.values(searches);
  //       setPreviousSearches(searchArray);
  //     }
  //   });
  // }

  const savedDominantColors = ['rgb(197, 201, 198)', 'rgb(91, 106, 125)', 'rgb(138, 60, 36)', 'rgb(210, 136, 55)', 'rgb(114, 126, 132)']

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= wideScreenBreakpoint);
    };

    window.addEventListener('resize', handleResize);
    handleResize()
    
    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.title = 'Market | boot-strapt™';
  }, []);

  const [showEthicalChecksPopup, setShowEthicalChecksPopup] = useState(false)
  const [ethicalChecks, setEthicalChecks] = useState()
  const [ethicalChecksText, setEthicalChecksText] = useState('')

  const openEthicalChecksPopup = useCallback((eCheck, eCheckText) => {
    console.log(eCheck, eCheckText)
    setEthicalChecks(eCheck)
    setEthicalChecksText(eCheckText)
    setShowEthicalChecksPopup(true)
  })

  const clearSearch = () => {
    setInputTerm('');
    setHasSearchText(false);
    setActiveCategory("All")
    setNoProducts(false)
    setProductNotFound(false)
    setProductData(initialData)
    // when clear is clicked go to first search
  };
  
  useEffect(() => {
    if (marketProductsSectionRef.current) {
      // Check the length of productData
      if (productData.length === 2) {
        marketProductsSectionRef.current.style.display = 'grid';
        marketProductsSectionRef.current.style.gridTemplateColumns = 'repeat(auto-fill, minmax(200px, 1fr))';
      } else {
        marketProductsSectionRef.current.style.display = '';
        marketProductsSectionRef.current.style.flexWrap = '';
      }
    }
  }, [productData]); 

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setShowLogin(false)
      setUserID(user.uid)
      setUserLoggedOut(false)
    } else {
      setUserLoggedOut(true)
    }
  });

  const loadMore = async(lastVisible, filteredCategory) => {
    if (!userLoggedOut) {
      setInitialLoad(false)
      try {
        let productDoc;
        if (filteredCategory && !filteredCategory.includes("All")) {
          productDoc = query(
            collection(db, "products"),
            where("category", "==", filteredCategory),
            orderBy("date_added", "desc"),
            startAfter(productData[productData.length - 1].date_added),
            limit(5)
          );
        } else {
          productDoc = query(
            collection(db, "products"),
            orderBy("date_added", "desc"),
            startAfter(productData[productData.length - 1].date_added),
            limit(5)
          );
        }

        onSnapshot(productDoc, (querySnapshot) => {
          let products = [];
          let productTags = []
          if (querySnapshot.size < 5) {
            setHasMore(false);
          } 
          if (querySnapshot.size === 0) {
            setNoProducts(false);
          }      
          querySnapshot.forEach((doc) => {
            products.push({ id: doc.id, ...doc.data() });
            productTags.push(...doc.data().tags)
          });
          setProductData([...productData, ...products]);
          setTags(currentTags => [...currentTags, ...productTags.flat()])

          setNoProducts(products.length === 0 ? true : false);
          setIsLoading(false)
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      setShowLogin(true)
    }
  }

  const sortingOptions = [
    { label: "cheapest", value: "cheapest" },
    { label: "most expensive", value: "most expensive" },
    { label: "newest", value: "newest" },
    { label: "oldest", value: "oldest" },
    { label: "product likes", value: "product likes" }
  ]; 

  // in future have sliding scale when price is used as as an option

  const categories = [
    'All',
    "Fashion",
    "Electronics",
    "Beauty",
    "Art",
    "Books",
    "Cellphones & Wearables",
    "Home & Decor",
    "Crafts & Handmade Goods",
    "Audio & Media",
    "Automotive & DIY",
    "Gaming",
    "Garden, Pool & Patio",
    "Health, Fitness & Personal Care",
    "Hobbies & Toys",
    "Holistic & Esoteric",
    "Movies & Television",
    "Music & Instruments",
    "Office & Stationary",
    "Antiques & Collectables",
    "Pets",
    "Physical Sport & Training",
  ]

  const [reorderedCategories, setReorderedCategories] = useState(categories)

  useEffect(() => {
    const fetchStoreData = async () =>{
      let stores = []
      try {
        let storeDoc;
        if (filteredCategory && !filteredCategory.includes("All")) {
          storeDoc = query(
            collection(db, "stores"),
            where("industry", "==", filteredCategory),
          );
        } else {
          storeDoc = query(
            collection(db, "stores")
          );
        }

        onSnapshot(storeDoc, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            stores.push({ id: doc.id, ...doc.data()})
          });
          setNoStores(stores.length === 0 ? true : false);
          setStoreData(stores)
        });
      } catch(err) {
        console.log(err)
      }
    }
    fetchStoreData()
  }, [filteredCategory])

  const fetchData = useCallback(async (filteredCategory, sorting) => {
    setNoProducts(false);
    try {
      let orderingRule;

      // Determine the sorting order based on sortedOption
      switch (sortedOption) {
        case 'cheapest':
          orderingRule = orderBy("listed_price", "asc");
          break;
        case 'most expensive':
          orderingRule = orderBy("listed_price", "desc");
          break;
        case 'newest':
          orderingRule = orderBy("date_added", "desc");
          break;
        case 'oldest':
          orderingRule = orderBy("date_added", "asc");
          break;
        case 'product likes':
          orderingRule = orderBy("likes", "desc");
          break;
        // Add other cases for 'product likes' and 'store likes' if applicable
      }

      let productQuery = collection(db, "products");

      // Add category filtering if applicable
      if (filteredCategory && !filteredCategory.includes("All")) {
        productQuery = query(productQuery, where("category", "==", filteredCategory), where("status", "==", "published"), orderingRule, limit(5));
      } else {
        productQuery = query(productQuery, where("status", "==", "published"), orderingRule, limit(5));
      }

      onSnapshot(productQuery, (querySnapshot) => {
        let products = [];
        let productTags = [];
        if (querySnapshot.size < 5) {
          setHasMore(false);
        }
        if (querySnapshot.size === 1) {
          set1Product(true);
        } else {
          set1Product(false);
        }

        querySnapshot.forEach((doc) => {
          products.push({ id: doc.id, ...doc.data() });
          if (doc.data().tags) {
            productTags.push(...doc.data().tags);
          }
        });

        setProductData(products);
        setInitialData(products)
  
        // Update the currentTags state
        const newCurrentTags = productTags.flat();
        setTags(newCurrentTags);
  
        setNoProducts(products.length === 0 ? true : false);
        setIsLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  }, [currentPage, sortedOption]);

  useEffect(() => {
    fetchData(filteredCategory, sortedOption)
  }, [fetchData, filteredCategory, sortedOption])
  
  const fetchDataByTag = (tag) => {
    setIsLoading(true);
  
    let productDoc;
    if (tag) {
      productDoc = query(
        collection(db, "products"),
        where("tags", "array-contains", tag),
        orderBy("date_added", "desc")
      );
    } else {
      productDoc = query(
        collection(db, "products"),
        orderBy("date_added", "desc")
      );
    }
  
    onSnapshot(productDoc, (querySnapshot) => {
      let products = [];
      let productTags = [];
      if (querySnapshot.size < 5) {
        setHasMore(false);
      }
      querySnapshot.forEach((doc) => {
        products.push({ id: doc.id, ...doc.data() });
        if (doc.data().tags) {
          productTags.push(...doc.data().tags);
        }
      });
      setProductData(products);
      setTags(productTags);
      setIsLoading(false);
      setNoProducts(products.length === 0); // here
    });
  };

  useEffect(() => {
    // function to fetch tags from the current product group
    const fetchCurrentGroupTags = () => {
      let currentGroupTags = [];
      productData.forEach(product => {
        if (product.tags) {
          currentGroupTags = [...currentGroupTags, ...product.tags];
        }
      });
      const uniqueTags = [...new Set(currentGroupTags)];
      setCurrentGroupTags(uniqueTags);
    };
    fetchCurrentGroupTags();
  }, [productData]);

  const memoizedFetchData = useMemo(() => () => fetchData(filteredCategory), 
  [filteredCategory]);

  useEffect(() => {
    setIsLoading(true)
    memoizedFetchData();
  }, [memoizedFetchData]);

  useEffect(() => {
    // function to fetch tags from the current product group
    const fetchCurrentGroupTags = () => {
      let currentGroupTags = [];
      productData.forEach(product => {
        if (product.tags) {
          currentGroupTags = [...currentGroupTags, ...product.tags];
        }
      });
      const uniqueTags = [...new Set(currentGroupTags)];
      setCurrentGroupTags(uniqueTags);
    };
    fetchCurrentGroupTags();
  }, [productData]);
  
  const handleCategoryFilterClick = (category) => {
    setIsActive(false)
    setActiveCategory(category)
    setFilteredCategory(category)
    reorderCategories(category)
  }

  const reorderCategories = (clickedCategory) => {
    const index = reorderedCategories.indexOf(clickedCategory);
    const newCategories = [...reorderedCategories];
    newCategories.splice(index, 1);
    newCategories.splice(0, 0, clickedCategory);
    setReorderedCategories(newCategories);
  };  

  useEffect(() => {
    setHasMore(true);
  }, [filteredCategory])
 
  const calculateSortedUniqueTags = useCallback(() => {
    const tagCounts = currentTags.reduce((acc, curr) => {
      if (typeof acc[curr] == 'undefined') {
        acc[curr] = 1;
      } else {
        acc[curr] += 1;
      }
      return acc;
    }, {});
  
    const sortedTagCounts = Object.entries(tagCounts)
      .sort((a, b) => b[1] - a[1])
      .reduce((obj, [key, val]) => {
        obj[key] = val;
        return obj;
      }, {});
  
    const sortedTags = Object.keys(sortedTagCounts);
  
    const sortedUniqueTags = [...new Set(currentTags)].sort();
    setSortedUniqueTags(sortedUniqueTags);

  }, [currentTags]);

  useEffect(() => {
    calculateSortedUniqueTags();
  }, [productData, currentTags]);

  const [tagHistory, setTagHistory] = useState([]);

  const handleTagClick = useCallback(async (tag) => {
    setIsLoading(true);
  
    try {
      const productDoc = query(
        collection(db, "products"),
        where("tags", "array-contains", tag),
        orderBy("date_added", "desc")
      );
  
      onSnapshot(productDoc, (querySnapshot) => {
        let products = [];
        let productTags = []
        if (querySnapshot.size < 5) {
          setHasMore(false);
        }
        querySnapshot.forEach((doc) => {
          products.push({ id: doc.id, ...doc.data() });
          if (doc.data().tags) {
            productTags.push(...doc.data().tags);
          }
        });
        setProductData(products);
        setTags(productTags);  // Update currentTags state here
        setIsLoading(false)
      });
  
      setTagHistory(prev => [...prev, tag]);
      
  
    } catch (err) {
      console.log(err);
    }
  }, []);
  
  const handleBackClick = () => {
    if (tagHistory.length > 0) {
      // Remove the last tag from the history
      setTagHistory(prev => {
        const newTagHistory = prev.slice(0, -1);
        
        // Refetch data with the previous tag if any, else fetch all data
        fetchDataByTag(newTagHistory.length > 0 ? newTagHistory[newTagHistory.length - 1] : '');
        return newTagHistory;
      });
    }
  };

  useEffect(() => {
    if (activeTag) {
      const filteredProducts = productData.filter(product => product.tags && product.tags.includes(activeTag));
      setProductData(filteredProducts);
    } else {
      fetchData(filteredCategory); // You might need to modify this to get the initial productData back
    }
  }, [activeTag]);

  const onLogoClick = (logo, category, description, store, storeID, index) => {
    setShow(true);
    setDependencies({logo, category, description, store, storeID, index});
  };

  const closeMarketStorePopup = () => {
    setShow(false);
    setDependencies(null);
  }

  return (
    <div className='marketPageBody'>
      <style>{'body { background-color: #EFC6A9; }' }</style>
      <div className="marketTopSectionContainer">
        <img src={dottedTexture} className="marketDottedTexture" />
        <canvas className="marketGradientCanvas">
          <MarketGradient page={"market"} dominantColors={savedDominantColors} isWideScreen={isWideScreen}/>
        </canvas>
        <SearchComponent 
          parentComponent={'market'}
          clearSearch={clearSearch}
          hasSearchText={hasSearchText}
          setProductData={setProductData}
          setNoStores={setNoStores}
          setActiveCategory={setActiveCategory}
          setNoProducts={setNoProducts}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setHasSearchText={setHasSearchText}
          setInputTerm={setInputTerm}
          inputTerm={inputTerm}
          setHasMore={setHasMore}
          setProductNotFound={setProductNotFound}
          setIsLoading={setIsLoading}
        />
        <div className="marketFilterDiv" ref={marketFilterDivRef}>
          {!isActive &&
            <div className="marketFilterCategoryDiv" ref={marketFilterCategoryDivRef}>
              {reorderedCategories.slice(0, 7).map((category, index) => (
                <div className={`marketCategoryTag ${activeCategory === category ? 'actively' : ''}`} key={index}
                onClick={() => {handleCategoryFilterClick(category)}}>
                  <div className="marketCategoryTagText">{category}</div>
                </div>
              ))}
            </div>
          }
          {isActive &&
            <div className="marketFilterCategoryDivExpanded" ref={marketFilterCategoryDivRef}>
            {reorderedCategories.map((category, index) => (
              <div className={`marketCategoryTag ${activeCategory === category ? 'actively' : ''}`} key={index}
              onClick={() => {handleCategoryFilterClick(category)}}>
                <div className="marketCategoryTagText">{category}</div>
              </div>
            ))}
            </div>
          }
          <div className="marketExpandFiltersBtn" 
          onClick={() => setIsActive(!isActive)}>
            <div>{isActive ? <img className="marketDropdownIcon2" src={dropdownIcon}  alt="dropdown icon" /> : <img className="marketDropdownIcon" src={dropdownIcon}  alt="dropdown icon" />}</div>
          </div>
        </div>
      </div>
      <div className="marketBottomSectionContainer">
        <div className="marketStoresSectionParent" style={{ height: 'fit-content', position: 'relative', zIndex: 4}}>
          <svg width="100vw" height="255" ref={svgRef} style={{ position: 'relative', left: 0}} viewBox="0 0 375 255" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="f1" x="-50%" y="-50%" width="200%" height="200%">
                <feDropShadow dx="-5" dy="10" stdDeviation="10" floodColor="rgba(0, 0, 0, 0.1)"/>
                <feDropShadow dx="-5" dy="10" stdDeviation="10" floodColor="rgba(0, 0, 0, 0.1)"/>
              </filter>
              <linearGradient id="paint0_linear_1608_54" x1="-2.5" y1="187" x2="377.5" y2="229" gradientUnits="userSpaceOnUse">
                <stop stopColor="#946F55"/>
                <stop offset="0.00251088" stopColor="#A37C5F"/>
                <stop offset="0.00538981" stopColor="#AB8264"/>
                <stop offset="0.0100572" stopColor="#B4896B"/>
                <stop offset="0.0124749" stopColor="#B98D6E"/>
                <stop offset="0.0170083" stopColor="#BE9272"/>
                <stop offset="0.0262154" stopColor="#B68B6C"/>
                <stop offset="0.035963" stopColor="#AB8264"/>
                <stop offset="0.0434914" stopColor="#A47D60"/>
                <stop offset="0.0467771" stopColor="#9E785C"/>
                <stop offset="0.0524993" stopColor="#977157"/>
                <stop offset="0.0588551" stopColor="#906C52"/>
                <stop offset="0.0675218" stopColor="#8B6850"/>
                <stop offset="0.0892559" stopColor="#755743"/>
                <stop offset="0.209638" stopColor="#6C513F"/>
                <stop offset="0.227837" stopColor="#624A3A"/>
                <stop offset="0.247766" stopColor="#5E4738"/>
                <stop offset="0.263505" stopColor="#554234"/>
                <stop offset="0.281923" stopColor="#4E3C31"/>
                <stop offset="0.293188" stopColor="#4D3B2F"/>
                <stop offset="0.304586" stopColor="#4D3A2D"/>
                <stop offset="0.319105" stopColor="#5B4537"/>
                <stop offset="0.329004" stopColor="#6B503E"/>
                <stop offset="0.345802" stopColor="#755743"/>
                <stop offset="0.912845" stopColor="#BF9272"/>
                <stop offset="0.929887" stopColor="#C29474"/>
                <stop offset="0.944186" stopColor="#C79877"/>
                <stop offset="0.959778" stopColor="#D3A280"/>
                <stop offset="0.975955" stopColor="#DEAC88"/>
                <stop offset="0.985125" stopColor="#D5A481"/>
                <stop offset="0.992095" stopColor="#CC9D7C"/>
              <stop offset="1" stopColor="#B98D6E"/>
              </linearGradient>
            </defs>
            <path d="M0 83.2009C0 66.5541 13.5538 53.0905 30.2003 53.2016L344.808 55.3018C361.256 55.4116 374.548 68.7445 374.608 85.1924L375 193.5V205.5V210.5V217V217C374.423 223.347 369.34 228.345 362.985 228.816L360.5 229H351H349H147H137L136.495 228.989C123.993 228.717 114 218.505 114 206V206V200.5L113.751 198.255C113.298 194.178 110.401 190.791 106.443 189.712L105.876 189.557C103.303 188.855 100.649 188.5 97.9825 188.5H93.5H22.5H18H13.2795C8.46464 188.5 4.01461 185.934 1.60219 181.767V181.767C0.552663 179.955 0 177.897 0 175.802V175.5V170V83.2009Z" fill="url(#paint0_linear_1608_54)" filter="url(#f1)"/>
            <defs>
              <filter id="f2" x="-50%" y="-50%" width="200%" height="200%">
                <feDropShadow dx="5" dy="10" stdDeviation="5" floodColor="rgba(0, 0, 0, 0.05)"/>
                <feDropShadow dx="5" dy="10" stdDeviation="2.5" floodColor="rgba(0, 0, 0, 0.05)"/>
              </filter>
            </defs>
            <defs>
              <linearGradient id="paint1_linear_1608_54" x1="187.5" y1="1.02296e-07" x2="188" y2="55" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F0D9C3"/>
                <stop offset="1" stopColor="#FFDBBA" stopOpacity="0"/>
              </linearGradient>
            </defs>
            <path d="M19 1H356C365.941 1 374 9.05888 374 19V193.5V201.5V204C374 213.941 365.941 222 356 222H139C129.059 222 121 213.941 121 204V200C121 190.059 112.941 182 103 182H15C7.26801 182 1 175.732 1 168V166.5V19C1 9.05887 9.05888 1 19 1Z" fill="#F3D3B6" filter="url(#f2)" stroke="url(#paint1_linear_1608_54)" strokeWidth="2"/>
          </svg>
          <div className="marketStoresSectionContainer">
            <div className="marketStoresSection" ref={marketStoresSectionRef}>
              <div className="marketStoresSectionHeader">stores.</div>
              <div className="marketStoreDiv">
                {noStores && initialLoad ? 
                  <div className="noStoresForCategoryDiv">
                    This category currently has no stores.
                  </div>:null
                }
                {storeData.map((storeData, index) => (
                  <MarketStoreLogo 
                    onLogoClick={onLogoClick}
                    showLoginPopup={() => {setShowLogin(true)}}
                    userLoggedOut={userLoggedOut}
                    key={index}
                    logo={storeData.store_logo}
                    category={storeData.industry}
                    description={storeData.store_description}
                    store={storeData.store}
                    storeID={storeData.store_id}
                  />
                ))}
              </div>
            </div>
            {show && dependencies && (
              <MarketStorePopup
                showLoginPopup={() => {setShowLogin(true)}}
                userLoggedOut={userLoggedOut}
                show={show}
                onClickOutside={() => {setShow(false)}}
                closePopup={closeMarketStorePopup}
                logo={dependencies.logo}
                category={dependencies.category}
                description={dependencies.description}
                store={dependencies.store}
                storeID={dependencies.storeID}
                key={dependencies.index}
              />
            )}
          </div>
          <Select
            onMenuOpen={() => setIsDropdownOpen(true)}
              onMenuClose={() => setIsDropdownOpen(false)}
              className="marketSortSelect"
              options={sortingOptions}
              onChange={(option) => {setSortedOption(option.value);}}
              placeholder="sort"
              isSearchable={false}
              styles={{
                container: (base) => ({
                  ...base,
                  position: 'absolute',
                  left: '10px',
                  bottom: '25px',
                  width: '27%',
                  zIndex: 10,
                }),
                placeholder: (base) => ({
                  ...base,
                  textAlign: 'left',
                  fontWeight: 'bold',
                }),
                control: (base) => ({
                  ...base,
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  backgroundColor: 'rgba(208, 215, 215, 0.7)',
                  borderRadius: '10px',
                  fontFamily: 'Poppins',
                  fontSize: '13px',
                  boxShadow: '-1px -1px 2px rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.25) 0px 30px 25px -5px, rgba(0, 0, 0, 0.25) 0px 10px 10px -5px',
                  backdropFilter: 'blur(12px)',
                  WebkitBackdropFilter: 'blur(12px)', // for Safari browser support
                  border: 'none',
                  cursor: 'pointer',
                  zIndex: 10,
                }),
                singleValue: (base) => ({
                  ...base,
                  textAlign: 'left',
                  fontWeight: 'bold',
                }),
                menu: (base) => ({
                  ...base,
                  position: 'absolute',
                  backgroundColor: 'rgb(241, 240, 240)',
                  borderRadius: '0px 0px 12px 12px',
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  zIndex: 5,
                  paddingTop: '34px',
                  border: 'none',
                  top: '0px',
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  visibility: 'visible', // ensure this is not set to 'hidden'
                  color: '#000', // Adjust color as needed
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#FB890E' : (state.isFocused ? '#FFC55C' : base.backgroundColor),
                  color: state.isSelected || state.isFocused ? 'rgba(0, 0, 0, 0.8)' : base.color,
                  width: '96%',
                  margin: '2px auto',
                  fontWeight: 'bold',
                  boxSizing: 'border-box',
                  overflow: 'hidden',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  textAlign: 'left',
                }),
                indicatorSeparator: () => ({ display: 'none' }),
            }}
          />
        </div>
        {noProducts && initialLoad ? 
          <div className="noProductsForCategoryDiv">
            This category currently has no products.
          </div>:null
        }
        {isLoading ?
          <div className="marketLoadingDiv">
            <img src={loadIcon} className="marketLoadingIcon" alt="loading icon" />
          </div> : null
        }
        {!isLoading && !only1Product && 
          <div className="sortedTagsDiv">
            {tagHistory.length > 0 && 
              <div className="sortedTagsBack">
                <img onClick={handleBackClick} src={backArrow} className="sortedTagsBackIcon"/>
              </div>
            }
            {sortedUniqueTags.map((tag, index) => (
              <div className="sortedTag" key={index}>
                <div className="sortedTagText" onClick={() => handleTagClick(tag)}>{tag}</div>
              </div>
            ))} 
          </div>
        }
      {productNotFound && 
        <div className="marketSearchUnavailable">
          <p className="marketSearchUnavailableText">We couldn't find a match for <strong>'{searchTerm}'</strong>. Try searching for another term.</p>
        </div>
      }
      {/* <div className="marketCategoryCarousel">
        <div className="carouselDiv1"></div>
        <div className="carouselDiv2"></div>
        <div className="carouselDiv3"></div>
      </div>
      <WatercolourGradient colours={['#FF6347', '#4682B4', '#32CD32']} dominantColors={['hsla(0, 100%, 50%, 1)', 'hsla(120, 100%, 25%, 1)', 'hsla(240, 100%, 75%, 1)']} /> */}
      <div className="marketProductsSection" ref={marketProductsSectionRef}>
      {!isLoading ?
        productData.map((productData, index) => (
          <MarketProductCard 
          showLogin={() => {setShowLogin(true)}}
          userLoggedOut={userLoggedOut}
          key={index}
          data={productData}
          productID={productData.productID}
          productImage={productData.images}
          productPrice={productData.listed_price}
          productTitle={productData.title}
          store={productData.store}
          storeID={productData.store_id}
          bundling={productData.bundling}
          productDescription={productData.description}
          productCategory={productData.category}
          navBarState={props.navBarState}
          openEthicalChecksPopup={openEthicalChecksPopup}
          setTransitionStarted={props.setTransitionStarted}
          />
        )) : null
      }
      </div>
      </div>
      {!isLoading && !noProducts && hasMore ? 
        <div className="loadMoreDiv">
          <div className="loadMoreBtn" onClick={loadMore}>load more</div>
        </div> : null
        // loading icon while loading
      }
      {showLogin?
        <div className="marketLogin">
          <div className="marketLoginDiv">
            <p className="marketLoginTextDiv">To <strong>discover more</strong>, please login or create an account.</p>
            <Login show={showLogin} onClickOutside={() => {setShowLogin(false)}}/>
          </div>
        </div> : null
      }
      {showEthicalChecksPopup && 
        <EthicalChecksPopup 
          onClickOutside={() => {setShowEthicalChecksPopup(false)}}
          ethicalChecks={ethicalChecks}
          ethicalChecksText={ethicalChecksText}
        />
      }
    </div>
  )
}
 
export default Market