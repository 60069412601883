import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const AutomotiveDIYTags = (props) => {
  const [tags, setTags] = useState(["automotive & DIY"])
  const newTagRef = useRef()

  const generalTags = [
    'automotive & DIY',
    'acetone',
    'bolts',
    'car cleaning',
    'car parts',
    'car radio',
    'car seat',
    'ladder',
    'lights',
    'nails',
    'nuts',
    'oil',
    'paint',
    'personalised number plate',
    'pitchfork',
    'sandpaper',
    'screws',
    'shovel',
    'spade',
    'tool box',
    'tools',
    'tow bar',
    'trailer',
    'turpentine',
    'tyres',
    'varnish',
    'wheels',
    'winches',
    'windshield',
    'wire',
    'wood',
    'workbench',
  ]
  let specificCarParts = [
    'brakes',
    'lubricant',
    'air intake',
    'cooling and heating',
    'gauge',
    'engines',
    'exhaust',
    'indicators',
    'transmission',
    'suspension',
    'steering',
    'wheel nuts',
    'fuel container',
    'valve',
  ]
  let specificCarCleaning = [
    'car shampoo',
    'polish',
    'cleaning cloth',
    'tire cleaner',
  ]
  let specificTools = [
    'toolbox',
    'hammer',
    'screwdriver',
    'axe',
    'mallet',
    'chainsaw',
    'saw',
    'chisel',
    'pliers',
    'tape measure',
    'drill',
    'nail gun',
    'glue gun',
    'spanner',
    'wrench',
    'level',
    'anvil',
    'trowel',
    'float',
  ]
  let specificPaint = [
    'paint thinner',
    'roller',
    'paint tray',
    'gloss',
    'semi-gloss',
    'high-gloss',
    'chalkboard',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  console.log(tags)
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>

          {tags.includes('car parts') ? (
            specificCarParts.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('car cleaning') ? (
            specificCarCleaning.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('tools') ? (
            specificTools.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('paint') ? (
            specificPaint.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default AutomotiveDIYTags