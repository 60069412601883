import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const HomeDecorTags = (props) => {
  const [tags, setTags] = useState(["home & decor"])
  const newTagRef = useRef()

  const generalTags = [
    'home & decor',
    'furniture',
    'plant',
    'decorations',
    'textiles & rugs',
    'bed textiles',
    'art & prints',
    'storage & organization',
    'curtains & blinds',
  ]
  let specificFurniture = [
    'cupboard',
    'shelf',
    'bookcase',
    'sofa',
    'couch',
    'kist',
    'chest',
    'desk',
    'cabinet',
    'dresser',
    'stand',
    'ottoman',
    'chair',
    'stool',
    'footstool',
    'bed',
    'cot',
    'mirror',
    'table',
    'headboard',
    'mattress',
  ]
  let specificPlant = [
    'plant pot',
    'body plant pot',
    'real plant',
    'bonsai',
    'artificial plant',
    'flowerpot holder',
    'herbs',
    'vegetal frame',
    'vertical garden',
  ]
  let specificDecorations = [
    'clocks',
    'mirrors',
    'frames',
    'vases',
    'decorative objects',
    'centerpieces',
    'candles',
    'candle holders',
    'pin trays',
    'ashtrays',
    'hanging decorations',
    'magnets',
    'pedestals',
    'dry erase board',
    'wall decor',
    'calendar',
    'air freshner',
    'oil lamp',
    'jewel box',
    'globe',
  ]
  let specificTextilesRugs = [
    'rug',
    'carpet',
    'mat',
    'leather',
    'cushion',
    'chair cover',
    'doormat',
    'upholstery fabric',
    'chair trimmings',
    'antibacterial fabrics',
  ]
  let specificBedTextiles = [
    'bed sheet',
    'bedding sets',
    'pillow cases',
    'quilts',
    'duvets',
    'duvet cover',
    'bedspread',
    'blanket',
    'lap robe',
    'matress cover',
    'bed frame cover',
    'bed wraps',
  ]
  let specificArtPrints = [
    'painting',
    'print',
    'photograph',
    'poster',
    'canvas print',
    'lithograph',
    'silk screen',
    'kakemono',
    'wall sticker',
    'tapestry',
    'decorative panel',
    'sculpture',
    'miniature',
  ]
  let specificStorageOrganization = [
    'room divider',
    'screen',
    'coat rack',
    'umbrella stand',
    'magazine rack',
    'valet stand',
    'clothes hanger',
    'shoe cabinet',
    'storage box',
    'key cabinet',
    'monkey box',
    'piggy bank',
    'doorstop',
    'bookend',
    'bookstand',
    'watch box',
    'laundry container',
    'waste bin',
    'bicycle storage',
    'footwear accessory',
  ]
  let specificCurtainsBlinds = [
    'curtains',
    'blinds',
    'roman blinds',
    'roller blinds',
    'pleated blinds',
    'vertical blinds',
    'venetian blinds',
    'panel curtain',
    'chain curtain',
    'railed curtain',
    'curtain fabrics',
    'curtain accessory',
    'mosquito net',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>
          
          {tags.includes('furniture') ? (
            specificFurniture.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('plant') ? (
            specificPlant.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('decorations') ? (
            specificDecorations.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('textiles & rugs') ? (
            specificTextilesRugs.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('bed textiles') ? (
            specificBedTextiles.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('art & prints') ? (
            specificArtPrints.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('storage & organization') ? (
            specificStorageOrganization.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('curtains & blinds') ? (
            specificCurtainsBlinds.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default HomeDecorTags