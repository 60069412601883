import React, { useRef, useState, useEffect } from "react";
import './CategoryTags.css'

import plusIcon from '../../../icons/plusIcon.png'

const AntiquesCollectablesTags = (props) => {
  const [tags, setTags] = useState(["antiques & collectables"])
  const newTagRef = useRef()

  const generalTags = [
    'antiques & collectables',
    'africana',
    'bar & smoking',
    'cards',
    'carpet',
    'ceramic',
    'clock',
    'coin',
    'crystal & glassware',
    'frame',
    'fur & pelts',
    'furniture',
    'gemstone',
    'globe',
    'jewellery',
    'kitchen',
    'light',
    'ornament',
    'photography & lenses',
    'porcelain',
    'poster',
    'pottery',
    'record player',
    'scientific',
    'stamp',
    'tapestry',
    'telephone',
    'toys',
    'watch',
    'tribal mask',
    'voice recorder',
    'vinyl',
  ]

  let specificBar = [
    'flask',
    'tankard',
    'smoking accessory',
    'pipe',
    'hookah',
    'cork screw',
    'wine rack',
  ]
  let specificFurniture = [
    'cupboard',
    'shelf',
    'bookcase',
    'sofa',
    'couch',
    'kist',
    'chest',
    'desk',
    'cabinet',
    'dresser',
    'stand',
    'ottoman',
    'chair',
    'stool',
    'footstool',
    'bed',
    'cot',
    'mirror',
    'table',
  ]
  let specificLight = [
    'desk lamp',
    'chandelier',
    'lantern',
    'ceiling light',
    'wall lighting',
    'candle snuffer',
  ]
  let specificClock = [
    'grandfather clock',
    'cuckoo',
    'mantel clock',
    'alarm clock',
    'electrical clock',
  ]
  let specificPhotography = [
    'camera',
    'lenses',
    'movie recorder',
    'video recorder',
    'projector',
  ]
  let specificPorcelain = [
    'dutch porcelain',
    'italian porcelain',
    'english porcelain',
    'oriental porcelain',
    'french porcelain',
    'south african porcelain',
    'german porcelain',
  ]
  let specificCrystal = [
    'ashtray',
    'bottle',
    'decanter',
    'drinking glass',
    'paperweight',
    'perfume bottle',
    'stained glass',
    'swarovski',
    'trinket box',
    'vase',
  ]
  let specificOrnament = [
    'bone',
    'glass',
    'metal',
    'wood',
    'porcelain & ceramic',
    'oriental',
    'bust',
    'statuette',
  ]
  let specificJewellery = [
    'ring',
    'diamond',
    'watch',
    'necklace',
    'bracelet',
    'gold',
    'silver',
    'copper',
    'brass',
  ]
  let specificToys = [
    'toy car',
    'figurine',
    'action figure',
    'doll',
    'stuffed animal',
    'teddy bear',
  ]
  let specificKitchen = [
    'crockery',
    'cutlery',
    'utensil',
    'bowl',
    'dishes',
    'enamelware',
  ]
  let specificScientific = [
    'barometer',
    'thermometer',
    'compass',
    'telescope',
    'sundial',
    'microscope',
    'scale',
    'weight',
  ]

  useEffect(() => {
    if (props.isEditing && props.tags !== undefined) {
      let value = props.tags;
      
      // If `props.tags` is a string, split it into an array
      if (typeof value === 'string') {
        value = value.split(", ");
      }
      
      // Reset and set tags
      setTags([...value]);
      
      newTagRef.current.value = '';
    }
  }, [props.isEditing, props.tags]);

  function addNewTag(e) {
    const value = newTagRef.current.value
    if(!value.trim()) return
    setTags([...tags, value])
    newTagRef.current.value = ''
  }
  
  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
    props.addTags(tags.filter((el, i) => i !== index))
  }
  
  return (
    <div className="tagsDiv">
      <h1 className="activeTagsHeader">active tags</h1>
      <p className="activeTagsDescription">used to describe your product in detail for our algorithm</p>
      <div className="activeTags">
        { tags.map((tag, index) => (
          <div className="tagItem" key={index}>
            <span className="tagText">{tag}</span>
            <span className="deleteTag" onClick={() => removeTag(index)}>&times;</span>
          </div>
        ))}
      </div>
      <div className="addNewTagDiv">
        <input  
          type="text" placeholder=' add new tag' id='addNewTagText' ref={newTagRef}
        />
        <img onClick={addNewTag} src={plusIcon} alt="add your tag" id='addNewTagIcon'/>
      </div>
      <div className="suggestedTags">
        <h1 className="suggestedTagsHeader">suggested tags</h1>
        <p className="suggestedTagsDescription">click the relevant tags to add them to your 'active tags'</p>
        <div className="generalTags">
          <h1 className="generalTagsHeader">general</h1>
          {generalTags.map((generalTag, index) => {
            if (!tags.includes(generalTag))
              return (
                <div className="suggestedTagItem" key={index} 
                onClick={() => {
                  setTags([...tags, generalTag])
                  props.addTags([...tags, generalTag])
                }}>
                <span className="suggestedTagText">{generalTag}</span>
                </div>
              )
          })}
        </div>
        <div className="specificTags">
          <h1 className="specificTagsHeader">specific</h1>

          {tags.includes('bar & smoking') ? (
            specificBar.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
          
          {tags.includes('furniture') ? (
            specificFurniture.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('light') ? (
            specificLight.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('clock') ? (
            specificClock.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('photography & lenses') ? (
            specificPhotography.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('porcelain') ? (
            specificPorcelain.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('crystal & glassware') ? (
            specificCrystal.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('ornament') ? (
            specificOrnament.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('jewellery') ? (
            specificJewellery.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('toys') ? (
            specificToys.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('kitchen') ? (
            specificKitchen.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}

          {tags.includes('scientific') ? (
            specificScientific.map((specificTag, index) => {
              if (!tags.includes(specificTag)) 
                return (
                  <div className="suggestedTagItem" key={index} 
                  onClick={() => {
                  setTags([...tags, specificTag])
                  props.addTags([...tags, specificTag])
                  }}>
                  <span className="suggestedTagText">{specificTag}</span>
                  </div>
                )
            })
          ) : (<div/>)}
        </div>
      </div>
    </div>
  )
}

export default AntiquesCollectablesTags